import React from 'react';
import { Button } from '@chakra-ui/react'

function ButtonComponent(props) {
	const {name,onClick,className} = props
	

	return(
		<>
			<Button onClick={onClick} className={className}>{name}</Button>
		</>

	);
}

export default ButtonComponent;