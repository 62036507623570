import { useEffect,useState } from 'react';
import { Button,HStack,Box,Heading,Link} from '@chakra-ui/react'
import {InputText} from '../../../components/FormElement'
import { v4 as uuid } from 'uuid';
function ProductOptions({text,handleOptionValue}){
	const [optionValue, setOptionValue] = useState([]); 

	 const addValues = () => {
	 	const unique_id = uuid();
       const small_id = unique_id.slice(0,8)
        setOptionValue([...optionValue, small_id])
        handleOptionValue(small_id);
    }

	return(
		<>
		<InputText name={`option_name_${text}`} title="Option Name"  />
		Option Values
		<br />


		{optionValue.map((item, i) => ( 
				<>
				
              <InputText name={`value_${text}_${item}`} title=""  />
              </>

            ))} 

		<Link onClick={() => addValues()}>Add Values</Link>

		<HStack>
			<Box>
				
			</Box>
		</HStack>
		<hr />
		</>
	)
}

export default ProductOptions;