import React from 'react';

import {
  
  RadioGroupControl,
  
} from "formik-chakra-ui";

import {
  
  Radio,Stack
  
} from "@chakra-ui/react";

function RadioComponent(props) {
	const {name,title,options,value, ...rest} = props

	
	return(
		<>
			<div className="field_section">
			
				<RadioGroupControl name={name} value={value} label={title}>
				<Stack>
						{options?.map((item) => {

							return (
								<>
								 <div className="d_flex">
									<div className="me-15">
		          						<Radio value={`${item.id}`}>{item.label}</Radio>
		          		</div>

		          		{(item.prefer == 1) ? (


		          				<div className="badge_section">
														<h5>Recommended</h5>
													</div>

		          			) : null}
		          						



									
								</div>
									
		          	</>
		          				)

		          })}
            
           </Stack>
          </RadioGroupControl>
				
			</div>
			
		</>
	);
}

export default RadioComponent;