import { useEffect,useState } from 'react';


import * as Yup from 'yup';
import { Button } from '@chakra-ui/react'
import {InputText,TextAreaField,Form,SelectField} from '../../components/FormElement'
import { fetchWrapper } from '../../helpers';
import React from 'react';
import InputGroupText from '../../components/InputGroupText'
import { BsFillPersonFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import logo from '../../assets/images/logo.png'
import { Image,HStack,Box,Heading,Text,Link } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

function SignIn() {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
       
        password:'',

      });

    const FormSchema = Yup.object().shape({
        username: Yup.string().required('Required'),
       
        password: Yup.string().required('Required')
    });


    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
     
           let  data = values
          
           
         const url = `${process.env.REACT_APP_API_URL}/api/auth/signin`;
         const returnData = await fetchWrapper.post(url,data)
         .then(returnData => {
            //navigate("/")
            window.location.href="/";
            localStorage.setItem('user', JSON.stringify(returnData));
         })
         .catch(err => {
          alert(err)
         })
       
         
         
    }


    return (
        <>  
            <HStack h='100vh' bg="#F5F5F6" justifyContent="center">
                <HStack justifyContent="center" alignItems="center" flexDirection="column">
                    <Box pt="60px" pb="40px" pl="80px" pe="80px" bg="white" w="481px" border="1px" borderColor="#CCCCCC" boxShadow="2px 4px 4px 0px #00000040" borderRadius='5px' textAlign="center" mb="20px">
                        {/*<Image m="auto" mb="30px" src={logo} alt='Dan Abramov' />*/}
                        <Text mb="30px" fontSize='20px' color="#363A77" fontWeight="700" fontFamily='Montserrat, sans-serif'>QEEKT</Text>

                        <Text fontSize='16px' fontFamily='Open Sans, sans-serif' mb="25px">Please login into your account</Text>

                        <Form
                            enableReinitialize
                            validationSchema={FormSchema}
                            initialValues={formData}
                            onSubmit={onSubmit}
                        >
                            <InputText name="username" icon={<BsFillPersonFill />} placeholder="User Name" />

                            <InputText inputProps={{type:'password'}} name="password" icon={<FaLock />} placeholder="Password" />

                            <Button
                                border='1px'
                                  borderColor='#047E60'
                                  bg="#047E60"
                                  px="30px"
                                  py="10px"
                                  display="block"
                                  color="#fff"
                                  w="100%"
                                  h="50px"
                                  fontSize="14px"
                                  borderRadius="3px"
                                  fontFamily='Open Sans, sans-serif'
                                  mb="10px"
                                colorScheme='teal'
                                
                                type='submit'
                            >
                             Login
                            </Button>
                            <Link fontSize="10px" fontFamily='Open Sans, sans-serif' color="#2F6DC9">Forgot Password?</Link>
                        </Form>
                    </Box>
                    <Text fontSize='16px' fontFamily='Open Sans, sans-serif' mb="25px">Copyright 2024, Qeekt</Text>

                </HStack>
            </HStack>
            </>
        )
}

export default SignIn