import React from 'react';
import { BsFillPersonFill } from "react-icons/bs";
import { Input, InputGroup , InputLeftAddon , InputRightAddon } from '@chakra-ui/react'

function InputGroupText(props) {
	const {name} = props
	const {icon} = props

	return(
		<>
			<div className="field_section">
				<label>{name}</label>
				<div className="group_field">
					<InputGroup>
					    {icon}
					    <Input placeholder='Mysite' size="sm" />	    
			  		</InputGroup>
			  	</div>
			</div>
		</>
	);
}

export default InputGroupText;