import React,{useEffect,useState} from 'react';
import { BsArrowLeftSquare, BsShop } from "react-icons/bs";
import SelectField from '../../../components/FormElement/SelectField'
import { useSelector, useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../helpers';
import { Form } from "../../../components/FormElement/Form.js";

import { Button ,HStack,Spinner } from '@chakra-ui/react'

import * as Yup from 'yup';
import CustomModalLink from '../../../components/FormElement/CustomModalLink'
import EditProfile from './EditProfile';

function Profile(){
	const [loading,setLoading] = useState(false);
	const [tenent,setTenent] = useState({});
	const [profileData,setProfileData] = useState({});
	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       	loadData();
       
        
    }, []);

  const loadList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/attribute/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  }

  const loadData = async () => {
  		setLoading(true) 
  		const url = `${process.env.REACT_APP_API_URL}/tenent/by-id`;
  		const data  = await fetchWrapper.get(url)
  		setTenent(data.data)
  		const tenentData = data.data
  		const settingsData = JSON.parse(tenentData.settings)
  		const profileDataValue = settingsData['store_profile'];
  		setProfileData(profileDataValue)
  		setLoading(false) 
  	}
  	
	return(
		<>
		{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<div className="detail_card">
				<div className="heading_section">
					<div className="d_flex justify_content_between">
						<h2>Profile</h2>

						<CustomModalLink
					        showModalButtonText="Edit"
					        modalHeader="Edit Profile"
					        modalBody=<EditProfile profileData={profileData} loadList={loadList} />
					      />
					</div>
				</div>

				<div className="detail_section">

					<div className="d_flex">
						<div className="profile_data_section">
							<div className="icon_box">
								<BsShop />
							</div>

							<div className="text_section">
								<p>Store name</p>
								<h5>{profileData?.store_name}</h5>
							</div>
						</div>

						<div className="profile_data_section">
							<div className="icon_box">
								<BsShop />
							</div>

							<div className="text_section">
								<p>Phone</p>
								<h5>{profileData?.whatsapp_number}</h5>
							</div>
						</div>
					</div>

					<div className="d_flex">
						<div className="profile_data_section">
							<div className="icon_box">
								<BsShop />
							</div>

							<div className="text_section">
								<p>Store contact email</p>
								<h5>{profileData?.email}</h5>
							</div>
						</div>

						<div className="profile_data_section">
							<div className="icon_box">
								<BsShop />
							</div>

							<div className="text_section">
								<p>Sender email</p>
								<h5>{profileData?.email}</h5>
							</div>
						</div>
					</div>
				</div>
			</div>	


						
		</>
	);
}

export default Profile;