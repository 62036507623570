import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../components/ButtonComponent'
import * as Yup from 'yup';
import Visa from '../../assets/images/visa.png'
import { BsArrowLeftSquare, BsTypeItalic, BsTypeBold } from "react-icons/bs";
import { BsChevronLeft, BsTelephone, BsCameraVideo, BsBoxArrowUpRight, BsPlusLg, BsFillStickyFill, BsMic, BsCamera } from "react-icons/bs";
import { Button,Spinner,HStack


 } from '@chakra-ui/react'
import user_img from '../../assets/images/user_img.png';
import { fetchWrapper } from '../../helpers';
import { useLocation } from "react-router-dom";
import {InputText,Form,InputDateField,SelectField,TextAreaField,RadioComponent} from '../../components/FormElement'
function TempalateDetail(){

	const {state} = useLocation();
	const id = state.id;

	const [loading,setLoading] = useState(false);
	const [detail,setDetail] = useState([])
	const [catList,setCatList] = useState([])
	const [formData, setFormData] = useState({})

	useEffect(() => {
        loadDetail();
        loadCatList(); 
    }, [id]);

  const loadDetail = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/template/by-id?id=${id}`;
      const data  = await fetchWrapper.get(url);
      const detailData = data.data;
      setDetail(detailData);


      const headerData = JSON.parse(detailData.header_option)

      setFormData({
		    name: detailData.name,
		    category_id:detailData.category_id,
		    id:detailData.id,
		    headerType:headerData?.headerType,
		    headerText:headerData?.headerText,
		    body:detailData.body,
		    footer:detailData.footer,

    
  		})
  
      setLoading(false);
  }

  const loadCatList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/template-category/list?type=M`;
      const data  = await fetchWrapper.get(url)
      setCatList(data.data)
  }

  

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),

	});

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = {
		   			name:values.name,
		   			category_id:values.category_id,
		   			body:values.body,
		   			footer:values.footer,
		   			id:values.id,
		   			action_1_id:"",
		   			action_1_id:"",
		   			action_1_name:"",
		   			action_2_name:"",
		   			header_option: JSON.stringify({
		   								headerType:values.headerType,
		   								headerText:values.headerText,


		   							})


		   }
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/template/update`;
  		 await fetchWrapper.post(url,data)
  		 
	}

	const headerOption = [{id:'none',label:'None'},{id:'text',label:'Text'},{id:'image',label:'Image'}]

	return(
		<>
		{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
		
			<div className="setting_detail_section">
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >
				<h2 className="main_heading">
					<div className="me-15">
						<a href="/setting/whatsapp-marketing-template"><BsArrowLeftSquare /></a>
					</div>

					<div>
						<h2>{detail.name}</h2>
						<p>Sent automatically to the customer after they place their order.</p>
					</div>
				 </h2>

				 <div className="d_flex">
				 	<div style={{ width : '65%' }}>
				 		<div className="detail_card">
							<div className="heading_section">			
								<h2>Orders Confirmation</h2>	
							</div>

							<div className="plan_detail_section">
								<InputText name="name" title="Template Name" />

								<SelectField name="category_id" title="Category" list={catList} />
							</div>
						</div>

						<div className="detail_card">
							<div className="heading_section">
								<h2>Header (optional)</h2>
								<p>Add a title, or select the media type you want to get approved for this template's header</p>
							</div>

							<div className="plan_detail_section">
								<div className="radio_section">
									<ul>
										<li>
											<RadioComponent name="headerType"
												title="Header"
												options={headerOption}
											 />
										</li>

										
									</ul>
								</div>

								<InputText name="headerText" />

								
							</div>
						</div>

						<div className="detail_card">
							<div className="heading_section">
								<h2>Body</h2>
							</div>

							<div className="plan_detail_section">
								<TextAreaField name="body" rows="15" />

								<div className="d_flex justify_content_between align_center">
									<div className="d_flex">
										<BsTypeBold className="me-10" />
										<BsTypeItalic />
									</div>
									
									<div>
										<a href="#">Add Variable</a>
									</div>
								</div>
							</div>
						</div>

						<div className="detail_card">
							<div className="heading_section">
								<h2>Footer (optional)</h2>
								<p>Add a short line of text to the bottom of your message template</p>
							</div>

							<div className="plan_detail_section">
								<InputText name="footer" />
							</div>
						</div>

						<div className="detail_card">
							<div className="heading_section">
								<h2>Buttons (optional)</h2>
								<p>Create buttons that let customers respond to your message or take action.</p>
							</div>

							<div className="plan_detail_section">
								<div className="method_section p-10 mb-20">
									<div className="text_section">
										<h5>Type of action</h5>
									</div>
									<div className="d_flex">
										<div className="me-10" style={{ width: '35%' }}>
											<SelectField name="action_1_id" title="Category" list={[]} />
										</div>

										<div style={{ width: '65%' }}>
											<InputText name="action_1_name" />
										</div>
									</div>

									<div className="text_section">
										<h5>URL Type</h5>
									</div>
									<div className="d_flex">
										<div className="me-10" style={{ width: '35%' }}>
											<SelectField name="action_2_id" title="Category" list={[]} />
										</div>

										<div style={{ width: '65%' }}>
											<InputText name="action_2_name" />
										</div>
									</div>
								</div>

								<ButtonComponent className="secondary" name="Add Button" />
							</div>
						</div>
				 	</div>

				 	<div style={{ width: '35%' }}>
				 		<div className="mobile_section">
				      <div className="mobile_header_section">
				        <div className="left_section">
				          <a href="#">
				            <BsChevronLeft />
				          </a>
				        </div>

				        <div className="user_section">
				          <div className="img_section">
				            <img src={user_img} />
				          </div>

				          <div className="text_section">
				            <h5>FluxCart</h5>
				            <p>tap here for contact</p>
				          </div>
				        </div>

				        <div className="icon_section">
				          <ul>
				            <li>
				              <a href="#">
				                <BsCameraVideo />
				              </a>
				            </li>

				            <li>
				              <a href="#">
				                <BsTelephone />
				              </a>
				            </li>
				          </ul>
				        </div>
				      </div>

				      <div className="mobile_body_section">
				        <div className="badge_section">
				          <p>Fri, Jul 26</p>
				        </div>

				        <div className="msg_section">
				          <div className="text_section">
				            <p>Thankyou for you purchase</p>
				          </div>

				          <div className="link_section">
				            <p><a href="#"><BsBoxArrowUpRight /> Buy Now</a></p>
				          </div>
				        </div>
				      </div>

				      <div className="mobile_footer_section">
				        <div>
				          <a href="#">
				            <BsPlusLg />
				          </a>
				        </div>

				        <div className="input_field_section">
				          <input type="text" name="name" />
				          <a href="#">
				            <BsFillStickyFill />
				          </a>
				        </div>

				        <div className="icon_section">
				          <ul>
				            <li>
				              <a href="#">
				                <BsCamera />
				              </a>
				            </li>

				            <li>
				              <a href="#">
				                <BsMic />
				              </a>
				            </li>
				          </ul>
				        </div>
				      </div>
				    </div>
				 	</div>
				 </div>

				 <hr />

				 <div className="text_right">
				 	<ButtonComponent className="secondary me-15" name="Cancel" />
				 	

				 	<Button 
            type='submit'
            size='md'
            border='1px'
            borderColor='#047E60'
            bg="#047E60"
            px="30px"
            py="10px"
            color="#fff"
            fontSize="14px"
            fontFamily='Open Sans, sans-serif'
          >
           Save
          </Button>
				 </div>
				 </Form>
			</div>

		
		</>
	);
}

export default TempalateDetail;