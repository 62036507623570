import React from 'react';
import ButtonComponent from '../../components/ButtonComponent'
import Mastercard from '../../assets/images/mastercard.png'
import Visa from '../../assets/images/visa.png'

function PaymentSetting(){
	return(
		<>
			<div className="setting_detail_section">
				<h2 className="main_heading">Payments</h2>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Supported Payment Methods</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="method_section d_flex justify_content_between mb-20">
							<div className="text_section">
								<h4>Stripe</h4>
								<p className="mb-10">Transaction Fee 1%</p>

								<div className="img_section d_flex">
									<div className="img_box">
										<img src={Visa} />
									</div>

									<div className="img_box mx_5">
										<img src={Mastercard} />
									</div>
								</div>
							</div>

							<div>
								<ButtonComponent name="Activate" className="primary" />
							</div>
						</div>

						<div className="method_section d_flex justify_content_between mb-20">
							<div className="text_section">
								<h4>PayPal</h4>
								<p className="mb-10">Transaction Fee 1%</p>

								<div className="img_section d_flex">
									<div className="img_box">
										<img src={Visa} />
									</div>

									<div className="img_box mx_5">
										<img src={Mastercard} />
									</div>
								</div>
							</div>

							<div>
								<ButtonComponent name="Activate" className="primary" />
							</div>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Manual Payment Methods</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="text_section mb-15">
							<p>Payments that are made outside your online store. When a customer selects a manual payment method such as cash on delivery, you'll need to approve their order before it can be fulfilled.</p>
						</div>

						<div className="list_section text_section d_flex justify_content_between">
							<h5>Bank Deposits</h5>

							<a>Manage</a>
						</div>

						<div className="list_section text_section d_flex justify_content_between">
							<h5>Bank Deposits</h5>

							<a>Manage</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PaymentSetting;