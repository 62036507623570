import React,{useEffect,useState} from 'react';

import { FaArrowLeft, FaRegClone, FaSearch } from "react-icons/fa";
import { Button,HStack,Box,Heading,Text,Checkbox, Input,Spinner } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,Image
} from '@chakra-ui/react'
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import product_shirt_image from '../../assets/images/product_shirt_img.png'
import { FiX } from "react-icons/fi";
import moment from 'moment';


const Detail = (rs) => {
	const [loading,setLoading] = useState(true);
	useEffect(() => {
    if (rs) {
      setLoading(false);
    }
  }, [rs]);

	const customer = rs?.rs;
	const totalAmount = customer?.Orders?.reduce((sum, order) => sum + order.order_amount, 0) || 0;
	const lastOrder = customer?.Orders?.[customer.Orders.length - 1] || {};

	const cartData = lastOrder?.Cart?.cart_data ?  JSON.parse(lastOrder?.Cart?.cart_data) : [];
	console.log(cartData)

	return (

			<>
			{loading &&
				<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
				<Box className="customer_detail_page">
					<HStack className="head_section">
						<Box className="arrow_section">
						 <a href="/customer"><FaArrowLeft /></a>
							
						</Box>

						<Box className="text_section">
							<HStack justifyContent="space-between">
								<Box mb="15px">
									<Heading>{customer.name}</Heading>

									<Text>Karachi, Pakistan</Text>
								</Box>

								<Box>
									<Button className="danger">Delete Customer</Button>
								</Box>
							</HStack>
						</Box>
					</HStack>

					<HStack className="data_section">
						<Box w="68%" me="10px">
							<Box className="amount_data_section">
								<HStack>
									<Box w="50%" borderRightWidth="1px" borderColor="#E0E2E4" py="5px">
										<Text>Amount Spent</Text>
										<Heading>Rs {totalAmount}</Heading>
									</Box>

									<Box w="50%" py="5px">
										<Text>Orders</Text>
										<Heading>{customer?.Orders?.length}</Heading>
									</Box>
								</HStack>
							</Box>

							<Box className="items_data_section">
								<Heading mb="10px !important">Last order placed</Heading>

								{lastOrder ? (
								<>

								<HStack justifyContent="space-between" mb="10px">
									<Box>
										<HStack alignItems="center">
											<Heading><Link>#{lastOrder.id}</Link> </Heading>

											<HStack className="badges_section">
												<Box className="badge_box">{lastOrder.payment_status === 1 ? 'Payment pending' :'Paid'}</Box>
												<Box className="badge_box badge_yellow">{lastOrder.order_status === 1 ? 'Unfulfilled' :'Fulfilled'}</Box>
											</HStack>
										</HStack>
									</Box>

									<Box>
										<Heading>Rs {lastOrder.order_amount}</Heading>
									</Box>
								</HStack>

								<Text>{moment(lastOrder.created_at).format('DD MMMM, YYYY h:mm:ss a')}</Text>

								<TableContainer mt="30px">
									<Table>
										<Tbody>
										 {cartData?.product_items?.map((item) => (
					                <Tr key={item.product_retailer_id}>
					                    <Td>
					                        <Box className="img_box">
					                            <Image src={product_shirt_image} />
					                        </Box>
					                    </Td>
					                    <Td>
					                        <Link>{item.product_retailer_id}</Link>
					                    </Td>
					                    <Td>Rs {item.item_price} x {item.quantity}</Td>
					                    <Td>Rs {item.item_price * item.quantity}</Td>
					                </Tr>
					            ))}




											{/*<Tr>
												<Td><Box className="img_box"><Image src={product_shirt_image} /></Box></Td>
												<Td><Link>Casual Shirt Small/Black</Link></Td>
												<Td>Rs 1,500 x 1</Td>
												<Td>Rs 1,500</Td>
											</Tr>

											<Tr>
												<Td><Box className="img_box"><Image src={product_shirt_image} /></Box></Td>
												<Td><Link>Casual Shirt Small/Black</Link></Td>
												<Td>Rs 1,500 x 1</Td>
												<Td>Rs 1,500</Td>
											</Tr>*/}



										</Tbody>
									</Table>
								</TableContainer>

								<HStack justifyContent="end" mt="10px">
									<Text me="15px !important"><Link to="/orders">View all orders </Link></Text>
									{/*<Button className="primary">Create order</Button>*/}
								</HStack>

								</>
            ) : (
              <Text>No orders found</Text>
            )}
							</Box>
						</Box>

						<Box w="32%" className="right_section">
							<Box className="data_section">
								<Box className="customer_data_section">
									<HStack justifyContent="space-between" alignItems="flex-start">
										<Box>
											<Heading>Customer</Heading>

											<Text mb="10px !important"><Link>{customer.phone}</Link></Text>

											<Text>+{customer.phone}</Text>
										</Box>

										<Box>
											<Text><Link>Edit</Link></Text>

											<FaRegClone style={{marginTop: 12}} />

											<FaRegClone style={{marginTop: 10}} />
										</Box>
									</HStack>
								</Box>

								<Box className="customer_data_section">
									<HStack alignItems="flex-start" justifyContent="space-between">
										<Box>
											<Heading>Default Address</Heading>

											<Text>{customer.name}</Text>
											<Text>{customer.address}</Text>
											{/*<Text>North Nazimabad,</Text> 
											<Text>Karachi</Text>*/}
											<Text>{customer.email}</Text>
										</Box>

										<Box>
											<Text><Link>Edit</Link></Text>

											<FaRegClone style={{marginTop: 10}} />
										</Box>
									</HStack>
								</Box>

								<Box className="customer_data_section">
									<Heading>Marketing</Heading>

									<Box className="marketing_box">Opt-in for WhatsApp</Box>
								</Box>
							</Box>

							<Box className="tags_data_section">
								<HStack justifyContent="space-between" alignItems="flex-start">
									<Heading>Tags</Heading>

									<Link>Manage</Link>
								</HStack>

								<Box className="search_bar_section">
									<FaSearch />

									<Input type="text" placeholder="Find or create new tags" />
								</Box>

								<HStack>
									<Box className="tag_badge">
										<HStack>
											<Text>Frequent Buyer</Text>

											<FiX />
										</HStack>
									</Box>

									<Box className="tag_badge">
										<HStack>
											<Text>High Spender</Text>

											<FiX />
										</HStack>
									</Box>
								</HStack>
							</Box>
						</Box>
					</HStack>
				</Box>
			</>
		)
}

export default Detail