import React,{useState} from 'react';
import { Routes, Route, Link, Outlet,useLocation } from 'react-router-dom';
import Header from '../components/Header'
import { BsArrowLeftSquare, BsShop } from "react-icons/bs";
import SelectField from '../components/SelectField'
import StoreDetail from './settings/StoreDetail'
import Plan from './settings/Plan'
import Billing from './settings/Billing'
import UserPermission from './settings/UserPermission'
import WhatsappSetting from './settings/WhatsappSetting'
import WhatsappBusinessProfile from './settings/WhatsappBusinessProfile'
import WhatsappMarketingTemplates from './settings/WhatsappMarketingTemplates'
import PaymentSetting from './settings/PaymentSetting'
import StripeSetting from './settings/StripeSetting'
import CheckoutSetting from './settings/CheckoutSetting'
import ProductSetting from './settings/ProductSetting'
import ShippingSetting from './settings/ShippingSetting'
import NotificationSetting from './settings/NotificationSetting'
import PoliciesSetting from './settings/PoliciesSetting'
import store_icon from '../assets/images/setting_nav_icon/store_icon.png'
import store_icon_active from '../assets/images/setting_nav_icon/store_icon_active.png'
import plan_icon from '../assets/images/setting_nav_icon/plan_icon.png'
import plan_icon_active from '../assets/images/setting_nav_icon/plan_icon_active.png'
import billing_icon from '../assets/images/setting_nav_icon/billing_icon.png'
import billing_icon_active from '../assets/images/setting_nav_icon/billing_icon_active.png'
import user_icon from '../assets/images/setting_nav_icon/user_icon.png'
import user_icon_active from '../assets/images/setting_nav_icon/user_icon_active.png'
import whatsapp_icon from '../assets/images/setting_nav_icon/whatsapp_icon.png'
import whatsapp_icon_active from '../assets/images/setting_nav_icon/whatsapp_icon_active.png'
import marketing_icon from '../assets/images/setting_nav_icon/marketing_icon.png'
import payment_icon from '../assets/images/setting_nav_icon/payment_icon.png'
import payment_icon_active from '../assets/images/setting_nav_icon/payment_icon_active.png'
import checkout_icon from '../assets/images/setting_nav_icon/checkout_icon.png'
import checkout_icon_active from '../assets/images/setting_nav_icon/checkout_icon_active.png'
import product_icon from '../assets/images/setting_nav_icon/product_icon.png'
import product_icon_active from '../assets/images/setting_nav_icon/product_icon_active.png'
import shipping_icon from '../assets/images/setting_nav_icon/shipping_icon.png'
import shipping_icon_active from '../assets/images/setting_nav_icon/shipping_icon_active.png'
import notification_icon from '../assets/images/setting_nav_icon/notification_icon.png'
import notification_icon_active from '../assets/images/setting_nav_icon/notification_icon_active.png'
import policies_icon from '../assets/images/setting_nav_icon/policies_icon.png'
import policies_icon_active from '../assets/images/setting_nav_icon/policies_icon_active.png'

const Setting = () => {
 	const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = (url) => {
        setActiveItem(url);
    };

	return(
		<>
			<Header />

			<div className="body_section">
				<div className="container">
					<div className="setting_page">
						<div className="setting_menu_section">
							<div className="back_menu_section">
								<a href="/">
									<BsArrowLeftSquare />
									<p>Back to Main Menu</p>
								</a>
							</div>

							<div className="menu_section">
								<ul>
									<li className={activeItem === "/setting" ? "active" : ""}>
										<a href="/setting" onClick={() => handleItemClick("/setting")}>
											<div className="img_box">
												<img src={store_icon} className="icon_img" />
												<img src={store_icon_active} className="icon_img_active" />
											</div>
											<p>Store Details</p>
										</a>
									</li>

									<li className={activeItem === "/setting/plan" ? "active" : ""}>
										<a href="/setting/plan" onClick={() => handleItemClick("/setting/plan")}>
											<div className="img_box">
												<img src={plan_icon} className="icon_img" />
												<img src={plan_icon_active} className="icon_img_active" />
											</div>
											<p>Plan</p>
										</a>
									</li>

									<li className={activeItem === "/setting/billing" ? "active" : ""}>
										<a href="/setting/billing" onClick={() => handleItemClick("/setting/billing")}>
											<div className="img_box">
												<img src={billing_icon} className="icon_img" />
												<img src={billing_icon_active} className="icon_img_active" />
											</div>
											<p>Billing</p>
										</a>
									</li>

									<li className={activeItem === "/setting/user-permission" ? "active" : ""}>
										<a href="/setting/user-permission" onClick={() => handleItemClick("/setting/user-permission")}>
											<div className="img_box">
												<img src={user_icon} className="icon_img" />
												<img src={user_icon_active} className="icon_img_active" />
											</div>
											<p>User and permissions</p>
										</a>
									</li>

									<li className={activeItem === "/setting/whatsapp-setting" ? "active" : ""}>
										<a href="/setting/whatsapp-setting" onClick={() => handleItemClick("/setting/whatsapp-setting")}>
											<div className="img_box">
												<img src={whatsapp_icon} className="icon_img" />
												<img src={whatsapp_icon_active} className="icon_img_active" />
											</div>
											<p>WhatsApp Settings</p>
										</a>
									</li>

									<li className={activeItem === "/setting/whatsapp-marketing-template" ? "active" : ""}>
										<a href="/setting/whatsapp-marketing-template" onClick={() => handleItemClick("/setting/whatsapp-marketing-template")}>
											<div className="img_box">
												<img src={marketing_icon} className="icon_img" />
												<img src={marketing_icon} className="icon_img_active" />
											</div>
											<p>Marketing Templates</p>
										</a>
									</li>

									<li className={activeItem === "/setting/payment-setting" ? "active" : ""}>
										<a href="/setting/payment-setting" onClick={() => handleItemClick("/setting/payment-setting")}>
											<div className="img_box">
												<img src={payment_icon} className="icon_img" />
												<img src={payment_icon_active} className="icon_img_active" />
											</div>
											<p>Payments</p>
										</a>
									</li>

									<li className={activeItem === "/setting/checkout-setting" ? "active" : ""}>
										<a href="/setting/checkout-setting" onClick={() => handleItemClick("/setting/checkout-setting")}>
											<div className="img_box">
												<img src={checkout_icon} className="icon_img" />
												<img src={checkout_icon_active} className="icon_img_active" />
											</div>
											<p>Checkout</p>
										</a>
									</li>

									<li className={activeItem === "/setting/product" ? "active" : ""}>
										<a href="/setting/product" onClick={() => handleItemClick("/setting/product")}>
											<div className="img_box">
												<img src={product_icon} className="icon_img" />
												<img src={product_icon_active} className="icon_img_active" />
											</div>
											<p>Products</p>
										</a>
									</li>

									<li className={activeItem === "/setting/shipping" ? "active" : ""}>
										<a href="/setting/shipping" onClick={() => handleItemClick("/setting/shipping")}>
											<div className="img_box">
												<img src={shipping_icon} className="icon_img" />
												<img src={shipping_icon_active} className="icon_img_active" />
											</div>
											<p>Shipping</p>
										</a>
									</li>

									<li className={activeItem === "/setting/notification" ? "active" : ""}>
										<a href="/setting/notification" onClick={() => handleItemClick("/setting/notification")}>
											<div className="img_box">
												<img src={notification_icon} className="icon_img" />
												<img src={notification_icon_active} className="icon_img_active" />
											</div>
											<p>Notifications</p>
										</a>
									</li>

									<li className={activeItem === "/setting/policies" ? "active" : ""}>
										<a href="/setting/policies" onClick={() => handleItemClick("/setting/policies")}>
											<div className="img_box">
												<img src={policies_icon} className="icon_img" />
												<img src={policies_icon_active} className="icon_img_active" />
											</div>
											<p>Policies</p>
										</a>
									</li>
								</ul>
							</div>		
						</div>

						<Outlet />
					</div>
				</div>
				
			</div>
			
		</>
	);
}

export default Setting;