import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../components/ButtonComponent'
import SwitchComponent from '../../components/SwitchComponent'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
 	useDisclosure
} from '@chakra-ui/react'
import { BsCheckCircleFill } from "react-icons/bs";
import { fetchWrapper } from '../../helpers';
import List  from './template/List';
function WhatsappMarketingTemplates(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading,setLoading] = useState(false);
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [listCount, setListCount] = useState(0); 
	const [activePage, setActivePage] = useState(1);
	  

  const [list,setList] = useState([])
  useEffect(() => {
        loadList() 
    }, [activePage]);

  const loadList = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/template-category/list?type=M`;
      const data  = await fetchWrapper.get(url);
      setList(data.data);
      setListCount(data.count);
      setLoading(false);
  }

  const meClose = () => {
  		
		   onClose();
		   
	}
	

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}


	const meDelete = async (rs) => {
			const aa = window.confirm("Are you sure delete this recored");
			if(aa){
					setLoading(true)
					const url = `${process.env.REACT_APP_API_URL}/template_category/list/${rs.id}`;
	  		 await fetchWrapper.delete(url)
	  		 loadList()
	  		 setLoading(false)
			}
	}

	const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

	return(
		<>
			<div className="setting_detail_section">
				<h2 className="main_heading justify_content_between">
					<div>
						<h2 className="mb-5">WhatsApp Marketing Templates</h2>
						<p>These are WhatsApp Marketing Message Templates</p>
					</div>

					<div>
						<ButtonComponent className="secondary me-15" name="Submit to WhatsApp for Approval" />
						<ButtonComponent className="primary" name="Add New Template" />
					</div>
				</h2>

				{list?.map((rs,index) => {

	    		return(

						<div className="detail_card" key={index}>
							<div className="heading_section">			
								<h2>{rs.name}</h2>	
							</div>

							<div className="plan_detail_section pt-5">
								 <List myid={rs.id} />
							</div>
						</div>

					)
				})}


			</div>
		</>
	);
}

export default WhatsappMarketingTemplates;