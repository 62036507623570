import React from 'react';
import { Select } from '@chakra-ui/react'


function SelectField(props) {
	const {name} = props
	return(
		<>
			<div className="field_section">
				<label>{name}</label>
				<Select placeholder='Select option'>
				  <option value='option1'>Option 1</option>
				  <option value='option2'>Option 2</option>
				  <option value='option3'>Option 3</option>
				</Select>
			</div>
		</>
	);
}

export default SelectField;