import React,{useEffect,useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button,HStack,Box,Heading,CloseButton,Checkbox,Badge,Spinner } from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import { InputControl } from "formik-chakra-ui";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react'
import { BsSearch , BsArrowDownUp, BsImages } from "react-icons/bs";


function List({getPage}){


	const [loading,setLoading] = useState(false);

	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
  		setLoading(true)
  		const url = `${process.env.REACT_APP_API_URL}/customer/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  		setLoading(false)
  		
  }

	return (

			<>
			{loading &&
				<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<div className="detail_card">
					<HStack justifyContent="space-between" mb="25px">
						<Box>
							<Heading as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			 				Customers
  			  		</Heading>
						</Box>

						<Box>
							<Button
							type='submit'
              className="btn_export me-15"
							onClick={() => getPage('create')}
							>Export</Button>

							<Button
							type='submit'
              className="btn_export me-15"
							onClick={() => getPage('create')}
							>Import</Button>

							<Button
							type='submit'
              className="primary"
							onClick={() => getPage('create')}
							>Add Customer</Button>
						</Box>
					</HStack>


					<HStack alignItems="unset" flexDirection='column'>
						<Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
							<HStack justifyContent="end" me="20px" my="15px">
							  <HStack>
							  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsSearch />} />
							  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsArrowDownUp />} />
						  	</HStack>
						  </HStack>

				      <div className="table_section">
								<TableContainer>
								  	<Table>
								  		<Thead>
								  			<Tr>
								  				<Th><Checkbox></Checkbox></Th>
								  				<Th>Customer name</Th>
								  				<Th>Opt-In</Th>
								  				<Th>Location</Th>
								  				<Th>Orders</Th>
								  				<Th>Amount Spent</Th>
								  				
								  			</Tr>
								  		</Thead>
									    <Tbody>
									    {list?.map(rs => {
									    	const totalAmount = rs?.Orders?.reduce((sum, order) => sum + order.order_amount, 0) || 0;
									    	return (
									      <Tr key={rs.id}>     
									        <Td><Checkbox></Checkbox></Td>
									        <Td><b><a href="#" style={{color:'#2F6DC9'}} onClick={() => getPage('detail',rs)}>{rs.name}</a></b></Td>
									        <Td><Badge bg="#ACE8CF" py="5px" px="15px" borderRadius="25px" color="#2B2F27" fontWeight="400" textTransform="capitalize">Yes</Badge></Td>
									        <Td>{rs.address}</Td>
									        <Td>{rs?.Orders?.length} order</Td>
									        <Td>Rs {totalAmount}</Td>        
									      </Tr>
									       )
									    }
									    	)}
									    </Tbody>
									  </Table>
								</TableContainer>
							</div>
							
						</Box>
					</HStack>
					
			</div>

			</>

		)
}
export default List;