import React from 'react';
import { Checkbox } from '@chakra-ui/react'

function CheckBox(props) {
	const {name,label,...rest} = props
	return(
		<>
			<div className="field_section">
				<Checkbox>{label}</Checkbox>
			</div>
		</>

	);
}

export default CheckBox;