import React,{useEffect,useState} from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import ButtonComponent from '../../../components/ButtonComponent'
import Mastercard from '../../../assets/images/mastercard.png'
import { fetchWrapper } from '../../../helpers';
import { Button ,HStack,Spinner } from '@chakra-ui/react'
import CustomModalLink from '../../../components/FormElement/CustomModalLink';
import ShowFeature from './showfeature'

const  List = ({getPage,authUser,rs})=>{
	const user_id = rs ? rs.id : authUser.id;

	const [planId , setPlanId] = useState()
	const [list , setList] = useState([])
	const [features, setFeatures] = useState([]);
	const [loading,setLoading] = useState(false);
	 
	useEffect(() => {
     
		
       	loadUserList(user_id)
        
       
        
    }, [user_id]);

    const loadUserList = async (user_id) => {
    	setLoading(true)
		const url = `${process.env.REACT_APP_API_URL}/user/${user_id}`;
  		const data  = await fetchWrapper.get(url);
  		const plan_id = data?.data?.Tenent?.plan_id;
  		setPlanId(plan_id)
  		loadList(plan_id)
  		setLoading(false)
    }

	const loadList = async (plan_id) => {
		const url = `${process.env.REACT_APP_API_URL}/plan/${plan_id}`;
  		const data  = await fetchWrapper.get(url);
  		setList(data.data)  
  		const parsedFeatures = JSON.parse(data.data.feature);
    	setFeatures(parsedFeatures);
    }

	return(
		<>
		{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}
			<div className="setting_detail_section">
				<h2 className="main_heading">Plan</h2>

				<div className="text_section mt-20">
					<h4>Plan details</h4>
					<p>Manage or change your plan. View our <a href="#">terms of service</a> and <a href="#">privacy policy.</a></p>
				</div>


				<div className="detail_card">
					<div className="heading_section">
						<div className="d_flex justify_content_between align_center">
							<div>
								<h2>{list?.name}</h2>

								<p>{list?.description}</p>
							</div>

							<div className="d_flex">
								<p>USD</p>
								<h3 className="mx_5">${list?.price}</h3>
								<p>/mo</p>
							</div>
						</div>
					</div>



					<div className="plan_detail_section" style={{ backgroundColor: '#F9FAFA' }}>
						<div className="d_flex justify_content_between">
							<div className="text_section">
								<h5 className="mb-10">Your plan includes</h5>

								{features?.slice(0, 5)?.map((item, index) => (
						           <p className="mb-15 d_flex align_center"><BsFillCheckCircleFill/>{item.feature}</p>
						        ))}
							</div>

							<div>
							<a>
							<CustomModalLink
						        showModalButtonText='Show all features'
						        modalHeader="Starter Plan Features"
						        modalBody=<ShowFeature rs={features}/>
						      />
						     </a>
							</div>
						</div>
					</div>

					<div className="plan_detail_section">
						<div className="d_flex justify_content_between">
							<div className="text_section">
								<h5>Next billing date</h5>

								<p>April 3, 2023 for $49.00 USD</p>
							</div>

							<div>
								<a href="#">Change billing cycle</a>
							</div>
						</div>
					</div>

					<div className="plan_detail_section">
						<div className="d_flex justify_content_between">
							<div className="text_section">
								<h5 className="mb-10">Payment method</h5>

								<p className="d_flex align_center"><img src={Mastercard} /> Mastercard ending in 1109</p>
							</div>

							<div>
								<a href="#">Change Payment Method</a>
							</div>
						</div>
					</div>

					<div className="plan_detail_section">
						<div className="text_right">
							<ButtonComponent name="Change Plan" className="primary" onClick={()=> getPage('create',authUser,planId)}  />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default List;