import React,{useEffect,useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Input,
  TableContainer,
  Text,
  Image
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button,HStack,Box,Heading,CloseButton,Checkbox,Badge ,Spinner} from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react'
import { BsSearch , BsArrowDownUp, BsImages ,BsCaretDownFill } from "react-icons/bs";
import moment from 'moment';
import { InputText , Form } from '../../../components/FormElement';

import chart_img from '../../../assets/images/chart.png';

function List({getPage}){

 const [selectedTab, setSelectedTab] = useState('all');
	const [formData, setFormData] = useState({
        name :""

      });


	const [loading,setLoading] = useState(false);
	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async (name ="") => {
  	setLoading(true)
  		const url = `${process.env.REACT_APP_API_URL}/order/list?name=${name}`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data);
  		setLoading(false)
  }

  const filterList = (status,order_status,payment_status) => {
  	
    if (status === 'all') {
      return list;
    }
    return list.filter((item) => {
    if (status === 'Unpaid') {
      return item.payment_status === 1;
    } else if (status === "Closed") {
      return item.status_id === 10;
    } else if (status === "Unfulfilled"){
    	return item.order_status === 1
    }
    else if (status === "Open"){
    	return item.order_status === 10
    }

    else {
      return "";
    }
  });
  };

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

           let  data = values
          
           
         loadList(data.name)
         
    }

  const exportToCSV = async (status) => {
    // const filteredData = filterList(status);
    // const csvContent = convertToCSV(filteredData);
    // downloadCSV(csvContent, `orders_${status}.csv`);
    console.log(status)
  };  


	return (

			<>
			{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}
			<div className="detail_card">
					<HStack justifyContent="space-between" mb="25px">
						<Box>
							<Heading as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			 				Orders
  			  		</Heading>
						</Box>

						<Box>
							<Button
							type='submit'
              className="btn_export me-15"
							onClick={() => exportToCSV(selectedTab)}
							>Export</Button>

							<Button 
							type='submit'
              className="primary"
							onClick={() => getPage('create')}
							>Create Order</Button>
						</Box>
					</HStack>


					<HStack justifyContent="space-between" mb="25px" className="black_bar" w='100%'>
						<Box w="15%" p="31px"  borderRight="3px solid #000000">
							<HStack alignItems="center">
								<Box>
									<Text mb="0px">7 Days </Text>
								</Box>
								<Box>
									<BsCaretDownFill />
								</Box>
							</HStack>
							
						</Box>
						<Box w="35%" position="relative" p="20px" borderRight="3px solid #000000">
							<HStack justifyContent="space-between" alignItems="center">
								<Box>
									<Text mb="2px">Orders</Text>
									<Text mb="0px">3</Text>
								</Box>
								<Box w="50%" position="absolute" bottom="0" right="0">
								
									<Image src={chart_img}/>
									
								</Box>
							</HStack>
						
							
						</Box>
						<Box w="50%" p="20px">
							<HStack justifyContent="space-between" alignItems="center">
								<Box >
									<Text mb="2px">Ordered Items</Text>
									<Text mb="0px">4</Text>
								</Box>
							</HStack>
							
						</Box>
					</HStack>


					<HStack alignItems="unset" flexDirection='column'>
						<Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
							<Tabs variant='soft-rounded' colorScheme='green'>
								<HStack justifyContent="space-between" me="20px">
								  <TabList my="15px" mx="20px">
								   {['all', 'Unfulfilled', 'Unpaid', 'Open', 'Closed'].map((status) => (
                    <Tab
                      key={status}
                      px="4"
                      py="1"
                      borderRadius="5px"
                      textTransform="capitalize"
                      fontSize="12px"
                      fontFamily="Open Sans, sans-serif"
                      onClick={() => {
                      setSelectedTab(status);
                      loadList()
                      }}
                      isSelected={selectedTab === status}
                    >
                      {status}
                    </Tab>
                  ))}
								    {/*<Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'>All</Tab>
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'>Unfulfilled</Tab>
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'>Unpaid</Tab>
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'>Open</Tab>
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'>Closed</Tab>*/}
								  </TabList>

								  <Form
	                    enableReinitialize
	                    // validationSchema={FormSchema}
	                    initialValues={formData}
	                    onSubmit={onSubmit}
	                >
								  	<HStack>
								  		<Box className="search_bar">								  			 
		                        <InputText name="name"  mb="0px"/>                    
								  		</Box>
								  		
									  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsSearch />} type="submit"/>
									  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsArrowDownUp />} />
								  	</HStack>
							  	</Form>	
							  </HStack>

							  <TabPanels>
							  	{['all', 'Unfulfilled','Unpaid','Open','Closed'].map((status,order_status,payment_status,index)=>(
							  		<TabPanel p='0'>
							      <div className="table_section">
											<TableContainer>
											  	<Table>
											  		<Thead>
											  			<Tr>
											  				<Th><Checkbox></Checkbox></Th>
											  				<Th>Order #</Th>
											  				<Th>Date</Th>
											  				<Th>Customer</Th>
											  				<Th>Total</Th>
											  				<Th>Payment Status</Th>
											  				<Th>Fulfillment Status</Th>
											  			</Tr>
											  		</Thead>
												    <Tbody>
												    {filterList(status,order_status,payment_status)?.map(rs => {

												    	return (
												      <Tr key={rs.id}>     
												        <Td><Checkbox></Checkbox></Td>
												        <Td>
												        <Link to={`/order-detail/${rs.id}`} state={{id:rs.id}}>
												        	#{rs.id}
												        	</Link>

												        </Td>
												        <Td>{moment(rs.order_date).format('DD MMMM, YYYY')} </Td>      
												        <Td>{rs?.Customer?.name}</Td>
												        <Td>Rs. {rs.order_amount}</Td> 
												        <Td><Badge bg={rs.payment_status === 1 ? '#FFD59B' : '#E3E4E6'} py="5px" px="15px" borderRadius="25px" color="#252627" fontWeight="600" textTransform="capitalize">{rs.payment_status === 1 ? 'Payment pending' : 'Paid'}</Badge></Td>
												        <Td><Badge bg={rs.order_status === 1 ? '#FFD59B' : '#E3E4E6'} py="5px" px="15px" borderRadius="25px" color="#252627" fontWeight="600" textTransform="capitalize">{rs.order_status === 1 ? 'Unfulfilled' : 'fulfilled'}</Badge></Td> 
												      </Tr>
												       )
												    }
												    	)}
												    </Tbody>
												  </Table>
											</TableContainer>
										</div>
							    </TabPanel>

							  	))}
							  </TabPanels>
							</Tabs>
							
						</Box>
					</HStack>
					
			</div>

			</>

		)
}
export default List;