import React,{useEffect,useState} from 'react';
import { BsCheck2 } from "react-icons/bs";
import ButtonComponent from '../../../components/ButtonComponent'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner
} from '@chakra-ui/react';


import { fetchWrapper } from '../../../helpers';


const ChangePlan = ({authUser ,getPage,rs})=>{
	const plan_id = rs ;
	const user_id = authUser.id;

	const [list , setList] = useState([])
	 const [loading,setLoading] = useState(false);
	useEffect(() => {
     
		
      	loadList();
       	
        
       
        
    }, []);

	const loadList = async () => {
		const url = `${process.env.REACT_APP_API_URL}/plan/list`;
  		const data  = await fetchWrapper.get(url);
  		setList(data.data.rows)  
    }

    const change = async(rs)=>{
    	setLoading(true)
    	// console.log(rs)
    	const url = `${process.env.REACT_APP_API_URL}/user/update`;
    	const data = {
    		plan_id : rs.id,
    		id:user_id
    	}
    	console.log(data)
    	await fetchWrapper.post(url,data);
    	getPage('List', authUser, data);
    	setLoading(false)
    }
	

	return(
		<>
		{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<div className="setting_detail_section">
				<h2 className="main_heading">Change Plan</h2>

				<HStack w="100%" alignItems="baseline" flexWrap="wrap" gap="10px">
				{list?.map((rs,index)=>{
					return(
						<>

						<Box className="sub_section" w="30%" key={index} mb="20px" >
							<Text mb="0px" className="main_head">{rs.name}</Text>

							<Text h="40px">{rs.description}</Text>

							<HStack borderBottom="1px solid #E0E2E4">
								<Box className="price_section">
									<Text mb="0px">$ {rs.price}</Text>
								</Box>
								<Box className="detail">
									<Text mb="0px">USD</Text>
									<Text mb="0px">/month</Text>
								</Box>
								 
							</HStack>

							<Box h="300px">
							{JSON.parse(rs.feature).slice(0, 5).map((item,featureIndex)=>(
								<HStack className="feature_section">


								<Box><BsCheck2/></Box>
								<Box>
									<Text mb="0px">{item.feature}</Text>
								</Box>
								</HStack>
							))}
							</Box>


							<div className="plan_detail_section">
								<div style={{textAlign:'center'}} >
									<ButtonComponent name={rs.id === plan_id ? "Current Plan" : "Change Plan"} className={rs.id === plan_id ? "current" : "primary"} onClick={()=> change(rs)} />
								</div>
							</div>
						</Box>
							
						</>
					)
				})}
				</HStack>

			</div>

			

		</>
		)
}
export default ChangePlan;