import React,{useEffect,useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  IconButton,
  Image,
  Badge
} from '@chakra-ui/react'
import { BsSearch , BsArrowDownUp, BsImages } from "react-icons/bs";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button,HStack,Box,Heading,CloseButton,Link ,Checkbox,Spinner} from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import { Routes, Route, Outlet } from 'react-router-dom';
import { InputText , Form } from '../../../components/FormElement';

function List({getPage,random}){

	const [loading,setLoading] = useState(false);

	const [sortOrder, setSortOrder] = useState('asc');
	const [formData, setFormData] = useState({
        name :""

      });



	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, [random]);

  const loadList = async (name="") => {
  		setLoading(true)
  		const url = `${process.env.REACT_APP_API_URL}/product/list?name=${name}`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  		setLoading(false)
  }


  const syncCat = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/facebook/product`;
  		const data  = await fetchWrapper.get(url)
  		loadList();
  }

    const pushCat = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/facebook/product-push`;
  		const data  = await fetchWrapper.get(url)
  		loadList();
  }

  const filterList = (status) => {
    if (status === 'all') {
      return list;
    }
    return list.filter((item) => (status === 'active' ? item.status_id === 1 : item.status_id === 10));
  };

  	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

           let  data = values
          
           
         await loadList(values.name)
    }

    const Sorting =()=>{
    	const sortedList = [...list].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setList(sortedList);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
    }

	return (

			<>
			{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}
			<div className="detail_card">
					<HStack justifyContent="space-between" mb="25px">
						<Box>
							<Heading as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			 				Product
  			  		</Heading>
						</Box>

						<Box>

						<Button
							type='submit'
              size='md'
              border='1px'
              borderColor='blue'
              bg="blue"
              px="30px"
              mr="10px"
              py="10px"
              color="#fff"
              fontSize="14px"
              fontFamily='Open Sans, sans-serif'
							onClick={() => syncCat()}
							>Sync Product With Facebook</Button>


							<Button
							type='submit'
              size='md'
              border='1px'
              borderColor='red'
              bg="red"
              px="30px"
              mr="10px"
              py="10px"
              color="#fff"
              fontSize="14px"
              fontFamily='Open Sans, sans-serif'
							onClick={() => pushCat()}
							>Push Product With Facebook</Button>

							<Button
							type='submit'
              className="primary"
							onClick={() => getPage('create')}
							>Add Product</Button>
						</Box>
					</HStack>

					<HStack alignItems="unset" flexDirection='column'>
						<Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
							<Tabs variant='soft-rounded' colorScheme='green'>
								<HStack justifyContent="space-between" me="20px">
								  <TabList my="15px" mx="20px">
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif' onClick={()=>loadList()}>All</Tab>
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'  onClick={()=>loadList()}>Active</Tab>
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'  onClick={()=>loadList()}>Archived</Tab>
								  </TabList>

								  
								  <Form
                      enableReinitialize
                      // validationSchema={FormSchema}
                      initialValues={formData}
                      onSubmit={onSubmit}
                  >
	                  <HStack>
								  		<Box className="search_bar">
							  		   <InputText name="name" mb="0px"/>							  			
								  		</Box>
								  		
									  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsSearch />} type="submit" />
									  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsArrowDownUp />} onClick={()=> Sorting()} />
																		  	
								  	</HStack>
							  	</Form>
								</HStack>

							  <TabPanels>
							  	{['all', 'active','archived'].map((status,index)=>(
							  		<TabPanel p='0' key={index}>
							    	<div className="table_section">
											<TableContainer>
											  	<Table>
											  		<Thead>
											  			<Tr>
											  			<Th><Checkbox></Checkbox></Th>
											  			<Th></Th>
											  			<Th>Sync</Th>
											  				<Th>Product Name</Th>
											  				<Th>Status</Th>
											  				<Th>Inventory</Th>
											  				<Th>Type</Th>
											  				<Th></Th>
											  				<Th></Th>
											  				
											  			</Tr>
											  		</Thead>
												    <Tbody>
												    {filterList(status)?.map(rs => {

												    	return (
												      <Tr key={rs.id}

												      >
												      <Td><Checkbox></Checkbox></Td>     
												      <Td>
												      {rs.image != '' && rs.image !== null ? (

												      			<Image
																		    boxSize='50px'
																		    objectFit='cover'
																		    src={`${rs.image_url}`}
																		    alt={rs.name}
																		  />


												      		) : (


												      		<IconButton bg="transparent" border="1px dashed" borderColor="#E0E2E4" borderRadius="3px" color="#878D92" aria-label='Search database' icon={<BsImages />} />

												      		) }

												      		</Td>
												      		<Th>{rs.sync_status == 1 ? 'Yes' : 'No'}</Th>
												        <Td onClick={() => getPage('edit',rs)} cursor="pointer" style={{color:'#2F6DC9'}}>{rs.name}</Td>
												        <Td><Badge bg="#ACE8CF" py="5px" px="15px" borderRadius="25px" color="#252627" fontWeight="200" textTransform="capitalize">{rs.status_id === 1 ? 'Active' : 'Inactive'}</Badge></Td>
												        <Td></Td>
												        <Td>{rs?.ProductType?.name}</Td>
												        <Td>
												        		<Link onClick={() => getPage('edit',rs)}>Edit</Link>
												        </Td>	
												        <Td></Td>
												      </Tr>
												       )
												    }
												    	)}
												    </Tbody>
												  </Table>
											</TableContainer>
										</div>
							    </TabPanel>

							  	))}
							    
							   
							    
							  </TabPanels>
							</Tabs>
							
						</Box>
					</HStack>

					
			</div>

			</>

		)
}
export default List;