import { useEffect,useState } from 'react';
import { fetchWrapper } from '../../../helpers';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'
const AddVarient = ({cat_id,product_id,loadVarient,meCloseEdit}) => {

	const [attributeCatList, setAttributeCatList] = useState([]);
	const [attributeList, setAttributeList] = useState([]);

	useEffect(() => {
        loadCatAttribute()
        loadAttribute();
       
        
    }, []);
    const loadCatAttribute = async() => {
      const url = `${process.env.REACT_APP_API_URL}/category/by-id/?id=${cat_id}`;
         const data = await fetchWrapper.get(url);
         
         setAttributeCatList(JSON.parse(data.data.question_list));
    }

    const loadAttribute = async() => {
      const url = `${process.env.REACT_APP_API_URL}/attribute/list`;
         const data = await fetchWrapper.get(url);
         setAttributeList(data.data);
    }

    const attributeName = (attribute_id) => {

    	const val =  attributeList.filter(rs => rs.id == attribute_id);
   
    	return val[0]?.name;
 	}

 	const [formData, setFormData] = useState({
        attribute_0:'',
        attribute_2:'',
        sku:'',
        barcode:'',
        price:'',
        inventory:'',
        product_id:product_id,
        

      });

    const FormSchema = Yup.object().shape({
        sku: Yup.string().required('Required'),
        barcode: Yup.string().required('Required'),
        price: Yup.string().required('Required'),
        inventory: Yup.string().required('Required')
        
    });


    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = {
		   		varient_id:values.attribute_0 + '-' + values.attribute_1,
		   		product_id:values.product_id,
		   		varient_name:values.attribute_0 + '-' + values.attribute_1,
		   		sku:values.sku,
		   		barcode:values.barcode,
		   		price:values.price,
		   		inventory:values.inventory


		   }
		   
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/product-varient/create`;
  		 await fetchWrapper.post(url,data)
  		 loadAttribute();
  		 meCloseEdit();
  		 
	}


	return (

			<Form
                        enableReinitialize
                        validationSchema={FormSchema}
                        initialValues={formData}
                        onSubmit={onSubmit}
             >
			
				{attributeCatList?.map((rs,index) => {

						return (
							<>
								<div>
								
								<InputText name={`attribute_${index}`} title={`${attributeName(rs.attribute_id)}`}  />



								</div>

							</>
							)

				})}

				<InputText name="sku" title="SKU"  />
				<InputText name="barcode" title="Barcode"  />
				<InputText name="price" title="Price"  />

				<InputText name="inventory" title="Inventory"  />


				<Button
				            colorScheme='teal'
				            border='1px'
			              borderColor='#047E60'
			              bg="#047E60"
			              px="20px"
			              py="10px"
			              color="#fff"
			              fontSize="14px"
			              fontFamily='Open Sans, sans-serif'
			              mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>
		)
}

export default AddVarient;