import React from 'react';
import { Button ,HStack,Spinner,Box,Text } from '@chakra-ui/react'
const Features = ({rs,onClose})=>{

	
	return(
		<>
			<Box w="100%" >
				{rs.map((rs,index)=>{
					return(
					<>
						<Text mb="10px" fontSize="15px" fontWeigth="300" color="#5C5F62">{rs.feature}</Text>
						<hr style={{border:'1px solid #E8E8E9'}}/>



					</>
				)	
				})}
				<HStack justifyContent="end">
					<Box></Box>
					<Box>
						<Button className="current" onClick={()=> onClose()}>Close</Button>
					</Box>
				</HStack>
				
				

			</Box>		
		</>
	)
}

export default Features;