import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { BsThreeDots } from "react-icons/bs";
import CheckBox from '../../../components/CheckBox'
import RadioComponent from '../../../components/RadioComponent'
import CustomModal from '../../../components/CustomModal'
import EditAttribute from './EditAttribute';
import CustomModalLink from '../../../components/FormElement/CustomModalLink'
import { fetchWrapper } from '../../../helpers';
import CreateAttribute from './CreateAttribute';
import AttributeValue from './AttributeValue';


function Attribute(){

	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/attribute/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  }
	const saveProduct = () => {
			
	}

	const [formData, setFormData] = useState({
    name: ''
  });

  const FormSchema = Yup.object().shape({
	    name: Yup.string()
	          .required('Required')
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/attributes`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
	}

	const meDelete = async(id)=>{
		const aa = window.confirm("Are you sure delete this record");
		if(aa){
			const url = `${process.env.REACT_APP_API_URL}/attribute/delete/${id}`;
			await fetchWrapper.delete(url)	
			loadList()
		}
		
	}

	return(
		<>
			
			
				<div className="detail_card">
					<div className="heading_section d_flex justify_content_between">			
						<h2>Attributes</h2>

					

						<CustomModal
			        showModalButtonText="Add Attributes"
			        modalHeader="Add Attribute"
			        modalBody=<CreateAttribute loadList={loadList} />
			      />
					</div>

					<div className="plan_detail_section">
					

						<div className="table_section">
							<TableContainer>
							  	<Table>
							  		<Thead>
							  			<Tr>
							  				<Th>Attributes</Th>
							  				<Th>Field Type</Th>
							  				<Th>Values</Th>
							  				
							  				<Th></Th>
							  				<Th></Th>
							  			</Tr>
							  		</Thead>
								    <Tbody>
								    {list?.map(rs => {

								    	return (
								      <Tr key={rs.id}>     
								        <Td>{rs.name}</Td>
								        <Td>{rs.field_type}</Td>
								        <Td>{
												        	rs?.values != "" ?
												        	JSON.parse(rs?.values)?.length
												        	: 0

												        }</Td>
								        <Td dir="rtl" className="manage_value">
								        	{(rs.field_type == 'dropdown') ? (

								        			<CustomModal
												        showModalButtonText={`Manage Values`}
												        modalHeader={`Manage Values ${rs.name}`}
												        modalBody=<AttributeValue rs={rs} loadList={loadList} />
												      />

								        	) : null}
								        </Td>      
								        <Td dir="rtl" cursor="pointer">
								        	<div class="dropdown">
													  <a class="dropdown-toggle" data-bs-toggle="dropdown">
													    <BsThreeDots />
													  </a>
													  <ul class="dropdown-menu">
													    <li><a class="dropdown-item"><CustomModalLink
														        showModalButtonText='Edit'
														        modalHeader="Edit Attribute"
														        modalBody=<EditAttribute loadList={loadList} rs={rs}/>
														      /></a></li>
													    <li><a class="dropdown-item" onClick={()=> meDelete(rs.id)}>Delete</a></li>
													  </ul>
													</div>
								        </Td>
								      </Tr>
								       )
								    }
								    	)}
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				

			
			
		</>
	);
}

export default Attribute;