import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'

import { fetchWrapper } from '../../../helpers';
import { toast } from "react-toastify";




function EditProductType({loadList,rs,onClose}){
	

	const [formData, setFormData] = useState({
    name: rs.name,
    id:rs.id,
  });

  const FormSchema = Yup.object().shape({
	    name: Yup.string()
	          .required('Required'),
	      // field_type: Yup.number()
	      // .required('Required')
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/product-type/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList();
  		 onClose();
  		  toast(`Record has been update`,{
  		 	type:'success'
  		 })
	}

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Name"  />

		         <Button
				            colorScheme='teal'
				            border='1px'
			              borderColor='#047E60'
			              bg="#047E60"
			              px="20px"
			              py="10px"
			              color="#fff"
			              fontSize="14px"
			              fontFamily='Open Sans, sans-serif'
			              mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default EditProductType;