import React,{useEffect,useState} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
const CustomModal = ({ showModalButtonText, modalHeader, modalBody,isOpenEdit, onOpenEdit, onCloseEdit}) => {
  


  return (
    <>
     
      
      <Modal isOpen={isOpenEdit} size='xl' onClose={onCloseEdit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalBody}

            
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;