import React from 'react';
import { Input } from '@chakra-ui/react'

function InputText(props) {
	const {name,type,inputValue} = props
	const [value, setValue] = React.useState('')
	const handleChange = (event) => setValue(event.target.value)
	return(
		<>
			<div className="field_section">
				<label>{name}</label>
				
				<Input type={type} onChange={handleChange} className="input_field" />
			</div>
		</>
	);
}

export default InputText;