import React,{useEffect,useState} from 'react';
import { BsWhatsapp, BsFillFileEarmarkArrowDownFill } from "react-icons/bs";
import InputText from '../../../components/InputText'
import ButtonComponent from '../../../components/ButtonComponent'
import Profile from './Profile'
import { tenentActions } from '../slice/tenent.slice.js';
import { useSelector, useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../helpers';
import { Button ,HStack,Spinner} from '@chakra-ui/react'


function Settings(props){
	const [loading,setLoading] = useState(false);
	const [tenent,setTenent] = useState({})
	const {getPage} = props;
	
	
	 useEffect(() => {
        loadData();
        
        
    }, []);

	
	const loadData = async () => {
		setLoading(true)
  		const url = `${process.env.REACT_APP_API_URL}/tenent/by-id`;
  		const data  = await fetchWrapper.get(url)
  		setTenent(data.data)
  		setLoading(false)
  }

	return (

		<>
		{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}

				<div className="setting_detail_section">
								<h2 className="main_heading">WhatsApp Settings</h2>
								
								<div className="detail_card">
									<div className="heading_section">			
										<h2>WhatsApp Business Profile</h2>	
									</div>

									<div className="plan_detail_section">
										<div className="d_flex justify_content_between">
											<div className="whatsapp_data_section d_flex" style={{width: '80%'}}>
												<div className="img_box me-10">
													<BsWhatsapp />
												</div>

												<div className="text_section" style={{width: '90%'}}>
													<p>Business Name</p>
													<h5 className="mb-10">{tenent?.settings?.business_name}</h5>

													<p>Business Number</p>
													<p className="mb-10">{tenent?.tenent?.whatsAppNumber}</p>

													<p>Business Bio</p>
													<p>{tenent?.settings?.bio}</p>
												</div>
											</div>

											<div style={{width: '20%',cursor:'pointer'}}>
												<a  onClick={() => getPage(<Profile tenent={tenent} />)} >Edit WhatsApp Business Profile</a>
											</div>
										</div>
									</div>
								</div>

								<div className="detail_card">
									<div className="heading_section">			
										<h2>Opt-in Settings</h2>	
									</div>

									<div className="plan_detail_section">
										<div className="d_flex align_end">
											<div className="text_section me-30" style={{ width: '50%' }}>
												<h5 className="mb-5">Opt-out Keywords</h5>
												<p className="mb-10">The user will have to type exactly one of these messages on which they should be automatically opted-out.</p>

												<InputText />
											</div>

											<div className="text_section" style={{ width: '50%' }}>
												<h5 className="mb-5">Opt-out Response</h5>
												<p className="mb-10">Setup a response message for out-out keywords</p>

												<InputText />
											</div>
										</div>

										<a href="#">Add more</a>

										<hr className="my-15" />

										<div className="d_flex align_end">
											<div className="text_section me-30" style={{ width: '50%' }}>
												<h5 className="mb-5">Opt-In Keywords</h5>
												<p className="mb-10">The user will have to type exactly one of these messages on which they should be automatically opted-in.</p>

												<InputText />
											</div>

											<div className="text_section" style={{ width: '50%' }}>
												<h5 className="mb-5">Opt-In Response</h5>
												<p className="mb-10">Setup a response message for out-in keywords</p>

												<InputText />
											</div>
										</div>

										<a href="#">Add more</a>

									</div>

									<div className="plan_detail_section">
										<div className="text_right">
											<ButtonComponent name="Save" className="primary" />
										</div>
									</div>
								</div>
							</div>


		</>
	)
}


export default Settings;