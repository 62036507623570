import { useEffect,useState,useRef } from 'react';
import * as Yup from 'yup';
import { Button,Text,HStack,Box,Heading,CloseButton,Link,useDisclosure,
Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer


 } from '@chakra-ui/react'
 import { BsArrowLeftSquare, BsTypeItalic, BsTypeBold } from "react-icons/bs";
import {InputText,TextAreaField,SelectField,CustomModal} from '../../../components/FormElement'
import { fetchWrapper } from '../../../helpers';
import ProductOptionsEdit from './ProductOptionsEdit';
import AddVarient from './AddVarient';
import { v4 as uuid } from 'uuid';
import axios from 'axios';


import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
    useFormik,
} from 'formik';


function Edit({getPage,rs}){

  const fileInputRef = useRef();
const [selectedFile, setSelectedFile] = useState();
const [isSelected, setIsSelected] = useState(false)  
const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
const [variationData, setVariationData] = useState([]); 
const [isOpenEdit,setIsOpenEdit] = useState(false)
const [catList, setCatList] = useState([]);
const [varientList, setVarientList] = useState([]);

  useEffect(() => {
        loadCatList()
         loadVarient()
       
        
    }, [isOpenEdit]);
    const loadCatList = async() => {
      const url = `${process.env.REACT_APP_API_URL}/category/list`;
         const data = await fetchWrapper.get(url);
         setCatList(data.data)
    }



    const loadVarient = async() => {
      const url = `${process.env.REACT_APP_API_URL}/product-varient/list-by-product?product_id=${rs.id}`;
         const data = await fetchWrapper.get(url);
         setVarientList(data.data)
    }






 

let initialValues = {
        name: rs.name,
        price:rs.price,
        sale_price:rs.sale_price,
        tax:rs.tax,
        sku:rs.sku,
        barcode:rs.barcode,
        fb_attribute:rs.fb_attribute,
        description:rs.description,
        status_id:rs.status_id,
        category_id:rs.category_id,
        fb_category_id:rs.fb_category_id,
        product_type_id:rs.product_type_id,
     
        tags:rs.tags,
        availability:rs.availability,
        condition:rs.condition,
        id:rs.id
}








 const [viewData, setViewData] = useState({});











	const [formData, setFormData] = useState(initialValues);

    const FormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
       
        description: Yup.string().required('Required'),
        status_id: Yup.string().required('Required'),
        category_id: Yup.string().required('Required')
    });




    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {


          let fd = new FormData();

          
        fd.append('name', values.name);
          fd.append('file', selectedFile);
          fd.append('price', values.price);
          fd.append('sale_price', values.sale_price);
          fd.append('tax', values.tax);
          fd.append('sku', values.sku);
          fd.append('barcode', values.barcode);
          fd.append('fb_attribute', values.fb_attribute);
          fd.append('description', values.description);
          fd.append('status_id', values.status_id);
          fd.append('category_id', values.category_id);
          fd.append('fb_category_id', values.fb_category_id);
          fd.append('product_type_id', values.product_type_id);
          fd.append('availability', values.availability);
          fd.append('condition', values.condition);
          fd.append('tags', values.tags);
          
          fd.append('id', rs.id);
          
         
           
         const url = `${process.env.REACT_APP_API_URL}/product/update`;

         axios
          .post(url, fd)
          .then( 
               getPage('list')
           );

          // let  data = values
          
          
         
            
          
           
         // const url = `${process.env.REACT_APP_API_URL}/product/update`;
         // await fetchWrapper.post(url,data);
         // getPage('list')
         
    }

    const statusList = [{name:'Active',id:1},{name:"In Active",id:0}]
    const categoryFBList = [{name:'Cat 1',id:1},{name:"Cat 2",id:2}]

  



    const addOption = () => {
        setIsOpenEdit(true);
    }

    const meCloseEdit = () => {
      
       setIsOpenEdit(false);
       
  }

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };
const availabilityList = [{name:'Available',id:'AVAILABLE'},{name:'Not Available',id:'NOT_AVAILABLE'}]
    const conditionList = [{name:'EXCELLENT',id:'EXCELLENT'},{name:'Very Good',id:'VERY_GOOD'},{name:'GOOD',id:'GOOD'},{name:'FAIR',id:'FAIR'},{name:'POOR',id:'POOR'}]
	return (

			<>
			
			
      <CustomModal
          showModalButtonText="Add Option"
          modalHeader="Add Option"
          isOpenEdit={isOpenEdit}
          onCloseEdit={meCloseEdit}
          onOpenEdit={onOpenEdit}
          
          modalBody={<AddVarient loadVarient={loadVarient} meCloseEdit={meCloseEdit} product_id={rs.id} cat_id={rs.category_id} />}
    />

      <Formik
            enableReinitialize
                        validationSchema={FormSchema}
                        initialValues={formData}
                        onSubmit={onSubmit}
        >
         {({
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,

          }) => {
             

          return (

            <FormikForm  className="needs-validation" noValidate="">       

              

             <HStack mb={5} justifyContent="space-between">       
              <HStack>
                <Box>
                  <a href="/product"><BsArrowLeftSquare size="24px" /></a>
                </Box>
                <Box ml="15px !important">

                <Heading mb="0px" as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
                  Edit Product
                </Heading>

                </Box>
              </HStack>

              <HStack>
                <Box w='10%' >

                  <Button 
                    type='submit'
                    className="primary"
                  >
                   Save Product
                  </Button>

                </Box>  
              </HStack>
            </HStack>







        <HStack spacing='24px' justifyContent="space-between" w="100%" alignItems="unset">
          <HStack w='75%' flexDirection="column" alignItems="unset">
            

            <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
            
                <InputText name="name" title="Name"  />
                <TextAreaField name="description" title="Description"  />
            </Box>

            <Box m="0px !important" mb="25px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
              <Heading as='h5' size='sm'>Pricing</Heading>
              <br />
              <HStack>
                  <Box w='33%' >
                      <InputText name="price" title="Price"  />
                  </Box>
                  <Box w='33%' >
                      <InputText name="sale_price" title="Sale Price"  />
                  </Box>
                  <Box w='33%' >
                      <InputText name="tax" title="Tax"  />
                  </Box>
              </HStack>
            </Box>

            <Box mb="25px !important" ml="0px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
              
              <Box position="relative" cursor="pointer" textAlign="center" p="20px" borderWidth="1px" borderColor="#878D92" borderStyle="dashed">
                <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Drop to upload images</Text>
                <Link fontSize="12px" color="#2F6DC9">Add Images</Link>
                <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Accept images in png and jpg format</Text>
                <input type="file" 
                      ref={fileInputRef}
                      className="file_input" name="file" onChange={(e) => changeHandler(e)} />
              </Box>
            </Box>

            <Box m="0px !important" mb="25px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
                <Heading as='h5' size='sm'>Inventory</Heading>
                <br />
                <HStack>
                    <Box w='50%' >
                        <InputText name="sku" title="SKU"  />
                    </Box>
                    <Box w='50%' >
                        <InputText name="barcode" title="Barcode"  />
                    </Box>
                    
                </HStack>
                


                
            </Box>

            <Link onClick={() => addOption()}>Add Child</Link>
            <Box m="0px !important" mb="25px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
            
            <TableContainer>
                          <Table>
                            <Thead>
                              <Tr>
                              <Th>Varient</Th>
                                <Th>Price</Th>
                                <Th>Inventory</Th>
                                <Th>SKU</Th>
                                <Th>Barcode</Th>
                                
                                
                              </Tr>
                            </Thead>
                            <Tbody>
            {varientList?.map((varientRs, i) => ( 
              <Tr>
        
                    <Td>{varientRs.varient_id}</Td>
                    <Td>{varientRs.price}</Td>
                    <Td>{varientRs.inventory}</Td>
                    <Td>{varientRs.sku}</Td>
                    <Td>{varientRs.barcode}</Td>
                       
                        
                    
              </Tr>

            ))} 

            </Tbody>
                          </Table>
                      </TableContainer>
            </Box>

            
            
            


            

            
          </HStack>

          <HStack w='35%' flexDirection="column" alignItems='baseline'>
            <Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9" w="100%" mb="10px">
            	<Box p="5" pb="0">
              	<SelectField title="Product Status" list={statusList} name="status_id" />	
              </Box>
              <hr />
              <Box p="5" pt="0px" pb="0" >
                <Text fontSize="12px">Facebook Attributes</Text>
                {/*<InputText name="availiblity_id" title="Availability"  />*/}
                <SelectField title="Availability" list={availabilityList} name="availability" />

                <SelectField title="Condition" list={conditionList} name="condition" />

              </Box>
            </Box>	
            <br />
            <Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9" w="100%" mb="10px !important">
              
              <Box p="5" pb="0">
                <Text fontSize="12px">Product Organization</Text>
                

                <SelectField title="Product Category" list={catList} name="category_id" />

               

              </Box>
            </Box>  

          </HStack>


         

  
        </HStack>                        
      </FormikForm>
           )
          }
        }
        </Formik>
      
			</>
		)
}

export default Edit;