import React,{useEffect,useState} from 'react';
import facebook_catalog_img from '../../assets/images/facebook_catalog.png'
import facebook_integration_img from '../../assets/images/facebook_integration.png'
import user_placeholder from '../../assets/images/user_placeholder.png'
// import Badge from 'react-bootstrap/Badge';
import { Badge } from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
const Connect = ({getPage,fbData}) =>{
	

	const disconnect = async () => {
		const url = `${process.env.REACT_APP_API_URL}/tenent/facebook-diconnect`;
		const data  = await fetchWrapper.get(url)
		getPage('setup')
	}
	return (


			<>

			<div className="facebook_catalog_page">
			<div className="facebook_integration_section">
						<img src={facebook_integration_img} />

						<div className="text_section">
							<h4 className="main_heading">Congratulations your are now Connected to Facebook Catalogs</h4>

							<h6 className="main_inner_heading">Connected Facebook Account</h6>

							<div className="d_flex justify_content_between align_center">
								<div className="user_name_section">
				          			<div className="user_box">
				          				<img src={user_placeholder} />
				          			</div>

				          			<p>{fbData.profile.name}</p>
				          		</div>

				          		<button type="button" className="danger" onClick={() => disconnect()}>Disconnect</button>
							</div>
						</div>
					</div>

					<div className="facebook_integration_section">
						<div className="text_section">
							<h6 className="main_inner_heading">Business Assets</h6>

							
						

								

							<div className="d_flex justify_content_between business_asset_section">
								<div className="text_section">
								
									<h6>Facebook Catalog ID: {fbData.catalog_id}</h6>
									<p>Your products sync to this Facebook catalog. Product changes may take up to 15 minutes to sync to Facebook.</p>
								</div>

								<div className="rigth_section">
									<Badge bg="#ACE8CF" py="5px" px="15px" borderRadius="25px" color="#252627" fontWeight="600" cursor="pointer" textTransform="capitalize">Active</Badge>
									<p>Activate</p>
								</div>
							</div>
							<div className="border_bottom"></div>

							

							
							
						</div>
					</div>
				</div>
				

				
				

				
				
			
		</>
		)
}


export default Connect;