import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../../../helpers';

// create slice

const name = 'tenent';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const tenentActions = { ...slice.actions, ...extraActions };
export const tenentReducer = slice.reducer;


export const updateProfile = (body,id) => {
  const baseUrl = `${process.env.REACT_APP_API_URL}/tenent/${id}`;
  return fetch(baseUrl, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(res => res.json())
}

// implementation

function createInitialState() {
    return {
        tenent: {},
        whatsAppSetting:{}
    }
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/tenent`;

    return {
        getOne: getOne()
    };    

    function getOne() {
        return createAsyncThunk(
            `${name}/getOne`,
            async () => await fetchWrapper.get(baseUrl + '/1')
        );
    }
}

function createExtraReducers() {
    return {
        ...getOne()
    };

    function getOne() {
        var { pending, fulfilled, rejected } = extraActions.getOne;
        return {
            [pending]: (state) => {
                state.tenent = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
                state.tenent = action.payload;
                state.whatsAppSetting = JSON.parse(action.payload.whatsAppSetting)
            },
            [rejected]: (state, action) => {
                state.tenent = { error: action.error };
            }
        };
    }
}