import React,{useEffect,useState} from 'react';
import Header from '../../components/Header'
import Navbar from '../../components/Navbar'
import Account from './Account'
import Setup from './Setup'
import Connect from './Connect'
import Catalog from './Catalog'
import { fetchWrapper } from '../../helpers';
import { Button ,HStack,Spinner } from '@chakra-ui/react'

const Index = () =>{
	const [viewPage, setViewPage] = useState('');
	const [loading,setLoading] = useState(false);

	useEffect(() => {
       

      	loadSetting();
       
        
       
        
    }, []);

    const loadSetting = async () => {
    	setLoading(true)

    	

		const url = `${process.env.REACT_APP_API_URL}/tenent/by-id`;
  		const data  = await fetchWrapper.get(url);
  		 const settingData = JSON.parse(data.data.settings)
  		 //getPage('setup');
  		 if(settingData.facebook){
        	 getPage('connect',settingData.facebook);
    	}else{
    		getPage('setup');
    	}
    	setLoading(false)
  		 
    }

    const getPage = (pageName,fbData) => {
    	if(pageName == 'account'){
	     		setViewPage(<Account getPage={getPage} />)
    	}


    	if(pageName == 'setup'){
	     		setViewPage(<Setup getPage={getPage} />)
    	}


    	if(pageName == 'connect'){
	     		setViewPage(<Connect getPage={getPage} fbData={fbData} />)
    	}


    	if(pageName == 'catalog'){
	     		setViewPage(<Catalog getPage={getPage} fbData={fbData} />)
    	}

    	
	     
	  }

	return (


			<>
			{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="page-section">
					{viewPage}
				</div>
			</div>

			
			
		</>
		)
}


export default Index;