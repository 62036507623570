import React,{useEffect,useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,Image
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button,HStack,Box,Heading,CloseButton,Checkbox ,Spinner} from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import { InputControl } from "formik-chakra-ui";
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react'
import { BsSearch , BsArrowDownUp, BsImages } from "react-icons/bs";


function List({getPage}){


	const [loading,setLoading] = useState(false);

	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
  	setLoading(true)
  		const url = `${process.env.REACT_APP_API_URL}/product-varient/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
		setLoading(false)
  }

	return (

			<>
			{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}
			<div className="detail_card">
					<HStack justifyContent="space-between" mb="25px">
						<Box>
							<Heading as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			 				Inventory
  			  		</Heading>
						</Box>

						<Box>
							<Button
							className="btn_export me-15"
							onClick={() => getPage('create')}
							>Download Import File Format</Button>

							<Button
							type='submit'
              className="primary"
							onClick={() => getPage('create')}
							>Import</Button>
						</Box>
					</HStack>


					<HStack alignItems="unset" flexDirection='column'>
						<Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
							<Tabs variant='soft-rounded' colorScheme='green'>
								<HStack justifyContent="space-between" me="20px">
								  <TabList my="15px" mx="20px">
								    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif'>All</Tab>
								  </TabList>

								  <HStack>
								  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsSearch />} />
								  	<IconButton bg="transparent" border="1px" borderColor="#E0E2E4" borderRadius="3px" aria-label='Search database' icon={<BsArrowDownUp />} />
							  	</HStack>
							  </HStack>

							  <TabPanels>
							    <TabPanel p='0'>
							      <div className="table_section">
											<TableContainer>
											  	<Table>
											  		<Thead>
											  			<Tr>
											  				<Th><Checkbox></Checkbox></Th>
											  				<Th></Th>
											  				<Th>Product Name</Th>
											  				<Th>SKU</Th>
											  				<Th>Committed</Th>
											  				<Th>Available</Th>
											  				<Th>On hand</Th>
											  				
											  			</Tr>
											  		</Thead>
												    <Tbody>
												    {list?.map(rs => {

												    	return (
												      <Tr key={rs.id}>     
												        <Td><Checkbox></Checkbox></Td>
												        <Td>
<Image
																		    boxSize='50px'
																		    objectFit='cover'
																		    src={`${rs.image_url}`}
																		    alt={rs.name}
																		  />
												        </Td>
												        <Td><b>{rs.Product?.name}</b>
												        	<br/>
												        	{rs.varient_name}
												        </Td>
												        <Td>{rs.sku}</Td>
												        <Td>1</Td>
												        <Td>{rs.inventory}</Td>        
												        <Td></Td>
												      </Tr>
												       )
												    }
												    	)}
												    </Tbody>
												  </Table>
											</TableContainer>
										</div>
							    </TabPanel>
							  </TabPanels>
							</Tabs>
							
						</Box>
					</HStack>
					
			</div>

			</>

		)
}
export default List;