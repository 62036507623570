import { useEffect,useState,useRef } from 'react';
import * as Yup from 'yup';
import { Button,HStack,Box,Heading,CloseButton,Link,Text } from '@chakra-ui/react'
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'
import { fetchWrapper } from '../../../helpers';
import ProductOptions from './ProductOptions';
import { v4 as uuid } from 'uuid';
import { FormProvider, useForm } from "react-hook-form";
import FieldArray from "./fieldArray";
import Variants from "./Variants";
import axios from 'axios';
 import { BsArrowLeftSquare, BsTypeItalic, BsTypeBold } from "react-icons/bs";

const defaultValues = {
  options: [
    {
      name: "",
      values: [{ value: "" }]
    }
  ]
};


function Create({getPage}){

  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [catList, setCatList] = useState([]);
const [isSelected, setIsSelected] = useState(false)  
  const formMethods = useForm({
    defaultValues
  });

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    reset,
    setValue
  } = formMethods;

  

  const [optionName, setOptionName] = useState([]); 
  const [optionValue, setOptionValue] = useState([]); 
	const [formData, setFormData] = useState({
        name: '',
        price:'',
        sale_price:'',
        tax:'',
        sku:'',
        barcode:'',
        fb_attribute:'',
        description:'',
        status_id:'',
        availability_id:'',
        availability:'',
        condition:'',
        category_id:'',

      });

    const FormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
       // fb_attribute: Yup.string().required('Required'),
        description: Yup.string().required('Required'),
        status_id: Yup.string().required('Required'),
        availability: Yup.string().required('Required'),
        condition: Yup.string().required('Required'),
        price: Yup.string().required('Required'),
        sale_price: Yup.string().required('Required'),
        sku: Yup.string().required('Required'),
        category_id: Yup.string().required('Required')
    });

     useEffect(() => {
        loadCatList()
     
       
        
    }, []);

    const loadCatList = async() => {
      const url = `${process.env.REACT_APP_API_URL}/category/list`;
         const data = await fetchWrapper.get(url);
         setCatList(data.data)
    }
    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

           let  data = values
          // console.log(optionName)
           //console.log(optionValue)
           
           const optionData = optionName.map( (item, i) => {
               
                const optionKey = 'option_name_' + item;
                const valuesArray = [];
                 optionValue.map((v,ii) => {
                    
                    const valueKey = 'value_' + item + '_' + v;
                    
                    const valueData = values[valueKey];
                      if(valueData){
                        valuesArray.push({id:v,value:valueData})
                      }
                      
                      //return {value:valueData};
                    
                    

                })

                const optionNameData = values[optionKey]
               
                  return {id:item,name:optionNameData,values:valuesArray}
                
               //console.log(valuesArray)
                //console.log(values[optionKey])
           })


           let fd = new FormData();

          
         fd.append('name', values.name);
          fd.append('file', selectedFile);
          fd.append('price', values.price);
          fd.append('sale_price', values.sale_price);
          fd.append('tax', values.tax);
          fd.append('sku', values.sku);
          fd.append('barcode', values.barcode);
          fd.append('fb_attribute', values.fb_attribute);
          fd.append('description', values.description);
          fd.append('status_id', values.status_id);
          fd.append('category_id', values.category_id);
          fd.append('fb_category_id', values.fb_category_id);
          fd.append('product_type_id', values.product_type_id);
          fd.append('tags', values.tags);
           fd.append('availability', values.availability);
            fd.append('condition', values.condition);
          fd.append('options', JSON.stringify(optionData));
          
          
           //data.options = JSON.stringify(optionData)
          const user = localStorage.getItem('user');
        const accessToken = JSON.parse(user)?.accessToken;
          let header = {
            headers: {
              access_token: accessToken,
            }
          }
           
         const url = `${process.env.REACT_APP_API_URL}/product/create`;
         axios
          .post(url, fd,header)
          .then( 
              getPage('list')
           );
        // await fetchWrapper.post(url,data);
        // getPage('list')
         
    }

    const statusList = [{name:'Active',id:1},{name:"In Active",id:0}]
    const categoryFBList = [{name:'New',id:1},{name:"Old",id:2}]
    const availabilityList = [{name:'Available',id:'AVAILABLE'},{name:'Not Available',id:'NOT_AVAILABLE'}]
    const conditionList = [{name:'EXCELLENT',id:'EXCELLENT'},{name:'Very Good',id:'VERY_GOOD'},{name:'GOOD',id:'GOOD'},{name:'FAIR',id:'FAIR'},{name:'POOR',id:'POOR'}]
    
    const addOption = () => {
      const unique_id = uuid();
       const small_id = unique_id.slice(0,8)
        setOptionName([...optionName, small_id])
    }

    const handleOptionValue = (optionValueFromChild) => {
        //console.log(optionValueFromChild)
       
        setOptionValue([...optionValue,optionValueFromChild])
        
    }
    const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

	return (

			<>
			<FormProvider {...formMethods}>
			<Form
                        enableReinitialize
                        validationSchema={FormSchema}
                        initialValues={formData}
                        onSubmit={onSubmit}
             >


             <HStack mb={5} justifyContent="space-between">       
              <HStack>
                <Box>
                  <a href="/product"><BsArrowLeftSquare size="24px" /></a>
                </Box>
                <Box ml="15px !important">


                <Heading mb="0px" as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
                  Add Product
                </Heading>

                </Box>
              </HStack>

              <HStack>
                <Box w='10%' >
                  <Button 
                    type='submit'
                    className="primary"
                  >
                   Save Product
                  </Button>

                </Box>  
              </HStack>
            </HStack>

{/*             <HStack spacing='24px'  alignItems="start">
        				<Box  p="2" w='2' >
        					<CloseButton size='sm' />
        				</Box>
        				<Box  p="2" w='70%' >


        				<Heading as='h3' size='lg'>
        			    Add Product
        			  </Heading>

				      </Box>

      				<Box  p="2" w='10%' textAlign="end" >


                      				<Button
                                  mt={4}
                                  colorScheme='teal'
                                  
                                  type='submit'
                              >
                               Save
                              </Button>



      				</Box>
			
			 </HStack>*/}





        <HStack spacing='24px' justifyContent="space-between" w="100%" alignItems="unset">
          <HStack w='75%' flexDirection="column" alignItems="unset">
            <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
                <InputText name="name" title="Name"  />
                <TextAreaField name="description" title="Description"  />
            </Box>
            
            <Box m="0px !important" mb="25px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
              <Heading as='h5' size='sm'>Pricing</Heading>
              <br />
              <HStack>
                  <Box w='33%' >
                      <InputText name="price" title="Price" placeholder="Rs" />
                  </Box>
                  <Box w='33%' >
                      <InputText name="sale_price" title="Sale Price"  />
                  </Box>
                  <Box w='33%' >
                      <InputText name="tax" title="Tax"  />
                  </Box>
              </HStack>
            </Box>

            <Box mb="25px !important" ml="0px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
              
              <Box position="relative" cursor="pointer" textAlign="center" p="20px" borderWidth="1px" borderColor="#878D92" borderStyle="dashed">
                <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Drop to upload images</Text>
                <Link fontSize="12px" color="#2F6DC9">Add Images</Link>
                <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Accept images in png and jpg format</Text>
                <input type="file" 
                      ref={fileInputRef}
                      className="file_input" name="file" onChange={(e) => changeHandler(e)} />
              </Box>
            </Box>

            <Box m="0px !important" mb="25px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
                <Heading as='h5' size='sm'>Inventory</Heading>
                <br />
                <HStack justifyContent="center" alignItems="baseline">
                    <Box w='50%' className="set_text">
                        <InputText name="sku" title="SKU"  />
                        <Text mb="0px" fontSize="12px" color="#C9C9C9">Content ID used in Facebook Catalog</Text>
                    </Box>
                    <Box w='50%' >
                        <InputText name="barcode" title="Barcode"  />
                    </Box>
                    
                </HStack>
            </Box>

            {optionName.map((item, i) => ( 
        <>
                    
                    <Box m="0px !important" mb="25px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
                     <Heading as='h5' size='sm'>Varient</Heading>
                        {item}
                        <ProductOptions text={item} handleOptionValue={handleOptionValue} />
                    </Box>
              </>

            ))} 


            
            
            <Link onClick={() => addOption()}>Add Option</Link>
          </HStack>

          <HStack w='35%' flexDirection="column" alignItems='baseline'>
            <Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9" w="100%" mb="10px">
            	<Box p="5" pb="0">
              	<SelectField title="Product Status" list={statusList} name="status_id" />	
              </Box>
              <hr />
              <Box p="5" pt="0px" pb="0" >
              	<Text fontSize="12px">Facebook Attributes</Text>
              	{/*<InputText name="availiblity_id" title="Availability"  />*/}
                <SelectField title="Availability" list={availabilityList} name="availability" />

              	<SelectField title="Condition" list={conditionList} name="condition" />

              </Box>
            </Box>

            <Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9" w="100%" mb="10px !important">
              
              <Box p="5" pb="5" >
                <Text fontSize="12px">Product Organization</Text>
               
                <SelectField title="Product Category" list={catList} name="category_id" />

               

              </Box>
            </Box>

             <Box bg="white" style={{display:'none'}} borderRadius='5' border="1px" borderColor="#E8E8E9" w="100%">
              
              <Box p="5" pb="5">
                <Text fontSize="12px">Product Attributes</Text>
               
                <SelectField title="Attribute 1" list={availabilityList} name="attribute_id" />
                <SelectField title="Attribute 2" list={availabilityList} name="attribute_id" />
                <SelectField title="Attribute 3" list={availabilityList} name="attribute_id" />
                <SelectField title="Attribute 4" list={availabilityList} name="attribute_id" />

              </Box>
            </Box>

          </HStack>
  
        </HStack>

        <hr/>

        


      </Form>
      </FormProvider>
			</>
		)
}

export default Create;