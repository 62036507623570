import React,{useEffect,useState} from 'react';
import { fetchWrapper } from '../../helpers';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { FaArrowLeft } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';
import user_placeholder from '../../assets/images/user_placeholder.png'

const Account = ({getPage}) =>{

 const [phoneNumber, setPhoneNumber] = useState("")
 const [wabaId, setWabaId] = useState("")  
	window.fbAsyncInit = function () {
    // JavaScript SDK configuration and setup
    window.FB.init({
      appId:    '1490617791519432', // Facebook App ID
      cookie:   true, // enable cookies
      xfbml:    true, // parse social plugins on this page
      version:  'v19.0' //Graph API version
    });
  };

  // Load the JavaScript SDK asynchronously
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  // Facebook Login with JavaScript SDK
  function launchWhatsAppSignup() {
    // Conversion tracking code
    window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: '1490617791519432', feature: 'whatsapp_embedded_signup'});
    
    // Launch Facebook login
    window.FB.login(function (response) {
      if (response.authResponse) {

      	
        const code = response.authResponse.code;

      
        saveSetting(code)
       
        // The returned code must be transmitted to your backend, 
  // which will perform a server-to-server call from there to our servers for an access token
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, {
      config_id: '907651121370585', // configuration ID goes here
      response_type: 'code',    // must be set to 'code' for System User access token
      override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
	  	extras: {
    		"sessionInfoVersion": 2,  //  Receive Session Logging Info
  		}

	  //  extras: {
	  //   featureType: "only_waba_sharing" // Bypass phone number selection
	  // }
    });
  }


  const sessionInfoListener = (event) => {
  if (event.origin !== "https://www.facebook.com") return;
  try {
    const data = JSON.parse(event.data);
    if (data.type === 'WA_EMBEDDED_SIGNUP') {
      // if user finishes the Embedded Signup flow
      if (data.event === 'FINISH') {
        const {phone_number_id, waba_id} = data.data;
        
        const postData = {phone_number_id:phone_number_id,waba_id:waba_id}
        console.log(postData)

        sentWaba(postData)
       
        //console.log(waba_id + " " + phone_number_id)
      }
      // if user cancels the Embedded Signup flow
      else {
       const{current_step} = data.data;
      }
    }
  } catch {
    // Don’t parse info that’s not a JSON
    console.log('Non JSON Response', event.data);
  }
};

const sentWaba = async(postData) => {
	const url = `${process.env.REACT_APP_API_URL}/tenent/save-waba`;
  			const data  = await fetchWrapper.post(url,postData)
}
window.addEventListener('message', sessionInfoListener); 

const saveSetting = async (code) => {

		const postData = {setting_facebook:code,waba_id:wabaId};
		
		const url = `${process.env.REACT_APP_API_URL}/tenent/facebook`;
  		const data  = await fetchWrapper.post(url,postData)
  		
  		getPage('connect',data.data)


  		//getPage('catalog',data.data)
  		
	}

const saveSettingOld = async (response) => {

		const postData = {setting_facebook:response};

		const url = `${process.env.REACT_APP_API_URL}/tenent/facebook`;
  		const data  = await fetchWrapper.post(url,postData)
  		
  		getPage('catalog',data.data)
  		
	}
	return (


			<>
				<div className="facebook_catalog_page">
					<div className="setup_catalog_section">
						<div className="head_section">
							<div className="icon_box">
								<FaArrowLeft />
							</div>


							<div className="text_section">
								<h6>Set up your catalog</h6>

								<p>Complete setup to sync your products to Facebook Catalog & WhatsApp</p>
							</div>
						</div>

						<Accordion defaultActiveKey="0">
					      <Accordion.Item eventKey="0">
					        <Accordion.Header>Connect your Facebook Account</Accordion.Header>
					        <Accordion.Body>
					          <div className="accordion_body_section">
					          	<p>Lorem ipsum dolor sit amet consectetur. Volutpat lacus in urna non arcu mauris odio. Porttitor leo vehicula amet mauris malesuada eu. Eu at nec fringilla ut id amet.</p>

					          	<div className="d_flex justify_content_between align_center">
					          		<div className="user_name_section">
					          			<div className="user_box">
					          				<img src={user_placeholder} />
					          			</div>

					          			<p>Connect your Facebook account to get started.</p>
					          		</div>

					          		

				<FacebookLogin
					  appId="1490617791519432"
					  initParams={{
					      version: 'v19.0',
					      cookie:true,
					      xfbml:true,
					      
					    }}

					    style={{
					    	display:'none',
      backgroundColor: '#047E60',
      color: '#fff',
      fontSize: '16px',
      padding: '12px 24px',
      border: 'none',
      borderRadius: '4px',
    }}
					  
					  onSuccess={(response) => {
					     saveSetting(response)
					  }}
					  onFail={(error) => {
					    console.log('Login Failed!', error);
					  }}
					  onProfileSuccess={(response) => {
					    console.log('Get Profile Success!', response);
					  }}

					   loginOptions={{
					    return_scopes: true,
					    config_id:"907651121370585"
					  }}
				/>

					          		

					        <button

					        	style={{
					    	
      backgroundColor: '#047E60',
      color: '#fff',
      fontSize: '16px',
      padding: '12px 24px',
      border: 'none',
      borderRadius: '4px',
    }}
					         onClick={ () => launchWhatsAppSignup()} >Login with Facebook</button>

					          	</div>
					          </div>
					        </Accordion.Body>
					      </Accordion.Item>

					      <Accordion.Item eventKey="1">
					        <Accordion.Header>Business assets</Accordion.Header>
					        <Accordion.Body>
					          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
					          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
					          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
					          aliquip ex ea commodo consequat. Duis aute irure dolor in
					          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
					          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
					          culpa qui officia deserunt mollit anim id est laborum.
					        </Accordion.Body>
					      </Accordion.Item>
					    </Accordion>
					</div>
				</div>

		</>
		)
}


export default Account;