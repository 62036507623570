import React,{useEffect,useState} from 'react';
import facebook_catalog_img from '../../assets/images/facebook_catalog.png'
import facebook_integration_img from '../../assets/images/facebook_integration.png'
import user_placeholder from '../../assets/images/user_placeholder.png'
import Badge from 'react-bootstrap/Badge';
import { fetchWrapper } from '../../helpers';
const Catalog = ({getPage,fbData}) =>{
	
	const [selectedOption, setSelectedOption] = useState("")  

	 function onValueChange(event){
        // Updating the state with the selected radio button's value
        setSelectedOption(event.target.value)
    }
    const complete = async() => {

    	if(selectedOption == ""){
    		alert("Please, select catalog");
    		return false;
    	}
    	
    	const postData = {catalog_id:selectedOption};

		const url = `${process.env.REACT_APP_API_URL}/facebook/connect-catalog`;
  		const data  = await fetchWrapper.post(url,postData);
  		
  		console.log(data.data)
  		getPage('connect',data.data)
    }
	return (


			<>

			<div className="facebook_catalog_page">
			<div className="facebook_integration_section">
						<img src={facebook_integration_img} />

						
					</div>

					<div className="facebook_integration_section">
						<div className="text_section">
							<h6 className="main_inner_heading">Business Assets</h6>



							{fbData?.business?.map
								(item => {
										return(
											<>
												<div><b>{item.business_name}</b></div>

												
													{
														item?.catalog?.map(cItem => {

																return (

																	<>
																		<div className="d_flex justify_content_between business_asset_section">

																			<div className="text_section">
																				<h6>Facebook Catalog ID: {cItem.id}</h6>
																				<p>Your products sync to this Facebook catalog. Product changes may take up to 15 minutes to sync to Facebook.</p>
																			</div>
																			<div>
																				
																			<input name="catalog" 
																			onChange={onValueChange}
																			value={cItem.id} type="radio" />
																			</div>
																		</div>
																		<div className="border_bottom"></div>
																	</>

																	)

														})	
													}

											</>
												
										)
								})

							}

							
							
							
							
						</div>
					</div>
				</div>
				

				<div>
					<button type="button" className="btn primary" onClick={() => complete()}>Complete</button>
				</div>
				

				
				
			
		</>
		)
}


export default Catalog;