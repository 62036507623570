import React,{useEffect,useState} from 'react';
import { BsThreeDots } from "react-icons/bs";
import ButtonComponent from '../../components/ButtonComponent'
import Mastercard from '../../assets/images/mastercard.png'

import {
  Box,
  HStack,
  
  Spinner
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux';
import { userTypeActions } from './slice/user.type.slice.js';
import { fetchWrapper } from '../../helpers';
import List from './user/List'
import Create from './user/Create';
import CustomModalLink from '../../components/FormElement/CustomModalLink'
function UserPermission(){
	const [loading,setLoading] = useState(false);

	const [show,setShow] = useState(false);


	const [list,setList] = useState([])

	useEffect(() => {
        loadList() 
    }, []);

	  const loadList = async () => {
	  		setLoading(true)
	      const url = `${process.env.REACT_APP_API_URL}/user-type/list`;
	      const data  = await fetchWrapper.get(url);
	      
	      setList(data.data);
	  
	      setLoading(false);
	  }

	  const ToggleShow = ()=>{
	  	setShow(!show)
	  }

	return(
		<>
		{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}
			<div className="setting_detail_section">
				<h2 className="main_heading">User & Permissions</h2>

				<div className="text_section mt-20">
					<h4>Permissions</h4>
					<p>Manage what users can see or do in your store.</p>
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Store Owner</h2>
					</div>

					<div className="plan_detail_section py-0">
						<div className="user_permission_section">
							<div className="user_detail_section d_flex align_center">
								<div className="user_box me-10">
									<h5>DA</h5>
								</div>

								<div className="text_section">
									<a href="#">Dilawar Abbas</a>
									<p>Last login was Friday, March 17, 2023 05:30 pm</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				
				
				{list?.map((rs, index) => {
			        return (
			          <>
			          	<div className="detail_card">

					          	<div className="heading_section">
									<div className="d_flex justify_content_between align_center">
										<h2>
										
										{rs.name} ({rs?.Users?.length})</h2>

										<CustomModalLink
									        showModalButtonText={`Add ${rs.name}`}
									        modalHeader="Create User"
									        modalBody=<Create user_type_id={rs.id} loadList={loadList} toggle={ToggleShow}/>
									      />
									</div>
								</div>
						
								<List user_type_id={rs.id} show={show} />
						</div>
			          </>
			        );
			      })}

				
					

					
				

				
			</div>
		</>
	);
}

export default UserPermission;