import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../../components/ButtonComponent'
import SwitchComponent from '../../../components/SwitchComponent'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { BsCheckCircleFill, BsTrash } from "react-icons/bs";
import { fetchWrapper } from '../../../helpers';


const List = ({rs,type})=>{
	const headername = type.name
	const [list,setList]=useState([])

	useEffect(() => {
        loadList(rs.id) 
    }, []);

	  const loadList = async (rs) => {	  	
	  	
	      const url = `${process.env.REACT_APP_API_URL}/notification/list?notification_type_id=${rs}`;
	      const data  = await fetchWrapper.get(url);      
	      setList(data.rows);
	  
	     
	  }
	return(
		<>
			<div className="heading_section">			
				<h2>{headername}</h2>	
			</div>

			<div className="plan_detail_section pt-5">
				<div className="table_section">
					<TableContainer>
					  	<Table>
						    <Tbody>
						    	{list.filter(nt => nt.content === headername).map((rs,index)=>{
						    		return(
						    			<>
						    				<Tr>
										        <Td><BsCheckCircleFill className="color_primary" /></Td>
										        <Td><a href="#">{rs.name}</a></Td>
										        <Td className="small-cell">{rs.description}</Td>
										        <Td></Td>   
										        <Td><SwitchComponent colorScheme="green" /></Td>
										    </Tr>
						    			</>
						    		)
						    	})}
						     
						          
						    </Tbody>
						  </Table>
					</TableContainer>
				</div>
			</div>
		</>
	)
}

export default List
