import React,{useEffect,useState} from 'react';
import { Button ,HStack,Box ,IconButton} from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,TextAreaField,SelectField} from '../../../components/FormElement'
import { toast } from "react-toastify";

import { fetchWrapper } from '../../../helpers';
import { Formik, Form, Field, FieldArray } from 'formik';
import { BsTrash } from "react-icons/bs";


function CreateAttribute({loadList,rs,onClose}){


	
	const [formData, setFormData] = useState({
    name: '',
    id:rs.id
  });

  const FormSchema = Yup.object().shape({
	    
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = {values:JSON.stringify(values.attriubteValue),id:rs.id}
		   
	       
	     const url = `${process.env.REACT_APP_API_URL}/attribute/add-value`;
	     
  		 await fetchWrapper.post(url,data)
  		 loadList();
       onClose();
       toast(`Record has been Saved`,{
        type:'success'
       })

  		
	}

	

	const addRow = () => {

		
	}

	let defaultValue = [];
	if(rs.values != ""){
		defaultValue = JSON.parse(rs.values)
	}

	return (

			<div>
     
     <Formik
       initialValues={{ attriubteValue: defaultValue }}
       onSubmit={onSubmit}
       render={({ values }) => (
         <Form>
           <FieldArray
             name="attriubteValue"
             render={arrayHelpers => (
               <Box>
                 {values.attriubteValue && values.attriubteValue.length > 0 ? (
                   values.attriubteValue.map((friend, index) => (
                     

                     <HStack w="100%" alignItems="baseline">
                      <Box w="90%">
                          <InputText name={`attriubteValue.${index}`} />
                      </Box>
                      <Box w="10%"  onClick={() => arrayHelpers.remove(index)} >
                          <IconButton bg="transparent" border="1px dashed" borderColor="#E0E2E4" borderRadius="3px" color="#878D92" aria-label='Search database' icon={<BsTrash />} />
                      </Box>
                     </HStack>
                   ))
                 ) : null }


                  <button className="simple" type="button" onClick={() => arrayHelpers.push('')}>
                     
                     Add
                   </button>

                   <hr />


                   <HStack justifyContent="end" mt="5px" mb="10px">
                          <Box></Box>
                          <Box>
                              <Button  colorScheme='teal' type='submit'>
                               Save
                              </Button>
                          </Box> 
                   </HStack>
                   
                
               </Box>
             )}


             
           />
         </Form>
       )}
     />
   </div>

	)
}

export default CreateAttribute;