import { Routes, Route, Navigate, useNavigate, useLocation,Outlet } from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";
import { history } from './helpers';
import { PrivateRoute } from './components';
import Dashboard  from './pages/Dashboard';
import Settings  from './pages/Settings';
import Login  from './pages/Auth/SignIn';

import ChatBot  from './pages/Chat';

import Plan  from './pages/settings/Plan';
import StoreDetail from './pages/settings/StoreDetail'
import Billing from './pages/settings/Billing'
import UserPermission from './pages/settings/UserPermission'
import WhatsappSetting from './pages/settings/WhatsappSetting'
import WhatsappBusinessProfile from './pages/settings/WhatsappBusinessProfile'
import WhatsappMarketingTemplates from './pages/settings/WhatsappMarketingTemplates'
import PaymentSetting from './pages/settings/PaymentSetting'
import StripeSetting from './pages/settings/StripeSetting'
import CheckoutSetting from './pages/settings/CheckoutSetting'
import ProductSetting from './pages/settings/ProductSetting'
import ShippingSetting from './pages/settings/ShippingSetting'
import NotificationSetting from './pages/settings/NotificationSetting'
import TemplateDetail from './pages/settings/TemplateDetail'
import PoliciesSetting from './pages/settings/PoliciesSetting'

import FbSetup from './pages/facebook/Index'

import SignUp from  './pages/Auth/SignUp';

// Product
import Customer from  './pages/customer/Customer';
import Category from  './pages/product/Category';
import Product from  './pages/product/Product';
import Inventory from  './pages/product/Inventory';
import Orders from  './pages/orders/Orders';
import Detail from  './pages/orders//Detail';
import Chat from  './pages/chat/Chat';
import Campaign from  './pages/campaign/Campaign';
import Header from './components/Header'
import Navbar from './components/Navbar'
import './App.css';
export default App ;

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();


    
  window.fbAsyncInit = function() {
    window.FB.init({
      appId      : '1490617791519432',
      xfbml      : true,
      version    : 'v19.0'
    });
    window.FB.AppEvents.logPageView();
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));

    return (
        <>
            
            <ChakraProvider>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                                
                            </PrivateRoute>
                        }
                    />


                    <Route
                        path="/chat-bot"
                        element={
                            <PrivateRoute>
                                <ChatBot />
                                
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/orders"
                        element={
                            <PrivateRoute>
                                <Orders />
                                
                            </PrivateRoute>
                        }
                    />

                    <Route
                            path="/order-detail/:id"
                            element={
                                <PrivateRoute>
                                    <Detail />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>

                    <Route
                        path="/inventory"
                        element={
                            <PrivateRoute>
                                <Inventory />
                                
                            </PrivateRoute>
                        }
                    />


                    <Route
                        path="/category"
                        element={
                            <PrivateRoute>
                                <Category />
                                
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/product"
                        element={
                            <PrivateRoute>
                                <Product />
                                
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/facebook/setup"
                        element={
                            <PrivateRoute>
                                <FbSetup />
                                
                            </PrivateRoute>
                        }
                    />

                    
                    <Route
                        path="/customer"
                        element={
                            <PrivateRoute>
                                <Customer />
                                
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/chat"
                        element={
                            <PrivateRoute>
                                <Chat />
                                
                            </PrivateRoute>
                        }
                    />

                    <Route
                        path="/campaign"
                        element={
                            <PrivateRoute>
                                <Campaign />
                                
                            </PrivateRoute>
                        }
                    />
                    

                    <Route
                        path="/setting"
                        element={
                            <PrivateRoute>
                                <Settings />
                                
                            </PrivateRoute>
                        }
                    >

                        <Route
                            path="/setting/plan"
                            element={
                                <PrivateRoute>
                                    <Plan />
                                    
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/setting"
                            element={
                                <PrivateRoute>
                                    <StoreDetail />
                                    
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/setting/billing"
                            element={
                                <PrivateRoute>
                                    <Billing />
                                    
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/setting/user-permission"
                            element={
                                <PrivateRoute>
                                    <UserPermission />
                                    
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/setting/whatsapp-setting"
                            element={
                                <PrivateRoute>
                                    <WhatsappSetting />
                                    
                                </PrivateRoute>
                            }
                        >
         

                        </Route>

                        <Route
                            path="/setting/whatsapp-marketing-template"
                            element={
                                <PrivateRoute>
                                    <WhatsappMarketingTemplates />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>

                        <Route
                            path="/setting/payment-setting"
                            element={
                                <PrivateRoute>
                                    <PaymentSetting />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>

                        <Route
                            path="/setting/policies"
                            element={
                                <PrivateRoute>
                                    <PoliciesSetting />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>

                        <Route
                            path="/setting/shipping"
                            element={
                                <PrivateRoute>
                                    <ShippingSetting />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>
                        

                        <Route
                            path="/setting/template-detail/:id"
                            element={
                                <PrivateRoute>
                                    <TemplateDetail />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>


                       <Route
                                    path="/setting/product"
                                    element={
                                        <PrivateRoute>
                                            <ProductSetting />
                                            
                                        </PrivateRoute>
                                    }
                                >
                        </Route> 


                        <Route
                                    path="/setting/checkout-setting"
                                    element={
                                        <PrivateRoute>
                                            <CheckoutSetting />
                                            
                                        </PrivateRoute>
                                    }
                                > 
                        </Route>

                        <Route
                            path="/setting/notification"
                            element={
                                <PrivateRoute>
                                    <NotificationSetting />
                                    
                                </PrivateRoute>
                            }
                        >

                        </Route>
                       
                    </Route>

                    
                    

                    <Route path="/login" element={<Login />} />
                     <Route path="/signup" element={<SignUp />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                
                    

            </ChakraProvider>
        </>
    );
}