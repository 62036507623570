import React from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import ButtonComponent from '../../components/ButtonComponent'
import Mastercard from '../../assets/images/mastercard.png'
import Index from './plan/Index'
function Plan(){
	return(
		<>
			<Index/>
			
		</>
	);
}

export default Plan;