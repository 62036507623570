import { useEffect,useState } from 'react';
import { Button,HStack,Box,Heading,Link} from '@chakra-ui/react'
import {InputText} from '../../../components/FormElement'
import { v4 as uuid } from 'uuid';
function ProductOptionsEdit({varientRs}){

	

	return(
		<>
		<HStack>
                    <Box w='30%' >
                        {varientRs.varient_id}
                    </Box>
                    <Box w='10%' >
                        {varientRs.price}
                    </Box>

                    <Box w='20%' >
                        {varientRs.inventory}
                    </Box>
                    <Box w='20%' >
                        {varientRs.sku}
                    </Box>

                    <Box w='20%' >
                        {varientRs.barcode}
                    </Box>

                          
                          
                  </HStack>
		</>
	)
}

export default ProductOptionsEdit;