import React from 'react';
import ButtonComponent from '../../components/ButtonComponent'
import InputText from '../../components/InputText'

function ShippingSetting(){
	return(
		<>
			<div className="setting_detail_section">
				<h2 className="main_heading">Shipping</h2>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Delivery Partners</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="method_section d_flex justify_content_between">
							<div className="text_section">
								<h4>Aftership</h4>
								<p>1000+ carrier partners across the globe.</p>
							</div>

							<ButtonComponent name="Activate" className="primary" />	
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ShippingSetting;