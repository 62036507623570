import React,{useEffect,useState} from 'react';
import List from './List';
import { useSelector } from 'react-redux';
import ChangePlan from './Changeplan';
import { fetchWrapper } from '../../../helpers';
const Index = () =>{
	const { user: authUser } = useSelector(x => x.auth);
	// console.log(authUser)

	const [viewPage, setViewPage] = useState('');

	
	useEffect(() => {
		loadUserList(authUser.id)    
        
    }, []);
     const loadUserList = async (user_id) => {   	
		const url = `${process.env.REACT_APP_API_URL}/user/${user_id}`;
  		const data  = await fetchWrapper.get(url);
  		// console.log(data)
  		const plan_id = data?.data?.Tenent?.plan_id;

  		if(plan_id){
			getPage('List',authUser)	
		}else{
			getPage('create' ,authUser)
		}     
  		
  		
    }


    const getPage = (pageName,authUser,rs) => {
    	
    	if(pageName == 'List'){
	     		setViewPage(<List getPage={getPage} authUser={authUser} rs={rs}/>)
    	}


    	if(pageName == 'create'){
	     		setViewPage(<ChangePlan getPage={getPage} authUser={authUser} rs={rs} />)
    	}


    	// if(pageName == 'connect'){
	    //  		setViewPage(<Connect getPage={getPage} fbData={fbData} />)
    	// }


    	// if(pageName == 'catalog'){
	    //  		setViewPage(<Catalog getPage={getPage} fbData={fbData} />)
    	// }

    	
	     
	  }

	return (


			<>

					{viewPage}
			

			
			
		</>
		)
}


export default Index;