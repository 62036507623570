import React,{useEffect,useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button,HStack,Box,Heading,CloseButton,Checkbox,Badge,Text,Image } from '@chakra-ui/react'
import { fetchWrapper } from '../../helpers';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { IconButton } from '@chakra-ui/react'
import { BsSearch , BsArrowDownUp, BsImages } from "react-icons/bs";
import profileimg from '../../assets/images/profile_img.png';
import { Input, InputRightElement, InputGroup, Textarea } from '@chakra-ui/react';


function List({getPage}){



	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/category/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  }

	return (

			<>
			<div className="detail_card">
					<HStack justifyContent="space-between" mb="25px">
						<Box>
							<Heading as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			 				Chat
  			  		</Heading>
						</Box>
					</HStack>

					<HStack>
						<Box bg="white" w="25%" border="1px" borderColor="#E8E8E9" borderRadius="5px" height="100vh">
							<HStack justifyContent="space-between" py="10px" px="15px" borderBottom="1px solid" borderColor="#E8E8E9">
								<HStack>	
									<Box me="10px !important">
										<Image src={profileimg} w="40px" h="40px" />
									</Box>

									<Box ml="0px !important">
										<Text fontSize="12px" lineHeight="15px" fontFamily='Open Sans, sans-serif' color="#252627" fontWeight="400">Agent Name</Text>
										<Text fontSize="10px" lineHeight="13px" fontFamily='Open Sans, sans-serif' color="#047D60" fontWeight="400">Available</Text>
									</Box>
								</HStack>

								<HStack>
									<Box>

									</Box>
								</HStack>
							</HStack>

							<HStack py="10px" px="15px" borderBottom="1px solid" borderColor="#E8E8E9">
								<InputGroup bg="#F5F5F6" borderColor="#E8E8E9">
							    <Input py="10px" height="auto" color="#000" fontSize="10px" placeholder='Search' />
							    <InputRightElement height="unset" py="10px">
							      <BsSearch color="#E8E8E9" />
							    </InputRightElement>
							  </InputGroup>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>

							<HStack justifyContent="space-between" py="7px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<HStack>
									<Box w="24px" h="24px">
										<Image src={profileimg} />
									</Box>

									<Box>
										<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
									</Box>
								</HStack>

								<HStack>
									<Text fontSize="8px" lineHeight="10px" color="#5C5F62" fontFamily='Open Sans, sans-serif'>10 mins ago</Text>
								</HStack>
							</HStack>
						</Box>

						<Box bg="white" w="50%" border="1px" borderColor="#E8E8E9" borderRadius="5px" height="100vh" display="flex" flexDirection="column" justifyContent="space-between">
							<HStack py="10px" px="15px" borderBottom="1px" borderColor="#E8E8E9">
								<Box w="24px" h="24px">
									<Image src={profileimg} />
								</Box>

								<Box>
									<Text fontSize="12px" lineHeight="15px" color="#252627" fontFamily='Open Sans, sans-serif'>+92 323 2986614</Text>
								</Box>
							</HStack>

							<HStack>
								<Box width="100%" border="1px" borderColor="#E8E8E9" my="10px" mx="10px" borderRadius="5px">
									<Textarea placeholder='Enter Message text' border="0px" />

									<Box borderTop="1px" borderColor="#E8E8E9" py="10px" px="15px" textAlign="right">
										<Button
			             	className="primarysmall"

										>Send</Button>
									</Box>
								</Box>
							</HStack>
						</Box>

						<Box bg="white" w="25%" border="1px" borderColor="#E8E8E9" borderRadius="5px" height="100vh">

						</Box>
					</HStack>					
			</div>

			</>

		)
}
export default List;