import React,{useState} from 'react';
import { BsFillGrid1X2Fill, BsInboxFill, BsFillTagFill, BsPersonFill, BsFillChatLeftFill, BsFillChatSquareQuoteFill } from "react-icons/bs";
import Dropdown from 'react-bootstrap/Dropdown';
import dashboard_icon from '../assets/images/main_nav_icon/dashboard_icon.svg'
import dashboard_icon_active from '../assets/images/main_nav_icon/dashboard_icon_active.svg'
import order_icon from '../assets/images/main_nav_icon/order_icon.svg'
import order_icon_active from '../assets/images/main_nav_icon/order_icon_active.svg'
import product_icon from '../assets/images/main_nav_icon/product_icon.svg'
import product_icon_active from '../assets/images/main_nav_icon/product_icon_active.svg'
import customer_icon from '../assets/images/main_nav_icon/customer_icon.svg'
import customer_icon_active from '../assets/images/main_nav_icon/customer_icon_active.svg'
import chat_icon from '../assets/images/main_nav_icon/chat_icon.svg'
import chat_icon_active from '../assets/images/main_nav_icon/chat_icon_active.svg'
import campaign_icon from '../assets/images/main_nav_icon/campaign_icon.svg'
import campaign_icon_active from '../assets/images/main_nav_icon/campaign_icon_active.svg'
import bot_icon from '../assets/images/main_nav_icon/bot_icon.svg'
import facebook_icon from '../assets/images/main_nav_icon/facebook_icon.png'
import setting_icon from '../assets/images/main_nav_icon/setting_icon.svg'
import facebook_icon_active from '../assets/images/main_nav_icon/facebook_icon_active.svg'
import { Routes, Route, Link,useLocation } from 'react-router-dom';
function Navbar() {
	const location = useLocation();
    const [activeItem, setActiveItem] = useState(location.pathname);

    const handleItemClick = (url) => {
        setActiveItem(url);
    };

	return(
		<>
			<div className="navbar_section">
				<ul>
					<li>
						<a href="/" onClick={() => handleItemClick("/")} className={activeItem === "/" ? "active" : ""}>
							<div className="img_box">
								<img src={dashboard_icon} className="icon_img" />
								<img src={dashboard_icon_active} className="icon_img_active" />
							</div>
							<p>Dashboard</p>
						</a>
					</li>

					<li>
						<a href="/orders" onClick={() => handleItemClick("/orders")} className={activeItem === "/orders" ? "active" : ""}>
							<div className="img_box">
								<img src={order_icon} className="icon_img" />
								<img src={order_icon_active} className="icon_img_active" />
							</div>
							<p>Orders</p>
						</a>

						<div className="sub_menu">
							<ul>
								<li>
									<a href="#"><p>Abandoned Carts</p></a>
								</li>
							</ul>	
						</div>
					</li>

					<li>
						<a href="/product" onClick={() => handleItemClick("/product")} className={activeItem === "/product" ? "active" : ""}>
							<div className="img_box">
								<img src={product_icon} className="icon_img" />
								<img src={product_icon_active} className="icon_img_active" />
							</div>
							<p>Products</p>
						</a>

						<div className="sub_menu">
							<ul>
								<li>
									<a href="/category" onClick={() => handleItemClick("/category")} className={activeItem === "/category" ? "active" : ""}><p>Categories</p></a>
								</li>

								<li>
									<a href="/inventory" onClick={() => handleItemClick("/inventory")} className={activeItem === "/inventory" ? "active" : ""}><p>Inventory</p></a>
								</li>
							</ul>
						</div>
					</li>

					<li>
						<a href="/customer" onClick={() => handleItemClick("/customer")} className={activeItem === "/customer" ? "active" : ""}>
							<div className="img_box">
								<img src={customer_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Customers</p>
						</a>
					</li>

					<li>
						<a href="/chat" onClick={() => handleItemClick("/chat")} className={activeItem === "/chat" ? "active" : ""}>
							<div className="img_box">
								<img src={chat_icon} className="icon_img" />
								<img src={chat_icon_active} className="icon_img_active" />
							</div>
							<p>Chat</p>
						</a>
					</li>

					<li>
						<a href="/campaign" onClick={() => handleItemClick("/campaign")} className={activeItem === "/campaign" ? "active" : ""}>
							<div className="img_box">
								<img src={campaign_icon} className="icon_img" />
								<img src={campaign_icon_active} className="icon_img_active" />
							</div>
							<p>Campaigns</p>
						</a>
					</li>

					<li>
						<a href="#">
							<div className="img_box">
								<img src={bot_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Bot</p>
						</a>
					</li>

					<li>
						<a href="/facebook/setup" onClick={() => handleItemClick("/facebook/setup")} className={activeItem === "/facebook/setup" ? "active" : ""}>
							<div className="img_box">
								<img src={facebook_icon} className="icon_img" />
								<img src={facebook_icon_active} className="icon_img_active" />
							</div>
							<p>Facebook Catalog</p>
						</a>
					</li>

					<li>
						<a href="/setting" onClick={() => handleItemClick("/setting")} className={activeItem === "/setting" ? "active" : ""}>
							<div className="img_box">
								<img src={setting_icon} className="icon_img" />
								<img src={customer_icon_active} className="icon_img_active" />
							</div>
							<p>Settings</p>
						</a>
					</li>
				</ul>
			</div>
			
			
			
		</>
	);
}

export default Navbar;