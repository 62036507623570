import React,{useState,useEffect} from 'react';
import { BsWhatsapp, BsArrowLeftSquare } from "react-icons/bs";
import ButtonComponent from '../../components/ButtonComponent'


import { Button } from '@chakra-ui/react'
import { Formik,Field,ErrorMessage } from 'formik';

import {InputText,TextAreaField,Form,SelectField} from '../../components/FormElement'
import * as Yup from 'yup';

import { BsCurrencyDollar, BsFillCaretDownFill, BsTriangle, BsFillPersonFill } from "react-icons/bs";


import { useSelector, useDispatch } from 'react-redux';
import { categoryActions } from './slice/category.slice.js';
import { updateProfile } from './slice/tenent.slice.js';

function WhatsappBusinessProfile(){
	const dispatch = useDispatch();

	useEffect(() => {
        dispatch(categoryActions.getAll());
        
        
    }, []);

	const  categoryList  = useSelector((state) => state.category.listing)

	const [formData, setFormData] = useState({
    bio: "",
    business_name:"",
    category_id: "",
    address: "",
    email: "",
    website: "",
 
   
    
    
  });

const FormSchema = Yup.object().shape({
    bio: Yup.string()
          .required('Required'),

    business_name: Yup.string()
          .required('Required'),


     category_id: Yup.string()
          .required('Required'),
   
          
    address: Yup.string()
        .required('Required'),
        


    email: Yup.string()
        .required('Required'),
       

    website: Yup.string()
        .required('Required'),
        

   
        
   
});

const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {

	   let  data = values
	  
       dispatch(updateProfile(data,1))
        
}

	return(
		<>

		
			<div className="setting_detail_section">
				<h2 className="main_heading"><a href="/setting/whatsapp-setting" className="me-15"><BsArrowLeftSquare /></a> WhatsApp Business Profile</h2>
				
				<div className="d_flex">
					<div style={{ width: '65%' }}>

					<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			          >
		        
		          

						<div className="detail_card">
							<div className="heading_section">			
								<h2>Business Logo</h2>
								<p>Logo will appear as the profile picture of your business.</p>	
							</div>

							<div className="plan_detail_section">
								<div className="text_section"> 
									<h5>Upload your logo</h5>
									<p className="mb-15">Image should be Square, 640px and max 5mb in size.</p>

									<ButtonComponent name="Upload your logo" className="secondary" />
								</div>
							</div>
						</div>

						<div className="detail_card">
							<div className="heading_section">			
								<h2>Description</h2>
								<p>Add description for your business</p>	
							</div>

							<div className="plan_detail_section">

								<InputText name="business_name" title="Business Name" />
								<div className="text_section"> 
									<h5>Your business bio</h5>
									<p className="mb-5">Introduce your business and tell customers how you can help</p>

									<TextAreaField name="bio" title="Bio" rows="8" />
								</div>

								<div className="text_section"> 
									<h5>Category</h5>
									<p className="mb-5">Pick on eof the category for your business .</p>
									
									<SelectField name="category_id" list={categoryList} />
								</div>
							</div>
						</div>

						<div className="detail_card">
							<div className="heading_section">			
								<h2>Contact Information</h2>
								<p>Add your contact information for your WhatsApp profile. </p>	
							</div>

							<div className="plan_detail_section">
								<TextAreaField name="address" title="Your Business Address" />

								<InputText name="email" title="Your Business Email Address" />

								<InputText name="website" title="Your Business Email Website" />

							</div>
						</div>
						<br />
						


						<Button
				            mt={4}
				            colorScheme='teal'
				           
				            type='submit'
		          		>
		           		 Save
		          		</Button>
		        </Form>
					</div>

					<div style={{ width: '35%' }}>
						
					</div>
				</div>
			</div>
		</>
	);
}

export default WhatsappBusinessProfile;