import React from 'react';
import { Switch } from '@chakra-ui/react'

function SwitchComponent(props) {
	const {colorScheme} = props
	return(
		<>
			<Switch size="lg" colorScheme={colorScheme} />
		</>
	);
}

export default SwitchComponent;