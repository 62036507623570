import React from 'react';

import {
  
  CheckboxContainer,
  CheckboxControl,
  CheckboxSingleControl,
  
} from "formik-chakra-ui";



function CheckBoxComponent(props) {
  const {name,title,options,value,label, ...rest} = props

  
  return(
    <>
      <div className="field_section">
      
       
           
            
       <CheckboxSingleControl name={name}>
            {label}
          </CheckboxSingleControl>
      </div>
      
    </>
  );
}

export default CheckBoxComponent;