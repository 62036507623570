import React,{useEffect,useState} from 'react';
import Header from '../../components/Header'
import Navbar from '../../components/Navbar'
import List from './List'

function Campaign(){
	const [viewPage, setViewPage] = useState('');


	useEffect(() => {
       
       
        getPage('list');
        
    }, []);

    const getPage = (pageName) => {
    	if(pageName == 'list'){
	     		setViewPage(<List getPage={getPage} />)
    	}

    	// if(pageName == 'create'){
	    //  		setViewPage(<Create getPage={getPage} />)
    	// }
	     
	  }

	return(
		<>	
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="page-section" style={{width: '80%'}}>
					{viewPage}
				</div>
			</div>
		</>
	)
}

export default Campaign;