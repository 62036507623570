import { useEffect,useState,useRef } from 'react';
import * as Yup from 'yup';
import { Button,HStack,Box,Heading,CloseButton,Link,Text } from '@chakra-ui/react'
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'
import { fetchWrapper } from '../../../helpers';
import { Divider } from '@chakra-ui/react'
import axios from 'axios';
 import { BsArrowLeftSquare, BsTypeItalic, BsTypeBold } from "react-icons/bs";
function Create({getPage}){
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false)
	const [formData, setFormData] = useState({
        name: '',
        fb_attribute:'',
        description:'',
        status_id:'',
        fb_category_id:'',

      });

    const FormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
      
        description: Yup.string().required('Required'),
        status_id: Yup.string().required('Required'),
        
    });


    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

           
          
           let fd = new FormData();

       
          fd.append('file', selectedFile);
          fd.append('name', values.name);
          fd.append('fb_attribute', values.fb_attribute);
          fd.append('status_id', values.status_id);
          fd.append('description', values.description);
      
          fd.append('id', values.id);
          
          console.log(fd)
          const data = Object.fromEntries(fd)

         const url = `${process.env.REACT_APP_API_URL}/category/create`;


         const user = localStorage.getItem('user');
        const accessToken = JSON.parse(user)?.accessToken;
          let header = {
            headers: {
              access_token: accessToken,
            }
          }
          axios
          .post(url, fd,header)
          .then( 
               getPage('list')
           );
         // await fetchWrapper.post(url,fd);
         // getPage('list')
         
    }

    const statusList = [{name:'Active',id:1},{name:"In Active",id:0}]
    const categoryFBList = [{name:'Cat 1',id:1},{name:"Cat 2",id:2}]

    const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };


	return (

			<>
			
			<Form
                        enableReinitialize
                        validationSchema={FormSchema}
                        initialValues={formData}
                        onSubmit={onSubmit}
             >


      <HStack mb={5} justifyContent="space-between">       
        <HStack >
  				<Box>
            <a href="/category"><BsArrowLeftSquare size="24px" /></a>
          </Box>
  				<Box ml="15px">


  				<Heading mb="0px" as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			    Add Category
  			  </Heading>

  				</Box>
        </HStack>

        <HStack>
  				<Box w='10%' >
    				<Button 
              type='submit'
              className="primary"
            >
             Save Category
            </Button>

  				</Box>	
			  </HStack>
      </HStack>

      <HStack spacing='24px' justifyContent="space-between" w="100%" alignItems="unset">
        <HStack w='75%' flexDirection="column" alignItems="unset">
          <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
            <InputText name="name" title="Name"  />
	          <TextAreaField name="description" title="Description"  />
          </Box>

          <Box mb="25px !important" ml="0px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
              
            <Box position="relative" cursor="pointer" textAlign="center" p="20px" borderWidth="1px" borderColor="#878D92" borderStyle="dashed">
              <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Drop to upload images</Text>
              <Link fontSize="12px" color="#2F6DC9">Add Images</Link>
              <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Accept images in png and jpg format</Text>
              <input type="file" 
                    ref={fileInputRef}
                    className="file_input" name="file" onChange={(e) => changeHandler(e)} />
            </Box>
          </Box>

         
        </HStack>

        <HStack w='25%' alignItems='baseline'>
          <Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
          	<Box p="5" pb="0" >
            	<SelectField title="Category Status" list={statusList} name="status_id" />
            	
            	
            </Box>
            <hr />
            <Box p="5" pt="0" style={{display:'none'}}>
            	<Heading mb="3" fontSize="12px" fontFamily='Open Sans, sans-serif'>Facebook Attributes</Heading>
            	<InputText name="fb_attribute" title="Facebook Set Name"  />

            	<SelectField title="Facebook Category" list={categoryFBList} name="fb_category_id" />

            </Box>	
          </Box>
        </HStack>
        
      </HStack>

      <Divider borderColor="#878D92" my="30px" />

      
                        
        </Form>
			</>
		)
}

export default Create;