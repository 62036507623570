import { useEffect,useState } from 'react';


import * as Yup from 'yup';
import { Button } from '@chakra-ui/react'
import {InputText,TextAreaField,Form,SelectField} from '../../components/FormElement'
import { fetchWrapper } from '../../helpers';
import React from 'react';
import CheckBox from '../../components/CheckBox'
import { BsFillPersonFill } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import logo from '../../assets/images/logo.png'
import { Image,HStack,Box,Heading,Text,Link } from '@chakra-ui/react'

function SignUp() {


 

    const [formData, setFormData] = useState({
        name: '',
        whatsapp_number:'',
        email:'',
        store_name:'',
        password:'',

      });

    const FormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        whatsapp_number: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        store_name: Yup.string().required('Required'),
        password: Yup.string().required('Required')
    });


    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

           let  data = values
          
           
         const url = `${process.env.REACT_APP_API_URL}/api/auth/signup`;
         await fetchWrapper.post(url,data)
         
    }

    return (
            <>
                <HStack h="100vh">
                    <Box bg="#F5F5F5" h="100vh" w="55%" display="flex" alignItems="center" justifyContent="center">
                        <Heading fontSize="40px" color="#ccc" fontFamily='Open Sans, sans-serif'>Marketing Area</Heading>
                    </Box>

                    <Box py="50px" px="80px">
                        <Heading fontSize="18px" fontFamily='Open Sans, sans-serif' mb="10">Signup</Heading>

                        <Form
                            enableReinitialize
                            validationSchema={FormSchema}
                            initialValues={formData}
                            onSubmit={onSubmit}
                        >
                            <InputText name="name" title="Name"  />
                            <InputText name="whatsapp_number" title="WhatsApp Number"  />
                            <InputText name="email" title="Email"  />
                            <InputText name="store_name" title="Your Store Name"  />

                            <HStack alignItems="end">
                                <Box>
                                    <InputText name="password" title="Password"  />
                                </Box>

                                <Box>
                                    <InputText name="password" title=""  />
                                </Box>
                            </HStack>

                            <CheckBox name="I have read and agree to terms and conditions" />

                            <Button
                                border='1px'
                                  borderColor='#047E60'
                                  bg="#047E60"
                                  px="30px"
                                  py="10px"
                                  color="#fff"
                                  fontSize="14px"
                                  fontFamily='Open Sans, sans-serif'
                                type='submit'
                            >
                             Continue
                            </Button>
                        </Form>
                    </Box>
                </HStack>
            </>
        )
}

export default SignUp