import React,{useEffect,useState} from 'react';
import Header from '../../components/Header'
import Navbar from '../../components/Navbar'
import List from './orders/List'
import Detail from './orders/Detail'
import { useLocation } from "react-router-dom";
function Orders(){

	const {state} = useLocation();
	console.log(state)
	const order_id = state.id;
	const [viewPage, setViewPage] = useState('');


	useEffect(() => {
       
       
        getPage('detail');
        
    }, []);

    const getPage = (pageName) => {
    	
    	if(pageName == 'detail'){
	     		setViewPage(<Detail order_id={order_id} getPage={getPage} />)
    	}

	     
	  }

	return(
		<>	
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="page-section" style={{width: '80%'}}>
					{viewPage}
				</div>
			</div>
		</>
	)
}

export default Orders;