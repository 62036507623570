import { useEffect,useState,useRef } from 'react';
import * as Yup from 'yup';
import { Button,HStack,Box,Heading,CloseButton,Input,Link,Text, 
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer

 } from '@chakra-ui/react'
 import { BsArrowLeftSquare, BsTypeItalic, BsTypeBold } from "react-icons/bs";
import {InputText,TextAreaField,SelectField,FileUpload} from '../../../components/FormElement'
import { fetchWrapper } from '../../../helpers';
import { Divider } from '@chakra-ui/react'
import axios from 'axios';


import {
    Formik,
    Form as FormikForm,
    Field,
    ErrorMessage,
    useFormikContext,
    useField,
    useFormik,
} from 'formik';


function Edit({getPage,rs}){


   const fileInputRef = useRef();

   const questionListValue = (rs.question_list != '') ? JSON.parse(rs.question_list) : [];

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false)
    const [attributeList, setAttributeList] = useState([]);
    const [questionList, setQuestionList] = useState(questionListValue);


    useEffect(() => {
        loadAtributeList()
       
       
        
    }, []);
    const loadAtributeList = async() => {
      const url = `${process.env.REACT_APP_API_URL}/attribute/list`;
         const data = await fetchWrapper.get(url);
         setAttributeList(data.data)
    }



	const [formDataIn, setFormDataIn] = useState({
        name: rs.name,
        fb_attribute:rs.fb_attribute,
        description:rs.description,
        status_id:rs.status_id,
        fb_category_id:rs.fb_category_id,
        id:rs.id,
        file:"",
        question:"",
        attribute_id:"",

      });

    const FormSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        
        description: Yup.string().required('Required'),
        status_id: Yup.string().required('Required'),
        
    });


    const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

          

           let fd = new FormData();
       
          fd.append('file', selectedFile);
          fd.append('name', values.name);
          fd.append('fb_attribute', values.fb_attribute);
          fd.append('status_id', values.status_id);
          fd.append('description', values.description);
          fd.append('question_list',JSON.stringify(questionList));
          fd.append('id', values.id);
          
          console.log(selectedFile)
          const data = Object.fromEntries(fd)
           
         const url = `${process.env.REACT_APP_API_URL}/category/update`;

         axios
          .post(url, fd)
          .then( 
               getPage('list')
           );

         //await fetchWrapper.post(url,data);
        //getPage('list')
         
    }

    const statusList = [{name:'Active',id:1},{name:"In Active",id:0}]
    const categoryFBList = [{name:'Cat 1',id:1},{name:"Cat 2",id:2}]

const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };
    
 const fileInputClicked = () => {
        fileInputRef.current.click();
    }

 const addAttribute = (values) => {

    const listVal  = {attribute_id:values.attribute_id,question:values.question}
    setQuestionList(
       [
        ...questionList,
        listVal
        ]
      )
    
 }
 const attributeName = (attribute_id) => {

    const val =  attributeList.filter(rs => rs.id == attribute_id);
   
    return val[0]?.name;
 }
	return (

			<>
			
			<Formik
            enableReinitialize
                        validationSchema={FormSchema}
                        initialValues={formDataIn}
                        onSubmit={onSubmit}

        >
         {({
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,

          }) => {
             

          return (

            <FormikForm  className="needs-validation" noValidate="">  


      <HStack mb={5} justifyContent="space-between">       
        <HStack >
  				<Box>
            <a href="/category"><BsArrowLeftSquare size="24px" /></a>
          </Box>
  				<Box ml="15px !important">

  				<Heading mb="0px" as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
  			    Edit Category
  			  </Heading>

  				</Box>
        </HStack>

        <HStack>
  				<Box w='10%' >
    				<Button 
              type='submit'
              className="primary"
            >
             Save Category
            </Button>

  				</Box>	
			  </HStack>
      </HStack>

      <HStack spacing='24px' justifyContent="space-between" w="100%" alignItems="unset">
        <HStack w='75%' flexDirection="column" alignItems="unset">
          <Box mb="25px" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
            <InputText name="name" title="Name"  />
	          <TextAreaField name="description" title="Description"  />
          </Box>
          <Box mb="25px !important" ml="0px !important" bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9">
              
            <Box position="relative" cursor="pointer" textAlign="center" p="20px" borderWidth="1px" borderColor="#878D92" borderStyle="dashed">
              <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Drop to upload images</Text>
              <Link fontSize="12px" color="#2F6DC9">Add Images</Link>
              <Text mb="0px" color="#6C7074" fontSize="12px" fontWeight="400" fontFamily="'Open Sans', sans-serif !important">Accept images in png and jpg format</Text>
              <input type="file" 
                    ref={fileInputRef}
                    className="file_input" name="file" onChange={(e) => changeHandler(e)} />
            </Box>
          </Box>

          <br />

          <Heading as='h4' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif'>
            Bot Questions for {rs.name}

          </Heading>

          <Box bg="white" p="5" borderRadius='5' border="1px" borderColor="#E8E8E9" m="0px !important">
            <HStack spacing='24px' justifyContent="space-between" w="100%" alignItems="end">
              <HStack w='50%' alignItems='baseline'>
                  <InputText name="question" title="Question"  />
              </HStack>
              <HStack w='25%' alignItems='baseline' >
                  <SelectField title="Attributes" list={attributeList} name="attribute_id" />
              </HStack>

              <HStack w='25%' alignItems='baseline'>

                  <Button onClick={() => addAttribute(values)} mb="20px">Add</Button>
              </HStack>
            </HStack>
               

               <br />

               <TableContainer>
                          <Table>
                            <Thead>
                              <Tr>
                                <Th>Question</Th>
                                <Th>Attribute</Th>
                               
                                <Th></Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                            {questionList?.map((item,index) => {

                              return (
                              <Tr key={index}>     
                                <Td>{item.question}</Td>
                                <Td>{attributeName(item.attribute_id)}</Td>
                                <Td></Td>
                                  
                                
                              </Tr>
                               )
                            }
                              )}
                            </Tbody>
                          </Table>
                      </TableContainer>
            
          </Box>
        </HStack>

        <HStack w='25%' alignItems='baseline'>
          <Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
          	<Box p="5" pb="0">
            	<SelectField title="Category Status" list={statusList} name="status_id" />
            	
            	
            </Box>
            <hr />
            <Box p="5" pt="0" style={{display:'none'}}>
            	<Heading mb="3" fontSize="12px" fontFamily='Open Sans, sans-serif'>Facebook Attributes</Heading>
            	<InputText name="fb_attribute" title="Facebook Set Name"  />

            	<SelectField title="Facebook Category" list={categoryFBList} name="fb_category_id" />

            </Box>	
          </Box>
        </HStack>
        
      </HStack>




      <Divider borderColor="#878D92" my="30px" />

      <HStack justifyContent="flex-end">
        <Button
          type='submit'
          size='md'
          border='1px'
          borderColor='#E70C0C'
          px="30px"
          py="10px"
          color="#E70C0C"
          fontSize="14px"
          fontFamily='Open Sans, sans-serif'
          variant='outline'
        >
         Delete Category
        </Button>
      </HStack>
                        
        </FormikForm>
           )
          }
        }
        </Formik>
			</>
		)
}

export default Edit;