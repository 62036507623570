import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../components/ButtonComponent'
//import Quill from "../../components/Quill";
import ReactQuill from "react-quill";
import { BsThreeDots } from "react-icons/bs";
import {InputText,Form,InputDateField,SelectField,TextAreaField,RadioComponent} from '../../components/FormElement'
import { fetchWrapper } from '../../helpers';
import * as Yup from 'yup';
import "react-quill/dist/quill.snow.css"; 
import { Button ,HStack,Spinner } from '@chakra-ui/react'
import { toast } from "react-toastify";
import CustomModalLink from '../../components/FormElement/CustomModalLink'
import Create  from './policies/create';
function PoliciesSetting(){

	const [list,setList] = useState({})
	const [formData, setFormData] = useState({})
	const [loading,setLoading] = useState(false);
	const [refund, setRefund] = useState('');
	const [privacy, setPrivacy] = useState('');
	const [term, setTerm] = useState('');
	const [contact, setContact] = useState('');
	const [shipping, setShipping] = useState('');
	useEffect(() => {
        loadDetail();
        
    }, []);

  const loadDetail = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/policy/by-id`;
      const data  = await fetchWrapper.get(url);
      const detailData = data?.data;
      setList(detailData);


      setRefund(detailData?.refund)
      setPrivacy(detailData?.privacy)
      setTerm(detailData?.terms)
      setContact(detailData?.contact)
      setShipping(detailData?.shipping)
      

      setFormData({
		    refund: detailData?.refund,
		    privacy: detailData?.privacy,
		    terms: detailData?.terms,
		    contact: detailData?.contact,
		    shipping:detailData?.shipping,
		    id:detailData?.id,
		   

    
  		})
  
      setLoading(false);
  }

  const FormSchema = Yup.object().shape({
	  // refund: Yup.string().required('Required'),

	});

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
  			setLoading(true)
		   let  data = {};

		   data.refund = refund;
		   data.privacy = privacy;
		   data.terms = term;
		   data.contact = contact;
		   data.shipping = shipping
		   data.id = list.id
		  console.log(data);

	       
	     const url = `${process.env.REACT_APP_API_URL}/policy/create`;
  		 await fetchWrapper.post(url,data)
  		 setLoading(false);
  		 toast(`Record has been saved`,{
  		 	type:'success'
  		 })

  		 
	}
	return(
		<>
			{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<div className="setting_detail_section">

			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >
				<h2 className="main_heading">Policies</h2>

				<div className="text_section mt-20">
					<h4>Store policies</h4>
					<p>Create your own store policies, or customize a template. Save policy will be sent to customer WhatsApp number upon request. Templates aren’t legal advise. By using these templates you agree that you have read and agree to the <a href="#">disclaimer</a>.</p>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Refund policy</h2>	
					</div>

					<div className="plan_detail_section">
					
						 
						 <ReactQuill theme="snow" value={refund} onChange={setRefund} style={{marginBottom:"20px"}} />
						 	<CustomModalLink
						 	className="secondary mt-10"
									        showModalButtonText="Create from template"
									        modalHeader="Create from template"
									        modalBody=<Create value={refund} loadList={loadDetail} list={list} name="Refund policy" />
									      />
						{/*<ButtonComponent name="Create from template" className="secondary mt-10"/>*/}
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Privacy policy</h2>	
					</div>

					<div className="plan_detail_section">
						
						<ReactQuill theme="snow" value={privacy} onChange={setPrivacy} style={{marginBottom:"20px"}} />
						<CustomModalLink
						 	className="secondary mt-10"
			        showModalButtonText="Create from template"
			        modalHeader="Create from template"
			        modalBody=<Create value={privacy} loadList={loadDetail}  list={list} name="Privacy policy" />
			      />
						{/*<ButtonComponent name="Create from template" className="secondary mt-10"/>*/}
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Terms of service</h2>	
					</div>

					<div className="plan_detail_section">
						<ReactQuill theme="snow" value={term} onChange={setTerm} style={{marginBottom:"20px"}}/>
						<CustomModalLink
						 	className="secondary mt-10"
			        showModalButtonText="Create from template"
			        modalHeader="Create from template"
			        modalBody=<Create value={term} loadList={loadDetail}  list={list} name="Terms of service"  />
			      />
						{/*<ButtonComponent name="Create from template" className="secondary mt-10"/>*/}
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Shipping policy</h2>	
					</div>

					<div className="plan_detail_section">
						<ReactQuill theme="snow" value={shipping} onChange={setShipping} style={{marginBottom:"20px"}} />
						<CustomModalLink
						 	className="secondary mt-10"
			        showModalButtonText="Create from template"
			        modalHeader="Create from template"
			        modalBody=<Create value={shipping} loadList={loadDetail}  list={list} name="Shipping policy"/>
			      />
						{/*<ButtonComponent name="Create from template" className="secondary mt-10"/>*/}
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Contact information</h2>
						<p>Contact information is required for your store if you are selling into the European Union.</p>	
					</div>

					<div className="plan_detail_section">
						<ReactQuill theme="snow" value={contact} onChange={setContact} style={{marginBottom:"20px"}} />
						<CustomModalLink
						 	className="secondary mt-10"
			        showModalButtonText="Create from template"
			        modalHeader="Create from template"
			        modalBody=<Create value={contact} loadList={loadDetail}  list={list} name="Contact information" />
			      />
						{/*<ButtonComponent name="Create from template" className="secondary mt-10"/>*/}
					</div>
				</div>


				<Button 
                    type='submit'
                    className="primary"
                    mt="20px"
                  >
                   Save
                  </Button>

				</Form>
			</div>

		
		</>
	);
}

export default PoliciesSetting;