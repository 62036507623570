import React,{useEffect,useState} from 'react';
import { Button ,HStack,Spinner} from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'

import { fetchWrapper } from '../../../helpers';
import { toast } from "react-toastify";




function EditProfile({loadList,profileData,onClose}){
	const [loading,setLoading] = useState(false);
	const rs = profileData
	console.log(rs)

	const [formData, setFormData] = useState({
    store_name: rs?.store_name,
    whatsapp_number:rs?.whatsapp_number,
    email:rs?.email,
    contact:rs?.contact

  });



  const FormSchema = Yup.object().shape({
	    store_name: Yup.string()
	          .required('Required'),
	       // whatsapp_number: Yup.string()
	       // .required('Required'),
	       // emaail: Yup.string()
	       // .required('Required'),
	       // contact: Yup.string()
	       // .required('Required')
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		  setLoading(true)
		let data = {}
		   data.type = 'store_profile'
		   data.setting_data = values;
		   console.log(data)
	       
	     const url = `${process.env.REACT_APP_API_URL}/tenent/settings`;
  		 await fetchWrapper.post(url,data)
  		 loadList();
  		 onClose()
  		 setLoading(false);
  		 toast(`Record has been update`,{
  		 	type:'success'
  		 })
	}

	const fieldList = [{id:'textfield',name:'Text Field'},{id:'dropdown',name:'Drop Down'}]


	return (

			<>
			{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="store_name" title="Store Name"  />

			 	<InputText name="whatsapp_number" title="Phone"  />

			 	<InputText name="contact" title="Contact"  />

			 	<InputText name="email" title="Email"  />

		         <Button
				            colorScheme='teal'
				            border='1px'
			              borderColor='#047E60'
			              bg="#047E60"
			              px="20px"
			              py="10px"
			              color="#fff"
			              fontSize="14px"
			              fontFamily='Open Sans, sans-serif'
			              mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default EditProfile;