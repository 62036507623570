import React,{useEffect,useState} from 'react';
import facebook_catalog_img from '../../assets/images/facebook_catalog.png'
import facebook_integration_img from '../../assets/images/facebook_integration.png'
import user_placeholder from '../../assets/images/user_placeholder.png'
import Badge from 'react-bootstrap/Badge';

const Setup = ({getPage}) =>{
	
	return (


			<>
				<div className="facebook_catalog_page">
					<div className="facebook_catalog_section">
						<img src={facebook_catalog_img} />

						<div className="text_section">
							<h4 className="main_heading">Connect your Facebook Catalog</h4>

							<div className="para_section">
								<h5>Heading One</h5>

								<p>Lorem ipsum dolor sit amet consectetur. Vel egestas eu risus id tristique sed consequat. Pretium pellentesque metus neque ligula in faucibus sit morbi. Tortor auctor praesent suspendisse eu arcu id. Suspendisse in molestie elit nulla id enim facilisi odio.</p>
							</div>

							<div className="para_section">
								<h5>Heading Two</h5>

								<p>Lorem ipsum dolor sit amet consectetur. Vel egestas eu risus id tristique sed consequat. Pretium pellentesque metus neque ligula in faucibus sit morbi. Tortor auctor praesent suspendisse eu arcu id. Suspendisse in molestie elit nulla id enim facilisi odio.</p>
							</div>

							<div className="para_section">
								<h5>Heading Three</h5>

								<p>Lorem ipsum dolor sit amet consectetur. Vel egestas eu risus id tristique sed consequat. Pretium pellentesque metus neque ligula in faucibus sit morbi. Tortor auctor praesent suspendisse eu arcu id. Suspendisse in molestie elit nulla id enim facilisi odio.</p>
							</div>

							<button type="button" className="btn primary" onClick={() => getPage('account')}>Start Setup</button>
						</div>
					</div>

					
				</div>

				
			
			</>
		)
}


export default Setup;