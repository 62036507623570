import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../components/ButtonComponent'
import SwitchComponent from '../../components/SwitchComponent'
import InputText from '../../components/InputText'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { BsThreeDots } from "react-icons/bs";
import CheckBox from '../../components/CheckBox'
import RadioComponent from '../../components/RadioComponent'


import { fetchWrapper } from '../../helpers';
import ProductType from './product/ProductType';
import Brand from './product/Brand';
import Attribute from './product/Attribute';
function ProductSetting(){

	const [productTypeList,setProductTypeList] = useState([])
	useEffect(() => {
        loadProductType()
       
       
        
    }, []);

  const loadProductType = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/product-type`;
  		const data  = await fetchWrapper.get(url)
  		setProductTypeList(data)
  }
	const saveProduct = () => {
			
	}
	return(
		<>
			<div className="setting_detail_section">
				<h2 className="main_heading">Products</h2>

				<ProductType />

				<Brand />

				<Attribute />
			</div>
		</>
	);
}

export default ProductSetting;