import React,{useState,useEffect} from 'react';
import ButtonComponent from '../../components/ButtonComponent'

import Mastercard from '../../assets/images/mastercard.png'
import Visa from '../../assets/images/visa.png'
import { BsFillExclamationCircleFill } from "react-icons/bs";
import CheckBox from '../../components/CheckBox'
import RadioComponent  from '../../components/RadioComponent'

import { toast } from "react-toastify";

import { Button ,HStack,Spinner} from '@chakra-ui/react'

import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField,RadioComponent as NewRadioComponent,CheckBoxComponent} from '../../components/FormElement'

import { fetchWrapper } from '../../helpers';

function CheckoutSetting(){


	const [formData, setFormData] = useState({
    full_name_text: 'Please enter your full name',
    email_address_text: 'Please enter your email address',
    company_name_text: 'Please enter your company name',
   	address_text: 'Please enter your complete address including your city name.',
    full_name:'',
    marketing_email:'',
    marketing_whatsapp:'',
    abd_send_message:'',
    no_of_reminder:'',
    first_message:'',
    discount_offer:'',
    discount_percent:'',
  });
	const [tenent,setTenent] = useState({})
	const [checkoutSetting,setCheckoutSetting] = useState({})
	const [loading,setLoading] = useState(false);
	 useEffect(() => {
        loadData();
        
        
        
    }, []);

  const FormSchema = Yup.object().shape({
	    full_name_text: Yup.string()
	          .required('Required'),
	      // field_type: Yup.number()
	      // .required('Required')
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		  

		setLoading(true)
		  const checkoutInput = [{
	       		label: values.full_name_text,
	       		required:'Y',id:'name',input_option:values.full_name},
	       		{
	       		label: values.email_address_text,
	       		required:'Y',id:'email',email:values.email,input_option:values.email_address},
	       		{
	       		label: values.company_name_text,
	       		company_name_require:'Y',id:'company',input_option:values.company_name},
	       		{
	       		label: values.address_text,
	       		required:'Y',id:'address',input_option:values.address}
	       		];

		  const marketing_input = {marketing_whatsapp:values.marketing_whatsapp,marketing_email:values.marketing_email}

		  const abandoned_input = {send_message:values.abd_send_message,no_of_reminder:values.no_of_reminder,
		  first_message:values.first_message,discount_offer:values.discount_offer,discount_percent:values.discount_percent}

		  const checkoutData = {checkout_input:checkoutInput,marketing_input:marketing_input,abandoned_input:abandoned_input}
	      
	       const data = {
	       		type:'checkout',

	       		setting_data:checkoutData

	       
	   }
  			
	     const url = `${process.env.REACT_APP_API_URL}/tenent/settings`;
  		 await fetchWrapper.post(url,data)
  		 console.log('done');
  		 setLoading(false)
  		 toast(`Record has been update`,{
  		 	type:'success'
  		 })
	}
	const loadData = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/tenent/by-id`;
  		const data  = await fetchWrapper.get(url)
  		setTenent(data.data)

  		const tenentData = data.data
  		const settingsData = JSON.parse(tenentData.settings)
  		const checkSettingValue = settingsData['checkout'];
  		setCheckoutSetting(checkSettingValue)

  		console.log(checkSettingValue)
  		setFormData({
	    full_name_text: checkSettingValue?.checkout_input[0]?.label,
	    email_address_text: checkSettingValue?.checkout_input[1]?.label,
	    company_name_text: checkSettingValue?.checkout_input[2]?.label,
	    address_text: checkSettingValue?.checkout_input[3]?.label,
	    full_name:checkSettingValue?.checkout_input[0]?.input_option,
	    email_address:checkSettingValue?.checkout_input[1]?.input_option,
	     company_name:checkSettingValue?.checkout_input[2]?.input_option,
	     address:checkSettingValue?.checkout_input[3]?.input_option,
	     marketing_email:checkSettingValue?.marketing_input?.marketing_email,
	     marketing_whatsapp:checkSettingValue?.marketing_input?.marketing_whatsapp,
	   
	    abd_send_message:checkSettingValue?.abandoned_input?.abd_send_message,
	    no_of_reminder:checkSettingValue?.abandoned_input?.no_of_reminder,
	    first_message:checkSettingValue?.abandoned_input?.first_message,
	    discount_offer:checkSettingValue?.abandoned_input?.discount_offer,
	    discount_percent:checkSettingValue?.abandoned_input?.discount_percent,
	     
  		})
  	}


	return(
		<>
		{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
		
			<div className="setting_detail_section">

			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >
				<h2 className="main_heading">Checkout</h2>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Checkout</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="text_section" >
							<p>Tailor your shopping experience effortlessly! Explore our Checkout  Settings page to customize payment methods, shipping options, and more.  Simplify transactions and enhance customer satisfaction.</p>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Customer Information</h2>	
					</div>

					<div className="plan_detail_section py-0">
						<div className="customer_list">
							<div className="text_section mb-20">
								

								
									<NewRadioComponent options={[{id:1,label:'Require first and last name',prefer:1},{id:2,label:'Only require last name',prefer:0}]}  name="full_name" title="Full Name" />
									

								
							</div>

							<div className="text_section">
								<p>Message sent</p>

								
									
										<InputText name="full_name_text"  />
									
								
							</div>
						</div>

						<div className="customer_list">
							<div className="text_section mb-20">

								<NewRadioComponent options={[{id:1,label:'Don’t Include',prefer:1},{id:2,label:'Required',prefer:0}]} name="email_address" title="Email Address" />
								

								

								
							</div>

							<div className="text_section">
								<p>Message sent</p>

								
									<InputText name="email_address_text"  />
								
							</div>
						</div>

						<div className="customer_list">
							<div className="text_section mb-20">

								<NewRadioComponent options={[{id:1,label:'Don’t Include',prefer:1},{id:2,label:'Required',prefer:0}]} name="company_name" title="Company Name" />
								

								
							</div>

							<div className="text_section">
								<p>Message sent</p>

								
									<InputText name="company_name_text"  />
								
							</div>
						</div>

						<div className="customer_list">
							<div className="text_section mb-20">
									<NewRadioComponent options={[{id:1,label:'Don’t Include',prefer:1},{id:2,label:'Required',prefer:0}]} name="address" title="Address" />
								

								
							</div>

							<div className="text_section">
								<p>Message sent</p>

								
									<InputText name="address_text"  />
								
							</div>
						</div>
					</div>
				</div>

				<div className="detail_card">
 					<div className="heading_section">
 						<h2>Marketing Options</h2>

 						<p>Let customer Opt-in for WhatsApp Marketing</p>
 					</div>

 					<div className="plan_detail_section">
 						<div className="d_flex justify_content_between">	
 							<div>
 								

 								<CheckBoxComponent label="WhatsApp" name="marketing_whatsapp" />
 							</div>

 							<div>
 								<a href="#">Opt-in Settings</a>
 							</div>
 						</div>
 						<CheckBoxComponent label="Email" name="marketing_email" />
 						

 						<div className="text_section mb-20">
 							<p>Message sent</p>
 							<p>Would you like to receive updates and product information?</p>
 						</div>

 						<div className="error_section">
 							<p className="d_flex align_center"><BsFillExclamationCircleFill className="me-10" /> To collect WhatsApp consent, you need to set up a terms of service and privacy policy in your legal settings</p>
 						</div>
 					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section d_flex justify_content_between">
						<div>			
							<h2>Abandoned Cart Notification</h2>
							<p>Send WhatsApp messages to customers who didn’t finish checking out.</p>
						</div>

						<div>
							<a href="#">Customize notification messages</a>
						</div>	
					</div>

					<div className="plan_detail_section py-0">
						<div className="customer_list pb-0">
						<CheckBoxComponent label="Send abandoned checkout WhatsApp messages automatically" name="abd_send_message" />
							
						</div>

						<div className="customer_list">
							<div className="text_section">
								


									<NewRadioComponent options={[{id:1,label:'1',prefer:0},{id:2,label:'2 Sent after 24 hours of first message',prefer:0},
									{id:3,label:'3 Sent after 72 hours of second message',prefer:0},
								]} name="no_of_reminder" title="No of reminders to be sent" />

								

								
							</div>
						</div>

						<div className="customer_list">
							<div className="text_section">


								<NewRadioComponent options={[{id:1,label:'1 hour',prefer:0},{id:6,label:'6 hour',prefer:0},
									
									{id:10,label:'10 hours',prefer:1},
									{id:24,label:'24 hours',prefer:0},
								]} name="first_message" title="First message will be send after" />
								

								

								

								
							</div>
						</div>

						<div className="customer_list d_flex">
							<div className="me-15">
								
								<CheckBoxComponent label="" name="discount_offer" />
							</div>

							<div className="text_section">
								<p>Offer discount on abandoned cart</p>

								<InputText name="discount_percent" label="Discount %" />
							</div>

						</div>
					</div>
				</div>

				<div className="text_right mt-20">
					
					<Button
				            mt={4}
				            colorScheme='teal'
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>
				</div>


				</Form>
		
			</div>
			</>
		
	);
}

export default CheckoutSetting;