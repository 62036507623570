import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
  
import React from 'react';
const CustomModalLink = ({ showModalButtonText, modalHeader, modalBody ,className }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();


  return (
    <>
      <a size="xs" onClick={onOpen} style={{cursor: 'pointer'}} className={className}>
        {showModalButtonText}
      </a>
      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {React.cloneElement(modalBody, { onClose: onClose })}

            
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModalLink;