import React,{useEffect,useState} from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  IconButton,
  Image,
  Checkbox,
  Badge,
  Text

} from '@chakra-ui/react'
import { BsSearch , BsArrowDownUp, BsImages } from "react-icons/bs";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button,HStack,Box,Heading,CloseButton,Link,Spinner } from '@chakra-ui/react'
import { fetchWrapper } from '../../../helpers';
import { Routes, Route, Outlet } from 'react-router-dom';


function List({getPage,random}){


	const [loading,setLoading] = useState(false);

	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, [random]);

  const loadList = async () => {
  		setLoading(true);
  		const url = `${process.env.REACT_APP_API_URL}/category/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  		setLoading(false);
  }
  const syncCat = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/category/create-intent`;
  		const data  = await fetchWrapper.get(url);
  		loadList()
  		
  }

   const filterList = (status) => {
    if (status === 'all') {
      return list;
    }
    return list.filter((item) => (status === 'active' ? item.status_id === 1 : item.status_id === 0));
  };

	return (

			<>
			{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}
			<div className="detail_card">
					<HStack justifyContent="space-between" mb="25px">
						<Box>
							<Heading as='h3' size='lg' fontSize='18px' fontFamily='Open Sans, sans-serif' mb="2px">
  			 				Category
  			  		</Heading>
  			  		<Text  fontSize='10px' fontFamily='Open Sans, sans-serif' mb="0">
  			 				Categories are called Sets in Facebook Catalog.
  			  		</Text>
						</Box>

						


						<Box>

						{<Button
							type='submit'
              size='md'
              border='1px'
              borderColor='blue'
              bg="blue"
              px="30px"
              mr="10px"
              py="10px"
              color="#fff"
              fontSize="14px"
              fontFamily='Open Sans, sans-serif'
							onClick={() => syncCat()}
							>Create Intent</Button>}

							<Button
							type='submit'
              className="primary"
							onClick={() => getPage('create')}
							>Add Category</Button>
						</Box>
					</HStack>


					<HStack alignItems="unset" flexDirection='column'>
						<Box bg="white" borderRadius='5' border="1px" borderColor="#E8E8E9">
							<Tabs variant='soft-rounded' colorScheme='green'>
							  <TabList my="15px" mx="20px">
							    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif' onClick={()=> loadList()}>All</Tab>
							    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif' onClick={()=> loadList()}>Active</Tab>
							    <Tab px='4' py="1" borderRadius="5px" fontSize="12px" fontFamily='Open Sans, sans-serif' onClick={()=> loadList()}>Inactive</Tab>
							  </TabList>

							  <TabPanels>
							   {['all', 'active', 'inactive'].map((status, index) => (
							    <TabPanel p='0' key={index}>
							      <div className="table_section">
											<TableContainer>
											  	<Table>
											  		<Thead>
											  			<Tr>
											  			<Th><Checkbox></Checkbox></Th>
											  			<Th></Th>
											  				<Th>Sync</Th>
											  				<Th>Category Name</Th>
											  				<Th>Status</Th>
											  				<Th>Product</Th>
											  				<Th>Bot Questions</Th>
											  				<Th></Th>
											  			</Tr>
											  		</Thead>
												    <Tbody>
												    {filterList(status)?.map(rs => {

												    	return (
												      <Tr key={rs.id}>
												      <Th><Checkbox></Checkbox></Th>
												      <Td>
												      	{rs.image != '' ? (

												      			<Image
																	    boxSize='50px'
																	    objectFit='cover'
																	    src={`${process.env.REACT_APP_API_URL}/upload/tenent/1/category/${rs.image}`}
																	    alt={rs.name}
																	  />


												      		) : (


												      		<IconButton bg="transparent" border="1px dashed" borderColor="#E0E2E4" borderRadius="3px" color="#878D92" aria-label='Search database' icon={<BsImages />} />

												      		) }
												      	

												      		
												      </Td>
												      <Th>{rs.sync_status == 1 ? 'Yes' : 'No'}</Th>
												        <Td>{rs.name}</Td>
												        <Td><Badge bg={rs.status_id === 1 ? "#ACE8CF" : "#F7D6D9"} py="5px" px="15px" borderRadius="25px" color="#252627" fontWeight="600" textTransform="capitalize">{rs.status_id == 1 ? 'Active' : 'Inactive'}</Badge></Td>
												        <Td>{rs.productCount}</Td>
												        <Td style={{color:"#2F6DC9"}}>{
												        	rs.question_list != "" ?
												        	JSON.parse(rs.question_list).length
												        	: 0

												        }</Td>
												        {<Td>
												        		<Link onClick={() => getPage('edit',rs)}>Edit</Link>
												        </Td>}
												        
												      </Tr>
												       )
												    }
												    	)}
												    </Tbody>
												  </Table>
											</TableContainer>
										</div>
							    </TabPanel>
							  ))}
							  </TabPanels>
							</Tabs>
							
						</Box>
					</HStack>
					
			</div>

			</>

		)
}
export default List;