import React from 'react';
import { BsFillCheckCircleFill, BsFillFileEarmarkArrowDownFill } from "react-icons/bs";
import ButtonComponent from '../../components/ButtonComponent'
import SwitchComponent from '../../components/SwitchComponent'
import InputGroupText from '../../components/InputGroupText'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import Mastercard from '../../assets/images/mastercard.png'
import { BsCurrencyDollar, BsFillCaretDownFill, BsTriangle, BsFillPersonFill } from "react-icons/bs";

function Billing(){
	return(
		<>
			<div className="setting_detail_section">
				<h2 className="main_heading">Billing</h2>

				<div className="d_flex justify_content_between align_baseline">
					<div className="detail_card me-15 bg_green">
						<div className="billing_detail_section">
							<div className="d_flex justify_content_between mb-5">
								<h2>Subscriptions: Starter Plan</h2>

								<ButtonComponent name="Change Plan" className="secondarysmall" />
							</div>

							<p className="mb-15">This is a recurring charge. The selected Subscription fees will be charged to your card every 30 days.<a href="#">Learn more</a></p>

							<h1 className="amount_text">$ 29.99</h1>
						</div>
					</div>

					<div className="detail_card">
						<div className="billing_detail_section bg_danger">
							<div className="d_flex justify_content_between mb-5">
								<h2>WhatsApp Usage Credits</h2>

								<ButtonComponent name="Topup" className="secondarysmall" />
							</div>

							<p className="mb-15">This is a recurring charge. The selected Subscription fees will be charged to your card every 30 days.<a href="#">Learn more</a></p>

							<div className="d_flex justify_content_between align_center">
								<h1 className="amount_text">$ 250.00</h1>

								<a className="d_flex align_center" href="#">Auto top up <BsFillCaretDownFill /></a>
							</div>
						</div>

						<div className="billing_detail_section">
							<div className="topup_section d_flex justify_content_between align_center">
								<p>Auto top up</p>

								<SwitchComponent colorScheme="green" />
							</div>

							<div className="d_flex">
								<div className="me-30" style={{ width: '40%' }}>
									<InputGroupText icon={<BsCurrencyDollar />} name="If balance falls below" />
								</div>

								<div style={{ width: '40%' }}>								
									<InputGroupText icon={<BsCurrencyDollar />} name="Automatically recharge" />
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="detail_card">
					<div className="heading_section">			
						<h2>Payment Method</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="d_flex justify_content_between">
							<div className="text_section">
								<h5 className="mb-10">Payment method</h5>

								<p className="d_flex align_center"><img src={Mastercard} /> Mastercard ending in 1109</p>
							</div>

							<div>
								<a href="#">Replace</a>
							</div>
						</div>
					</div>

					<div className="plan_detail_section">
						<ButtonComponent name="Add Payment Method" className="secondary" />
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Billing Information</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="d_flex justify_content_between">
							<div className="text_section">
								<h5 className="mb-5">Company name goes here</h5>

								<p className="mb-5">Address line 1</p>

								<p className="mb-5">Address line 2</p>

								<p className="mb-5">City, State, Country, Zip Code</p>

								<p className="mb-5">Contact #</p>

								<p className="mb-5">WhatsApp #</p>

								<p className="mb-5">E-Mail Address</p>
							</div>

							<div>
								<a href="#">Edit</a>
							</div>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Subscriptions</h2>

						<p>Items you’re billed for on a recurring basis, like your WhatsApp Store, WhatsApp conversations.</p>	
					</div>

					<div className="plan_detail_section py-0">
						<div className="subscription_card_section d_flex justify_content_between">
							<div className="d_flex">
								<div className="icon_box_section me-10">
									<BsTriangle />
								</div>

								<div className="text_section">
									<h5>Flux Cart</h5>

									<p>$ 49.00 USD every 30 days. Renews on April 03, 2023</p>
								</div>
							</div>

							<div>
								<a href="#">Change Plan</a>
							</div>
						</div>

						<div className="subscription_card_section d_flex justify_content_between">
							<div className="d_flex">
								<div className="icon_box_section me-10">
									<BsFillPersonFill />
								</div>

								<div className="text_section">
									<h5>Additional Agents x 5</h5>

									<p>$ 25.00 USD every 30 days. Renews on April 03, 2023</p>
								</div>
							</div>

							<div>
								<a href="#">Change Plan</a>
							</div>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Invoices</h2>
					</div>

					<div className="plan_detail_section p-0">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>
								        <Td>Subscription</Td>
								        <Td>Created Mar 4, 2023</Td>
								        <Td></Td>
								        <Td><ButtonComponent className="btn_table bg_green" name="Paid" /></Td>
								        <Td>$ 49.00 USD</Td>     
								        <Td><a href="#" className="d_flex align_center">INV-10001 <BsFillFileEarmarkArrowDownFill className="mx_5" /></a></Td>
								      </Tr>
								      <Tr>
								        <Td>Topup</Td>
								        <Td>Created Feb 3, 2023</Td>
								        <Td></Td>
								        <Td><ButtonComponent className="btn_table bg_green" name="Paid" /></Td>
								        <Td>$ 49.00 USD</Td>     
								        <Td><a href="#" className="d_flex align_center">INV-10002 <BsFillFileEarmarkArrowDownFill className="mx_5" /></a></Td>
								      </Tr>
								      <Tr>
								        <Td>Subscription</Td>
								        <Td>Created Jan 3, 2023</Td>
								        <Td><a href="#">Pay Now</a></Td>
								        <Td><ButtonComponent className="btn_table bg_red" name="Unpaid" /></Td>
								        <Td>$ 49.00 USD</Td>     
								        <Td><a href="#" className="d_flex align_center">INV-10003 <BsFillFileEarmarkArrowDownFill className="mx_5" /></a></Td>
								      </Tr>     
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>

					<div className="plan_detail_section">
						<a href="#">View all billing history</a>
					</div>
				</div>
			</div>
		</>
	);
}

export default Billing;