import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../components/ButtonComponent'
import SwitchComponent from '../../components/SwitchComponent'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { BsCheckCircleFill, BsTrash } from "react-icons/bs";
import { fetchWrapper } from '../../helpers';
import List from './Notification/List'

function NotificationSetting(){
	const [list,setList] = useState([])

	useEffect(() => {
        loadList() 
    }, []);

	  const loadList = async () => {
	  	
	      const url = `${process.env.REACT_APP_API_URL}/notification_type/list`;
	      const data  = await fetchWrapper.get(url);    
	      setList(data.rows);
	  
	     
	  }

		const notificationTypes = {
		  customer: [
		    { name: 'Orders', notification_id: 1 },
		    { name: 'Abandoned Cart Reminders', notification_id: 1 },
		    { name: 'Shipping', notification_id: 1 },
		    { name: 'Vouchers & Discount Codes', notification_id: 1 },
		    { name: 'Authentication', notification_id: 1 }
		  ],
		  staff: [
		    { name: 'Orders', notification_id: 2 },
		    { name: 'Recipients', notification_id: 2 }
		  ]
		};


	return(
		<>

			<div className="setting_detail_section">
				<h2 className="main_heading justify_content_between">
					<div>
						<h2 className="mb-5">Notifications</h2>
						<p>These are WhatsApp Utility Templates</p>
					</div>

					<div>
						<ButtonComponent className="secondary" name="Submit to WhatsApp for Approval" />
					</div>
				</h2>


				{list.map((rs,index)=>{
					const Type = rs.id === 1 ? 'customer' :'staff'
					return(
						<>
							<div className="text_section mt-20">
								<h4>{rs.notification_for}</h4>
								 <p>
								    {rs.notification_for === "Staff Notification" 
								      ? "Choose who receives order notifications. These notifications are sent on email."
								      : (
								        <>
								          WhatsApp notifications are automatically sent out to customers (if enabled). Click the notification title links below to view or edit the template. See also the help documentation for <a href="#">notification variables.</a>
								        </>
								      )}
								  </p>
							</div>

							{rs.notification_for === 'Staff Notification' ? <ButtonComponent name="Add recipient" className="secondary" /> :''}


							{notificationTypes[Type].map((name,index)=>{
								return(
									<>
										<div className="detail_card">

											<List rs={rs} type={name}/>
										</div>	
									</>
									)
							})}

							
						</>
						)
				})}

				

				


			</div>





			{/*<div className="setting_detail_section">
				<h2 className="main_heading justify_content_between">
					<div>
						<h2 className="mb-5">Notifications</h2>
						<p>These are WhatsApp Utility Templates</p>
					</div>

					<div>
						<ButtonComponent className="secondary" name="Submit to WhatsApp for Approval" />
					</div>
				</h2>




				<div className="text_section mt-20">
					<h4>Customer Notifications</h4>
					<p>WhatsApp notifications are automatically sent out to customers (if enabled). Click the notification title links below to view or edit the template. 
					See also the help documentation for <a href="#">notification variables.</a></p>
				</div>

				<div className="detail_card">
					<div className="heading_section">			
						<h2>Orders</h2>	
					</div>

					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">Order confirmation</a></Td>
								        <Td>Sent automatically to the customer after they place their order.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Order edited</a></Td>
								        <Td>Sent to the customer after their order is edited. </Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="red" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">Order cancelled</a></Td>
								        <Td>Sent automatically to the customer if their order is cancelled. </Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">Order refund</a></Td>
								        <Td>Sent automatically to the customer if their order is refunded.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr> 
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Payment error</a></Td>
								        <Td>Sent automatically to the customer if their payment can’t be processed during checkout.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Payment success</a></Td>
								        <Td>Sent automatically to the customer when their pending payment is successfully processed after they have checked out.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>    
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Abandoned Cart Reminders</h2>
					</div>
					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">First Reminder</a></Td>
								        <Td>Sent to the customer if they leave checkout before they buy the items in their cart.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Second Reminder</a></Td>
								        <Td>Sent to the customer if they leave checkout before they buy the items in their cart.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="red" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Third Reminder</a></Td>
								        <Td>Sent to the customer if they leave checkout before they buy the items in their cart.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="red" /></Td>
								      </Tr>       
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>

					<div className="plan_detail_section">
						<div className="text_section">
							<p>Configure options in <a href="#">checkout settings.</a></p>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Shipping</h2>
					</div>
					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">Shipping confirmation</a></Td>
								        <Td>Sent automatically to the customer when their order is shipped.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Shipping update</a></Td>
								        <Td>Sent automatically to the customer if their fulfilled order’s tracking number is updated.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="red" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">Out for Delivery</a></Td>
								        <Td>Sent to the customer automatically after orders with tracking information are out for delivery.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>
								        <Td><BsCheckCircleFill className="color_primary" /></Td>
								        <Td><a href="#">Delivered</a></Td>
								        <Td>Sent to the customer automatically after orders with tracking information are delivered.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>        
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Vouchers & Discount Codes</h2>
					</div>
					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">Discount Voucher</a></Td>
								        <Td>Generates and send OTP to the customer.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>        
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Authentication</h2>
					</div>
					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>
								        <Td><BsCheckCircleFill /></Td>
								        <Td><a href="#">OTP</a></Td>
								        <Td>Generates and send OTP to the customer.</Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>        
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				<div className="text_section mt-50">
					<h4>Staff Notifications</h4>
					<p className="mb-10">Choose who receives order notifications. These notifications are sent on email.</p>

					<ButtonComponent name="Add recipient" className="secondary" />
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Orders</h2>
					</div>
					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>       
								        <Td><a href="#">New order</a></Td>
								        <Td>Sent to order notification subscribers when a customer places an order.</Td>
								        <Td></Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="green" /></Td>
								      </Tr>
								      <Tr>       
								        <Td><a href="#">New return request</a></Td>
								        <Td>Sent to order notification subscribers when a customer requests a return.</Td>
								        <Td></Td>
								        <Td></Td>   
								        <Td><SwitchComponent colorScheme="red" /></Td>
								      </Tr>         
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				<div className="detail_card">
					<div className="heading_section">
						<h2>Recipients</h2>
					</div>
					<div className="plan_detail_section pt-5">
						<div className="table_section">
							<TableContainer>
							  	<Table>
								    <Tbody>
								      <Tr>       
								        <Td><a href="#">Dilawar Abbas</a></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td><ButtonComponent name="Active" className="btn_table bg_green" /></Td>
								        <Td><a href="#">Disable</a></Td>   
								        <Td><a href="#" style={{color: '#000'}}><BsTrash /></a></Td>
								      </Tr>
								      <Tr>       
								        <Td><a href="#">Altaf Siddiqui</a></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td></Td>
								        <Td><ButtonComponent name="Disabled" className="btn_table bg_red" /></Td>
								        <Td><a href="#">Active</a></Td>   
								        <Td><a href="#" style={{color: '#000'}}><BsTrash /></a></Td>
								      </Tr>         
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>
			</div>*/}
		</>
	);
}

export default NotificationSetting;