import {combineReducers} from "@reduxjs/toolkit";
import { configureStore } from '@reduxjs/toolkit';
import Reducers from "../pages/settings/store";

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { tenentReducer } from '../pages/settings/slice/tenent.slice';
import { userReducer } from '../pages/settings/slice/user.slice';
import { userTypeReducer } from '../pages/settings/slice/user.type.slice';

import { categoryReducer } from '../pages/settings/slice/category.slice';

export * from './auth.slice';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        tenent:tenentReducer,
        userType:userTypeReducer,
        category:categoryReducer,
       
    },
});
