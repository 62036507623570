import React, { useState, useEffect } from 'react';
import { InputText, Form, InputDateField, SelectField, TextAreaField, RadioComponent } from '../../../components/FormElement';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../helpers';
import ButtonComponent from '../../../components/ButtonComponent'
import { Button,Box ,HStack,Spinner } from '@chakra-ui/react'


const Create = ({ value, name ,list ,loadList ,onClose}) => {
  const [formData, setFormData] = useState({
    text:'',

  });

	const [policyList,setPolicyList]=useState([])
	const [loading,setLoading] = useState(false);
	const [selectedContent, setSelectedContent] = useState('');


  const FormSchema = Yup.object().shape({
  text: Yup.string().required(`${name} is required`)

});

  useEffect(() => {
   
    LoadPolicyList(name)
  }, [name, value]);

  	const LoadPolicyList = async(name)=>{
  	  const url = `${process.env.REACT_APP_API_URL}/policy_template/list`;
      const data  = await fetchWrapper.get(url);
			const filtered = data?.rows?.filter((rs) => {
		  return rs?.Policy_Template_Type?.name === name;
		});

      setPolicyList(filtered)
      

  	}

  	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
  			 setLoading(true)		

		   let  data = {};
		   if(name === "Refund policy"){
		   	data.refund = selectedContent
		   }
		    if(name === "Privacy policy"){
		   	data.privacy = selectedContent
		   }
		   if(name === "Terms of service"){
		   	data.terms = selectedContent
		   }
		    if(name === "Shipping policy"){
		   	data.shipping = selectedContent
		   }
		   if(name === "Contact information"){
		   	data.contact = selectedContent
		   }
		   
		   data.id = list.id
		   console.log(data)
		  


	       
	     const url = `${process.env.REACT_APP_API_URL}/policy/update`;
  		 await fetchWrapper.post(url,data)
  		 setLoading(false);

  		 loadList();
  		 onClose()
  		 toast(`Record has been saved`,{
  		 	type:'success'
  		 })

  		 
	}

	const handleChange = (e)=>{
		const item = e.target.value;
		const selectedPolicy = policyList.find((policy) => policy.id === parseInt(item));
		setSelectedContent(selectedPolicy ? selectedPolicy.content : '');
	}



  return (
  	<>
  	{loading &&
		<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
	    	<Spinner
			  thickness='4px'
			  speed='0.65s'
			  emptyColor='gray.200'
			  color='blue.500'
			  size='xl'
			/>
		</HStack>
	}
    <Form
      enableReinitialize
      validationSchema={FormSchema}
      initialValues={formData}
      onSubmit={onSubmit}
    >
      <h2 className="main_heading" style={{marginBottom:'30px'}}>{name}</h2>
      <SelectField name="text" list={policyList} onChange={handleChange} />

      {selectedContent ? (
        <Box p="4" borderWidth="1px" borderRadius="md" mt="4" bg="gray.100">
          <h5 dangerouslySetInnerHTML={{ __html: selectedContent }} />
        </Box>
      ) : (
         <Box p="4" borderWidth="1px" borderRadius="md" mt="4" bg="gray.100">
          <h5 dangerouslySetInnerHTML={{ __html: value }} />
        </Box>
      )}





      <Button 
                    type='submit'
                    className="primary"
                    mt="20px"
                  >
                   Save
                  </Button>
      
    </Form>
    </>
  );
};

export default Create;
