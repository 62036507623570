import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../../../helpers';

// create slice

const name = 'category';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const categoryActions = { ...slice.actions, ...extraActions };
export const categoryReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        
        listing: null,
        category:{},
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/whats-app-category`;

    return {
        getAll: getAll()
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(baseUrl)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.category = { loading: true };
            },
            [fulfilled]: (state, action) => {
                
                state.listing = action.payload;
            },
            [rejected]: (state, action) => {
                state.category = { error: action.error };
            }
        };
    }
}