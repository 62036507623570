import React,{useEffect,useState} from 'react';



import { BsCurrencyDollar, BsFillCaretDownFill, BsTriangle, BsFillPersonFill } from "react-icons/bs";



import Settings from './whatsappsetting/Settings'

function WhatsappSetting(){

	const [viewPage, setViewPage] = useState('');

	

	
	
	 useEffect(() => {
       
       
        getPage(<Settings getPage={getPage}  />);
        
    }, []);


	
	


	const getPage = (pageName) => {
    	
	     setViewPage(pageName)
	     
	  }


	return(
		<>
			{viewPage}
		</>
	);
}

export default WhatsappSetting;