import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import React, { useMemo, useEffect } from "react";
import {Link,Box,HStack,CloseButton } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
let temp = [];
const Variants = () => {
  const { register, setValue, getValues } = useFormContext();
  const watchOptions = useWatch({ name: "options" });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: "variants"
    }
  );

  const watchVariants = useWatch({ name: "variants", defaultValue: fields });

  const cartesianIdx2 = (a) => {
    // a = array of array
    var i,
      j,
      l,
      m,
      a1,
      o = [];
    if (!a || a.length == 0) return a;

    a1 = a.splice(0, 1)[0]; // the first array of a
    a = cartesianIdx2(a);
    for (i = 0, l = a1.length; i < l; i++) {
      if (a && a.length)
        for (j = 0, m = a.length; j < m; j++) o.push([i].concat(j));
      else o.push([i]);
    }
    return o;
  };

  const variantsIdxs = useMemo(() => {
    const formattedOptions = watchOptions.map((option) =>
      option.values.filter((value) => !!value.value).map((value) => value.value)
    );
    return cartesianIdx2(formattedOptions.filter((opt) => !!opt.length));
  }, [watchOptions]);

  const isArrayEquals = (arr1, arr2) => {
    return (
      arr1.filter((i, n) => arr2[n] === i).length === arr1.length &&
      arr2.filter((i, n) => arr1[n] === i).length === arr2.length
    );
  };

  const findExistingVariant = (variant, ttttt) => {
    console.log({ ttttt });
    return ttttt.find((v) => {
      if (v.key.length < variant.length) {
        return v.key.every((k, i) => {
          const res = variant.includes(k);
          const idx = variant.findIndex((v) => v === k);
          if (res && idx === i) return true;
          return false;
        });
      }
      if (v.key.length === variant.length) {
        // const variantNames = getVariantsName(v.key);
        // if (variantNames.length !== v.key.length) return false;'
        const result = isArrayEquals(v.key, variant);
        console.log(v.key, variant, result);
        return result;
      }
    });
  };

  const getVariantsName = (variantIdxs) => {
    const nameArray = [];
    variantIdxs.forEach((variantIdx, idx) => {
      nameArray.push(watchOptions[idx].values[variantIdx].value);
    });
    return nameArray.join(" / ");
  };

  useEffect(() => {
    const variantsValue = [];
   
    temp = watchVariants;
    if (variantsIdxs.length <= watchVariants.length) {
      variantsIdxs.forEach((i, j) => {
       
        if (temp[j]) temp[j].key = i;
      });
    }

    variantsIdxs.forEach((variantIdxs) => {
      if (!variantIdxs.length) return;
      const existingVariant = findExistingVariant(variantIdxs, temp);
      variantsValue.push({
        key: variantIdxs,
        variant: getVariantsName(variantIdxs),
        price: existingVariant?.price || 0,
        compareAtPrice: existingVariant?.compareAtPrice || 0,
        quantity: existingVariant?.quantity || 0,
        sku: existingVariant?.sku || 0,
        barcode: existingVariant?.barcode || 0,
        image: existingVariant?.image || 0
      });
    });

    setValue("variants", variantsValue);
  }, [variantsIdxs, setValue]);
  return (
    <div>
      <label>Product Variants</label>

      <TableContainer>
          <Table>
                <Thead>
                  <Tr>
                    <Th>Variants</Th>
                    <Th>Price</Th>
                    <Th>Inventory</Th>
                    
                    <Th>SKU</Th>
                    <Th>Barcode</Th>
                    
                  </Tr>
                </Thead>
                <Tbody>
                {fields.map((field, index) => {
                  return (
                        <Tr key={index}>
                          <Td>{field.variant}</Td>
                          <Td>
                          <input
                              style={{border:'1px solid #ccc',width:'100%',padding:'5px',borderRadius:'6px',marginRight: "25px"
                       ,marginBottom:'5px'}}
                              {...register(`variants[${index}].price`)}
                            />
                          </Td>
                          <Td>
                              <input
                                 style={{border:'1px solid #ccc',width:'100%',padding:'5px',borderRadius:'6px',marginRight: "25px"
                       ,marginBottom:'5px'}}
                                {...register(`variants[${index}].quantity`)}
                              />

                          </Td> 

                          <Td>
                              <input
                                 style={{border:'1px solid #ccc',width:'100%',padding:'5px',borderRadius:'6px',marginRight: "25px"
                       ,marginBottom:'5px'}}
                                {...register(`variants[${index}].sku`)}
                              />

                          </Td> 

                          <Td>
                              <input
                                 style={{border:'1px solid #ccc',width:'100%',padding:'5px',borderRadius:'6px',marginRight: "25px"
                       ,marginBottom:'5px'}}
                                {...register(`variants[${index}].barcode`)}
                              />

                          </Td> 

                        </Tr>

                    );
                  })}

                </Tbody>
          </Table>
      </TableContainer>

     
    </div>
  );
};

export default Variants;
