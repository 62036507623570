import React from 'react';

import {
  
  TextareaControl,
  
} from "formik-chakra-ui";
function TextAreaField(props) {
	const {name,title, ...rest} = props
	const {rows} = props
	
	return(
		<>
			<div className="field_section mb-10">
				<TextareaControl name={name} label={title} />
				
			</div>
			
		</>
	);
}

export default TextAreaField;