import React,{useEffect,useState} from 'react';
import Header from '../../components/Header'
import Navbar from '../../components/Navbar'
import List from './product/List'
import Create from './product/Create';
import Edit from './product/Edit';
function Product(){
	const [viewPage, setViewPage] = useState('');


	useEffect(() => {
       
       
        getPage('list');
        
    }, []);

	const getPage = (pageName,rs={}) => {
    	if(pageName == 'list'){
	     		setViewPage(<List random={Math.random()} getPage={getPage} />)
    	}

    	if(pageName == 'create'){
	     		setViewPage(<Create getPage={getPage} />)
    	}


    	if(pageName == 'edit'){
	     		setViewPage(<Edit getPage={getPage} rs={rs} />)
    	}
	     
	  }

	return (
			<>
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="page-section">
					{viewPage}
				</div>
			</div>

			
			
		</>

		)
}
export default Product;