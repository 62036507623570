import React,{useEffect,useState} from 'react';
import ButtonComponent from '../../../components/ButtonComponent'
import SwitchComponent from '../../../components/SwitchComponent'
import {Link} from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
 	useDisclosure,
 	Spinner,HStack
} from '@chakra-ui/react'
import { BsCheckCircleFill } from "react-icons/bs";
import { fetchWrapper } from '../../../helpers';
const List = ({myid}) => {

	const [loading,setLoading] = useState(false);

	const [list,setList] = useState([])
  useEffect(() => {
        loadList() 
    }, [myid]);

  const loadList = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/template/list-by-category?category_id=${myid}`;
      const data  = await fetchWrapper.get(url);
      setList(data.data);
  
      setLoading(false);
  }


	return (

			<>
			{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<div className="table_section">
									<TableContainer>
									  	<Table>
										    <Tbody>
										    {list?.map((rs,index) => {

	    									return(


										      <Tr>
										        <Td><BsCheckCircleFill className="color_primary" /></Td>
										        <Td><Link to={`/setting/template-detail/${rs.id}`} state={{id:rs.id}}>{rs.name}</Link></Td>
										        <Td>Description Goes Here</Td>
										        <Td></Td>     
										        <Td><SwitchComponent colorScheme="green" /></Td>
										      </Tr>
										     
													  )
											})}
										    </Tbody>
										  </Table>
									</TableContainer>
								</div>
			</>
		)

}

export default List