import React from 'react';
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import Accordion from 'react-bootstrap/Accordion';
import dashboard_order_icon from '../assets/images/dashboard_order_icon.png'
import { FaAngleRight, FaFacebookF, FaWhatsapp } from "react-icons/fa";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button,HStack,Box,Heading,CloseButton,Checkbox,Badge } from '@chakra-ui/react';

function Dashboard() {
	
	return(
		<>
			<Header />

			<div className="body_section d_flex">
				<Navbar />

				<div className="dashboard_page">
					<div className="dashboard_section">
						<div className="orders_box">
							<div className="text_section">
								<div className="img_box">
								 	<img src={dashboard_order_icon} />
								</div>
								 <p><b>1 order</b> to fulfill</p>
							</div>

							<div className="icon_section">
								<FaAngleRight />
							</div>
						</div>

						<div className="whatsapp_store_card">
							<div className="text_section">
								<h3>WhatsApp Store</h3>

								<p>Your WhatsApp Store is not public. Your customers will not be able to place orders until you will set your store live.</p>
							</div>

							<div>
								<Button className="btn_whatsapp_store">Take my WhatsApp Store Live</Button>
							</div>
						</div>

						<div className="accordion_section">
							<Accordion defaultActiveKey="0">
						      <Accordion.Item eventKey="0">
						        <Accordion.Header>
						        	<div className="accordion_head_section">
						        		<h3>Setup guide</h3>

						        		<p>Use this personalized guide to get your store up and running.</p>

						        		<div className="progress_section">
						        			<p>1 of 5 tasks complete</p>

						        			<div className="dashboard_progress_section">
						        				<ProgressBar now={40} />
						        			</div>
						        		</div>
						        	</div>
						        </Accordion.Header>
						        <Accordion.Body>
						          <div>
						          	<div className="para_section">
						          		<div className="radio_btn_section">
						          			<label class="radio_custom_section">
											  <input type="radio" name="radio" />
											  <span class="checkmark"></span>
											</label>
						          		</div>

						          		<div className="text_section">
						          			<p>Add your first product</p>
						          			<a href="#">Learn how to Add Product</a>
						          		</div>
						          	</div>

						          	<div className="para_setup_section">
						          		<div className="radio_btn_section">
						          			<label class="radio_custom_section">
											  <input type="radio" name="radio" />
											  <span class="checkmark"></span>
											</label>
						          		</div>

						          		<div className="text_section">
						          			<h4>Connect your Facebook Catalog</h4>
						          			<p>Maximize your business potential on WhatsApp! Connect your Facebook  Catalog for instant product visibility. Elevate your WhatsApp Commerce  experience, effortlessly reaching customers and driving sales.</p>
						          			<a href="#">Learn how to Connect your Facebook Catalog</a>
						          		</div>

						          		<div className="btn_section">
						          			<Button className="btn_facebook"><FaFacebookF /> Connect your Facebook Catalog</Button>
						          		</div>
						          	</div>

						          	<div className="para_setup_section">
						          		<div className="radio_btn_section">
						          			<label class="radio_custom_section">
											  <input type="radio" name="radio" />
											  <span class="checkmark"></span>
											</label>
						          		</div>

						          		<div className="text_section">
						          			<h4>Connect your WhatsApp Business</h4>
						          			<p>Revolutionize your business on WhatsApp! Seamlessly connect your  WhatsApp Business for a dynamic commerce experience. Boost sales, engage  customers, and elevate your brand presence effortlessly.</p>
						          			<a href="#">Learn how to Connect your WhatsApp Business</a>
						          		</div>

						          		<div className="btn_section">
						          			<Button className="btn_whatsapp"><FaWhatsapp /> Connect your WhatsApp Business</Button>
						          		</div>
						          	</div>
						          </div>
						        </Accordion.Body>
						      </Accordion.Item>
						    </Accordion>
						</div>

						<div className="setup_whatsapp_section">
							<div className="accordion_head_section">
				        		<h3>Setup your WhatsApp Store</h3>

				        		<p>Use this personalized guide to get your store up and running.</p>

				        		<div className="progress_section">
				        			<p>1 of 6 tasks complete</p>

				        			<div className="dashboard_progress_section">
				        				<ProgressBar now={40} />
				        			</div>
				        		</div>
				        	</div>

				        	<div className="accordion_body_section">
				        		<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup WhatsApp Business Profile</h4>
					          			<p>Configure your WhatsApp Business Profile appearance. </p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>

					          	<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup FAQ’s about your Business </h4>
					          			<p>Train the bot about your business queries.</p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>

					          	<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup WhatsApp Templates</h4>
					          			<p>Small description goes here</p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>

					          	<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup WhatsApp Notifications</h4>
					          			<p>Small description goes here</p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>

					          	<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup Abandoned Cart Reminders</h4>
					          			<p>Small description goes here</p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>

					          	<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup Payment Methods</h4>
					          			<p>Small description goes here</p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>

					          	<div className="para_setup_section">
					          		<div className="radio_btn_section">
					          			<label class="radio_custom_section">
										  <input type="radio" name="radio" />
										  <span class="checkmark"></span>
										</label>
					          		</div>

					          		<div className="text_section">
					          			<h4>Setup Shipping Methods</h4>
					          			<p>Small description goes here</p>
					          		</div>

					          		<div className="btn_section">
					          			<Button className="btn_whatsapp">Setup</Button>
					          		</div>
					          	</div>
				        	</div>
						</div>
					</div>
				</div>
			</div>

			
			
		</>
	);
}

export default Dashboard;