import React,{useEffect,useState} from 'react';
import { FaArrowLeft, FaRegClone } from "react-icons/fa";
import { Button,HStack,Box,Heading,Text,Checkbox } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,Image
} from '@chakra-ui/react'
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import product_shirt_image from '../../../assets/images/product_shirt_img.png'
import { fetchWrapper } from '../../../helpers';
import moment from 'moment';
const Detail = ({order_id,getPage}) => {
	const [loading,setLoading] = useState(false);
	const [orderData,setOrderData] = useState({})
	const [orderItem,setOrderItem] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async (name ="") => {
  	setLoading(true)
  		const url = `${process.env.REACT_APP_API_URL}/order/by-id?id=${order_id}`;
  		const data  = await fetchWrapper.get(url)
  		setOrderData(data.data)
  		setOrderItem(data.item)
  		console.log(data.item)
  		setLoading(false)
  }

	return (
			<>
				<Box className="order_detail_page">
					<HStack className="head_section">
						<Box className="arrow_section">
							<FaArrowLeft />
						</Box>

						<Box className="text_section">
							<HStack mb="15px">
								<Heading>#{orderData.id}</Heading>

								<HStack className="badges_section">
									<Box className="badge_box">Payment pending</Box>
									<Box className="badge_box badge_yellow">Unfulfilled</Box>
								</HStack>
							</HStack>

							<Text>{moment(orderData.created_at).format('DD MMMM, YYYY h:mm A')}</Text>
						</Box>
					</HStack>

					<HStack className="data_section">
						<Box w="68%" me="10px">
							<Box className="items_data_section">
								<Heading>Items</Heading>
							
								<TableContainer>
									<Table>
										<Tbody>
										{orderItem.map((rs,index) => {


												return (


														<Tr>
														<Td><Box className="img_box"><Image src={rs?.Product?.image_url} /></Box></Td>
														<Td><Link>{rs?.Product?.name}</Link></Td>
														<Td>Rs {rs.price} x {rs.qty}</Td>
														<Td>Rs {rs.qty * rs.price}</Td>
													</Tr>


													)


										})}
											

											
										</Tbody>
									</Table>
								</TableContainer>

								<Box textAlign="end">
									<Button className="primary">Fulfill Items</Button>
								</Box>
							</Box>

							<Box className="items_data_section">
								<Heading>Payment</Heading>

								<HStack justifyContent="space-between">
									<Box>
										<Text>Sub Total</Text>
									</Box>

									<Box>
										<Text>1 Item</Text>
									</Box>

									<Box>
										<Text>Rs 1,500</Text>
									</Box>
								</HStack>

								<TableContainer>
									<Table>
										<Tbody>
											<Tr>
												<Td><b>Total</b></Td>
												<Td><b>Rs 1,500</b></Td>
											</Tr>

											<Tr>
												<Td>Paid by Customer</Td>
												<Td>Rs 0.00</Td>
											</Tr>
										</Tbody>
									</Table>
								</TableContainer>

								<HStack justifyContent="end">
									<Button className="btn_fulfill">Fulfill Items</Button>

									<Button className="primary">Collect Payment</Button>
								</HStack>
							</Box>
						</Box>

						<Box w="32%" className="right_section">
							<Box className="data_section">
								<Box className="customer_data_section">
									<Heading>Customer</Heading>

									<Box>
										<Text><Link>{orderData?.Customer?.name}</Link></Text>
										<Text>1 order</Text>
									</Box>
								</Box>

								<Box className="customer_data_section">
									<HStack alignItems="flex-start" justifyContent="space-between">
										<Box>
											<Heading>Customer</Heading>

											<Text><Link>{orderData?.Customer?.phone}</Link></Text>
										</Box>

										<Box>
											<Text><Link>Edit</Link></Text>

											<FaRegClone style={{marginTop: 10}} />
										</Box>
									</HStack>	
								</Box>

								<Box className="customer_data_section">
									<HStack alignItems="flex-start" justifyContent="space-between">
										<Box>
											<Heading>Shipping Address</Heading>

											<Text>{orderData?.Customer?.name}</Text>
											<Text>{orderData?.Customer?.company_name}</Text>
											<Text>{orderData?.Customer?.address}</Text>
											
											<Text>{orderData?.Customer?.email}</Text>
										</Box>

										<Box>
											<Text><Link>Edit</Link></Text>

											<FaRegClone style={{marginTop: 10}} />
										</Box>
									</HStack>
								</Box>
							</Box>
						</Box>
					</HStack>
				</Box>
			</>
			
		)
}

export default Detail;