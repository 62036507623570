import React, {Fragment, useEffect, useRef, useState} from 'react';

import { fetchWrapper } from '../helpers';




import IconButton from "../assets/images/visa.png";
import $ from "jquery";
import CancelIcon from "../assets/images/visa.png";



let defaultState = {
    messages: [],
    data: [],
    loopBackTrigger: {},
    selected_id: "",
    currentFallbackResponse: "",
    
}

let trigger = {};
let updatedTriggers = [];

const Chat = ({onClose}) => {
    const [formKey, setFormKey] = useState(0);
    const [init, setInit] = useState(defaultState);
    let {messages, data, loopBackTrigger} = init;
    const msgArea = useRef(null);
     const [formData, setFormData] = useState([]);
     const [formDataSave, setFormDataSave] = useState([]);


     const [checkOutFormTrigger,setCheckOutFormTrigger] = useState(false);


    const [formCheckoutKey, setFormCheckoutKey] = useState(0);
     const [formCheckoutData, setFormCheckoutData] = useState([]);
     const [formCheckoutDataSave, setFormCheckoutDataSave] = useState([]);

    useEffect(()=>{
        loadSettings()
    },[])

    const loadSettings = async() => {
        const url = `${process.env.REACT_APP_API_URL}/tenent/by-id?id=1`;
        const data  = await fetchWrapper.get(url)
        const checkoutData = JSON.parse(data.data.settings).checkout;
        console.log(checkoutData)
        setFormCheckoutData(checkoutData)
    }

    useEffect(() => {
        setInit({
                    ...init,
                   
                })
    }, [messages]);


    const chatform = async (text) => {


        if(formKey == 0){
            const url = `http://localhost:3006/chat`;
            const apiData  = await fetchWrapper.post(url,{text:text})

                if(apiData.form){
                    setFormData(apiData.form);
                   
                    trigger = await startForm(data[0],apiData.form)
                }else{
                    trigger = {
                    id: createGuid(),
                    response: apiData.message,
                    type:"TEXT",
                   
                 }
            }

           // setFormDataSave([])
        }else{

            

            trigger = await startForm(data[0],formData,text);
            
             

             
        }

       
        
        setInit({
                ...init,
                messages: !$.isEmptyObject(trigger) ? [...init.messages, {
                    text: text,
                    id: "me"
                }, trigger] : [...init.messages, {text: text, id: "me"}],
                data:data
            });
        
        scrollOnMessage()
    }
    
    const checkOutCall = (text) => {
            //console.log(formCheckoutData.length)
            if(text){
                setFormCheckoutDataSave([...formCheckoutDataSave,text])
            }
          
            if(formCheckoutData.length !== formCheckoutKey){
           
                       
                    
                        
                            let formMenu = [];
                            
                            trigger = {
                                    id: createGuid(),
                                    response: 'Enter ' + formCheckoutData[formCheckoutKey].label,

                                    type:"TEXT",
                                    mode:"save",
                                    data:data
                                 }


                                

                           
                                let newKey = Math.abs(formCheckoutKey) + 1;
                                setFormCheckoutKey(newKey)
             }else{
                        
                       

                        


                                trigger = {
                                    id: createGuid(),
                                    response: "Thank you for purchasing",

                                    type:"TEXT",

                                    
                                 }
                  
                        //setFormCheckoutDataSave([]);
                        setFormCheckoutKey(0)
                        setCheckOutFormTrigger(false)
                            
             }
                           
         return trigger;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let text = msgArea.current.value;

        if(checkOutFormTrigger){
            
             const trigger = checkOutCall(text)
             setInit({
                ...init,
                messages: !$.isEmptyObject(trigger) ? [...init.messages, {
                    text: text,
                    id: "me"
                }, trigger] : [...init.messages, {text: text, id: "me"}],
                data:data
            });
        
        scrollOnMessage()
        }else{

            chatform(text)
        }
       
        

        
    }

    const meAddToCard = async (item) => {

        const cart_data = [{
            product_id:item.product_id,
            product_name:item.name,
            varient:item.varient,
            price:item.price,
            qty:1,
        }]
        const url = `http://localhost:4051/cart/add-product`;
        const data = {bot_user_id:1,cart_data:cart_data}
        const apiData  = await fetchWrapper.post(url,data)

        trigger = {
                                    id: createGuid(),
                                    response: "Product Added to cart",

                                    type:"CART",

                                    
                                 }


        setInit({
                                            ...init,
                                            messages: !$.isEmptyObject(trigger) ? [...init.messages, {
                                                text: "",
                                                id: "me"
                                            }, trigger] : [...init.messages, {text: "", id: "me"}],
                                            data:data
                                        });
        
        scrollOnMessage()
        
    }

     const startForm = async (data,xformdata,text) => {
            if(text){
                setFormDataSave([...formDataSave,text])
            }
          
            if(xformdata.length !== formKey){
           
                       
                    
                        
                            let formMenu = [];
                            
                            trigger = {
                                    id: createGuid(),
                                    response: 'Enter ' + xformdata[formKey],

                                    type:"TEXT",
                                    mode:"save",
                                    data:data
                                 }


                                

                           
                                let newKey = Math.abs(formKey) + 1;
                                setFormKey(newKey)
             }else{
                        
                       

                        const url2 = `${process.env.REACT_APP_API_URL}/product/search-product`;
                        const apiData2  =  await fetchWrapper.post(url2,{varient:JSON.stringify(formDataSave)})


                                trigger = {
                                    id: createGuid(),
                                    response: apiData2.data,

                                    type:"list",

                                    
                                 }
                  
                        setFormDataSave([]);
                        setFormKey(0)
                            
             }
                           
         return trigger;

    }

    

    const S4 = () =>  {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    const createGuid = () => {
    

        return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }
  
    const scrollOnMessage = () => {
        let container = $(".conversation-container")
        container.animate({
            scrollTop: container.offset().top + (container.scrollTop() + 300)
        }, 300)
    }
    const checkout = () => {
        
        setCheckOutFormTrigger(true);
        trigger = {
                                    id: createGuid(),
                                    response: "Do you want to Proceed to checkout?",

                                    type:"TEXT",

                                    
                                 }


        setInit({
                                            ...init,
                                            messages: !$.isEmptyObject(trigger) ? [...init.messages, {
                                                text: "",
                                                id: "me"
                                            }, trigger] : [...init.messages, {text: "", id: "me"}],
                                            data:data
                                        });
        
        scrollOnMessage()

    }

    const getContentByType = (data, index) => {
            
            switch (data.type) {
                case "TEXT":
                    let str = $.parseHTML(data.response)
                    $(`#${data.id}--${index}`).html(str);

                    return (
                        <div className="sub-txt" id={`${data.id}--${index}`}/>
                    );


               case "CART":
                    let strCart = $.parseHTML(data.response)
                    //$(`#${data.id}--${index}`).html(strCart);

                    return (
                        <div className="sub-txt" id={`${data.id}--${index}`}>
                                Your product has been added to cart successfully
                                <br />
                                Cart Total: 5000
                                <br /><br />
                                <button onClick={() => checkout()} className="btnCustom">Checkout</button>
                                <br />
                                <button  className="btnCustom">Continue Shopping</button>
                        </div>
                    );

                case "list":


                    
                    

                    return (

                        data.response.map((item,indexProduct) => {
                            return <div className="sub-txt" id={`${data.id}--${indexProduct}`}>
                                <img src={`https://dummyimage.com/150x100/000/fff&text=${item.name}`} />
                                <br />
                                {item.name}
                                <br />
                                Price: {item.price}
                                <br />
                                <button onClick={() => meAddToCard(item)}  className="btnCustom">Add to cart</button>
                                <hr />
                            </div>
                        })
                        
                    );
                
                default:
            }
        
    }

    const currentTime = (date) =>  {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    return (
    <div class="bg-front">

        <div class="customabs_sec">
                                                                <div onClick={() => onClose()}>
                                                                    <div fontSize="large" style={{color: "black"}}/>
                                                                </div>
                                                            </div>

         <div class="mobWraper">
            <div id="mockChat" class="mockchat">
              <div class="device">
                <div class="screen">
                    <div class="whatsapp-preview">
                      <div class="app1" style={{height:'100%'}} >
                        <div class="page">
                          <div class="marvel-device nexus5">
                            <div class="screen" style={{width:'100%',height:'100%'}} >

                            

                              <div class="screen-container">
                                <div class="chat-window" >
                                  <div class="chat-container">
                                    <div class="user-bar">
                                      <div class="user_topTxt">
                                            <div>
                                                <span class="time" >12:00</span>
                                            </div>
                                            <div>
                                            <span style={{fontSize:10,margin:'3px'}} ><i class="fas fa-signal"></i></span>
                                                <span style={{fontSize:10,margin:'3px'}}><i class="fas fa-wifi"></i></span>
                                            <span style={{fontSize:10,margin:'3px'}}><i class="fas fa-battery-full"></i></span>
                                            </div>
                                        </div>
                                      <div class="user_midBar">
                                          <div class="mob_topBar">
                                          <div class="avatar">
                                         
                                            
                                          </div>
                                          <div class="name">
                                            <span>Chatbot</span>
                                            <span class="status">online</span>
                                          </div>
                                          
                                        </div>
                                        <div class="mob_topIcon">
                                            <span style={{margin:'0px 10px 0px 0px'}}><i class="fas fa-video"></i></span>
                                            <span><i class="fas fa-phone" style={{transform:'rotate(104deg)'}} ></i></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="conversation">
                                      <div class="conversation-container">
                                        <div class="messages sent" style={{wordBreak:'word-break',flexDirection:'column'}} >
                                          <div >
                                            
                                                <div className="chat-bot-container">
                                                            <div className="content-hld">

                                                            

                                                                <div className="section">
                                                                    <div className="chat-body hide-scroll">
                                                                        {
                                                                            messages.length > 0 ? (
                                                                                messages.map((m, index) => {
                                                                                    return (
                                                                                        m.id === "me" ?
                                                                                            <div className="msg-row me">
                                                                                                <div className="msg-box me">
                                                                                                    <div className="msg">{m.text}</div>
                                                                                                    <div className="sub-text">
                                                                                                        {currentTime(new Date())}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> :
                                                                                            (
                                                                                                <div className="msg-row">
                                                                                                    <div className="msg-box frm">
                                                                                                        <div className="msg">

                                                                                                            {m.isFallBack === undefined && m.name}
                                                                                                            {getContentByType(m, index)}
                                                                                                        </div>
                                                                                                        <div className="opt-hld">
                                                                                                            {m.isFallBack === undefined &&
                                                                                                            m.menus?.map((menu) => {
                                                                                                                return (
                                                                                                                    <Fragment>
                                                                                                                        <button
                                                                                                                            key={menu.toTriggerId}
                                                                                                                            className="btn light-filled">{menu.text}
                                                                                                                        </button>
                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            })
                                                                                                            }
                                                                                                        </div>
                                                                                                        <div className="sub-text">
                                                                                                            {currentTime(new Date())}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                    )
                                                                                })
                                                                            ) : <div className="no-data-found">No Data Found</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                </div>

                                        </div>
                                      </div>
                                      <div class="mobBottom_sec">
                                        <div class="conversation-compose">
                                          <div class="emoji">
                                            <i class="far fa-smile"></i>
                                          </div>

                                            <div className="msg-send-composer">
                        
                                                <div className="txt-area">
                                                    <form className="" onSubmit={handleSubmit}>
                                                        <input className="input-msg" ref={msgArea} type="text" placeholder={"Type a message"}/>
                                                    </form>
                                                </div>
                        
                                            </div>

                                         
                                          <div class="photo">
                                            <i class="fas fa-camera"></i>
                                          </div>
                                          <button class="send">
                                            <div class="circle">
                                              <i class="fas fa-paper-plane"></i>
                                            </div>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

       
        </div>
    );
};

export default Chat;