import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { BsThreeDots } from "react-icons/bs";
import CheckBox from '../../../components/CheckBox'
import RadioComponent from '../../../components/RadioComponent'
import EditBrand from './EditBrand';
import CustomModalLink from '../../../components/FormElement/CustomModalLink'
import { toast } from "react-toastify";

import { fetchWrapper } from '../../../helpers';

function Brand(){

	const [list,setList] = useState([])
	useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/brand/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  }
	const saveProduct = () => {
			
	}

	const [formData, setFormData] = useState({
    name: ''
  });

  const FormSchema = Yup.object().shape({
	    name: Yup.string()
	          .required('Required')
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/brand/create`;
  		 await fetchWrapper.post(url,data)
  		 loadList();
  		 resetForm();
  		  toast(`Record has been Saved`,{
  		 	type:'success'
  		 })
	}

	const meDelete = async(id)=>{
		const aa = window.confirm("Are you sure delete this record");
		if(aa){
			const url = `${process.env.REACT_APP_API_URL}/brand/delete/${id}`;
			await fetchWrapper.delete(url)	
			loadList()
		}
		
	}

	return(
		<>
			
<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			          >
				<div className="detail_card">
					<div className="heading_section">			
						<h2>Brand</h2>	
					</div>

					<div className="plan_detail_section">
						<div className="d_flex">
							<div className="me-10" style={{ width: '60%' }}>
								
								<InputText name="name"  />
							</div>

							<div> 
								
								<Button
				            colorScheme='teal'
				            border='1px'
			              borderColor='#047E60'
			              bg="#047E60"
			              px="20px"
			              py="10px"
			              color="#fff"
			              fontSize="14px"
			              fontFamily='Open Sans, sans-serif'
				            type='submit'
		          		>
		           		 Add
		          		</Button>

							</div>
						</div>

						<div className="table_section">
							<TableContainer>
							  	<Table>
							  		<Thead>
							  			<Tr>
							  				<Th>Brand</Th>
							  				<Th></Th>
							  				<Th></Th>
							  				<Th></Th>
							  			</Tr>
							  		</Thead>
								    <Tbody>
								    {list?.map(rs => {

								    	return (
								      <Tr key={rs.id}>     
								        <Td>{rs.name}</Td>
								        <Td></Td>
								        <Td></Td>      
								        <Td dir="rtl" cursor="pointer">
								        <div class="dropdown">
												  <a class="dropdown-toggle" data-bs-toggle="dropdown">
												    <BsThreeDots />
												  </a>
												  <ul class="dropdown-menu">
												    <li><a class="dropdown-item" href="#"><CustomModalLink
													        showModalButtonText='Edit'
													        modalHeader="Edit Brand"
													        modalBody=<EditBrand loadList={loadList} rs={rs}/>
													      /></a></li>
												    <li><a class="dropdown-item" onClick={()=> meDelete(rs.id)} >Delete</a></li>
												  </ul>
												</div>
											</Td>
								      </Tr>
								       )
								    }
								    	)}
								    </Tbody>
								  </Table>
							</TableContainer>
						</div>
					</div>
				</div>

				

			</Form>
			
		</>
	);
}

export default Brand;