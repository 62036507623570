import React,{useEffect,useState} from 'react';
import { BsArrowLeftSquare, BsShop } from "react-icons/bs";
import SelectField from '../../components/FormElement/SelectField'
import { useSelector, useDispatch } from 'react-redux';
import { fetchWrapper } from '../../helpers';
import { Form } from "../../components/FormElement/Form.js";
import Profile from './storedetail/Profile.js';
import BillingInfo from './storedetail/BillingInfo.js';
import CustomModalLink from '../../components/FormElement/CustomModalLink'
import { Button ,HStack,Spinner } from '@chakra-ui/react'
import * as Yup from "yup";
import { toast } from "react-toastify";

function StoreDetail(){
	const [loading,setLoading] = useState(false);
	const [tenent,setTenent] = useState({})
	const [billingData,setBillingData] = useState({})
	const [otherSetting,setOtherSetting] = useState({})
	const [unitList,setUnitList] = useState([])
	const [currencyList,setCurrencyList] = useState([])
	const [timezoneList,setTimezoneList] = useState([])
	const [weightList,setWeightList] = useState([])
	const [list,setList] = useState([])
	
	 useEffect(() => {
        loadData();
        loadUnitList();
        loadCurrencyList();
        loadTimeZoneList();
        loadWeightList();
        
        
    }, []);

	   const [formData, setFormData] = useState({
	    unit: "",
	    currency:"",
	    timezone:"",
	    weightunit:"",
	     
  		});

	  	const FormSchema = Yup.object().shape({
	    	unit: Yup.string().required("Required"),
	    	currency: Yup.string().required("Required"),
	    	timezone: Yup.string().required("Required"),
	    	weightunit: Yup.string().required("Required"),
	    	

	    });

	const loadData = async () => {
		setLoading(true) 
  		const url = `${process.env.REACT_APP_API_URL}/tenent/by-id`;
  		const data  = await fetchWrapper.get(url)
  		setTenent(data.data)

  		const tenentData = data.data
  		const settingsData = JSON.parse(tenentData.settings)
  		const billingDataValue = settingsData['billing_info'];
  		setBillingData(billingDataValue)

  		const otherSettingDataValue = settingsData['other_setting'];

  		setOtherSetting(otherSettingDataValue)
  		console.log(settingsData)
  		setFormData({
	    unit: otherSettingDataValue?.unit,
	    currency:otherSettingDataValue?.currency,
	    timezone:otherSettingDataValue?.timezone,
	    weightunit:otherSettingDataValue?.weightunit,
	     
  		})
  		setLoading(false) 
  	}

	  const loadUnitList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/unit/list`;
  		const data  = await fetchWrapper.get(url)
  		
  		setUnitList(data.data)
	  }
    const loadCurrencyList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/currency/list`;
  		const data  = await fetchWrapper.get(url)
 
  		setCurrencyList(data.data)
  	}
	const loadTimeZoneList = async () => {
		const url = `${process.env.REACT_APP_API_URL}/timezone/list`;
		const data  = await fetchWrapper.get(url)

		setTimezoneList(data.data)
	}
	const loadWeightList = async () => {
		const url = `${process.env.REACT_APP_API_URL}/weight/list`;
		const data  = await fetchWrapper.get(url)

		setWeightList(data.data)
	}
	const loadList = async () => {
  		const url = `${process.env.REACT_APP_API_URL}/attribute/list`;
  		const data  = await fetchWrapper.get(url)
  		setList(data.data)
  }

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		 setLoading(true) 
		let data = {}
		   data.type = 'other_setting'
		   data.setting_data = values;
		  
	     const url = `${process.env.REACT_APP_API_URL}/tenent/settings`;
  		 await fetchWrapper.post(url,data)
  		 setLoading(false);
  		 toast(`Record has been update`,{
  		 	type:'success'
  		 })
	}
	
	return(
		<>
			{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<div className="setting_detail_section">
			<Form
				 enableReinitialize
		        initialValues={formData}
		        validationSchema={FormSchema}
		         onSubmit={onSubmit}
			>
							<h2 className="main_heading">Store details</h2>
						
							<Profile />

							<div className="detail_card">
								<div className="heading_section">
									<div className="d_flex justify_content_between">
										<h2>Billing Information</h2>

										

										<CustomModalLink
									        showModalButtonText="Edit"
									        modalHeader="Edit"
									        modalBody=<BillingInfo billingData={billingData} loadList={loadList} />
									      />
									</div>
								</div>

								<div className="detail_section">
									<div className="text_section">
										<p>Business address</p>
										<h5>{billingData?.address}</h5>
									</div>
								</div>
							</div>

							<div className="detail_card">
								<div className="heading_section">
									<h2>Store Currency</h2>
									<p>The currency your products are sold in.</p>
								</div>

								<div className="detail_section">
									<SelectField list={currencyList} name="currency"/>
								</div>
							</div>

							<div className="detail_card">
								<div className="heading_section">
									<h2>Time zone and units of measurement</h2>
									<p>Used to calculate product prices, shipping weights, and order times.</p>
								</div>

								<div className="detail_section">
									<div style={{ width: '60%' }}>
										<SelectField list={timezoneList} name="timezone" title="Time Zone" />

										<div className="d_flex">
											<div style={{ width: '50%',marginRight: '25px' }}>
												 <SelectField list={unitList} name="unit" title="Unit System"/>
												
											</div>

											<div style={{ width: '50%' }}>
												<SelectField list={weightList} name="weightunit" title="Default weight unit" />
											</div>
										</div>
									</div>
									
								</div>


								<Button
				            colorScheme='teal'
				            border='1px'
			              borderColor='#047E60'
			              bg="#047E60"
			              px="20px"
			              py="10px"
			              color="#fff"
			              fontSize="14px"
			              fontFamily='Open Sans, sans-serif'
			             
				          m={5}  
				            type='submit'
		          		>
		           		 Save
		          		</Button>

							</div>



							</Form>	
						</div>


						
		</>
	);
}

export default StoreDetail;