import React from 'react';
import { BsFillBellFill } from "react-icons/bs";
import { FaCaretDown } from "react-icons/fa";
import logo from '../assets/images/logo.png'
import { useSelector } from 'react-redux';
import { userActions } from '../store';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Image,HStack,Box,Heading,Text,Link } from '@chakra-ui/react'

function Header() {
	const { user: authUser } = useSelector(x => x.auth);

	const navigate = useNavigate();

	const meLogout = () =>{
		localStorage.removeItem('user')
		navigate("/login")
		// window.location.href="/login"
	}
	return(
		<>
			<div className="header_section">
				<div className="logo_section">
					{/*<img src={logo} />*/}
					<Text mb="0px" fontSize='20px' color="#363A77" fontWeight="700" fontFamily='Montserrat, sans-serif'><a href="/">QEEKT</a></Text>
				</div>

				<div className="user_section">
					<ul>
						<li>
							<a href="#">
								<BsFillBellFill />
							</a>
						</li>


						<li>
							<Dropdown>
						      <Dropdown.Toggle id="dropdown-basic">
						        <div className="user_data_section">
									<div className="user_box">
										{/*<h4>{authUser?.full_name[0]}</h4>*/}
									</div>
									
									<div className="text_section">
										<h5>{authUser?.full_name}</h5>
										<p>My Company Name <FaCaretDown size="9px" color="#6C7074" /></p>
									</div>
								</div>
						      </Dropdown.Toggle>

						      <Dropdown.Menu>
						        <Dropdown.Item onClick={()=> meLogout()}>Logout</Dropdown.Item>
						      </Dropdown.Menu>
						    </Dropdown>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
}

export default Header;