import React,{useEffect,useState} from 'react';
import { Button ,HStack,Spinner} from '@chakra-ui/react'

import * as Yup from 'yup';
import {InputText,TextAreaField,Form,SelectField} from '../../../components/FormElement'

import { fetchWrapper } from '../../../helpers';
import { toast } from "react-toastify";




function BillingInfo({loadList,billingData,onClose}){
	const [loading, setLoading] = useState(false);
	const rs = billingData
	

	const [formData, setFormData] = useState({
    address: rs?.address,
  

  });



  const FormSchema = Yup.object().shape({
	    address: Yup.string()
	          .required('Required'),
	       // whatsapp_number: Yup.string()
	       // .required('Required'),
	       // emaail: Yup.string()
	       // .required('Required'),
	       // contact: Yup.string()
	       // .required('Required')
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		 setLoading(true)
		let data = {}
		   data.type = 'billing_info'
		   data.setting_data = values;
		   
	       
	     const url = `${process.env.REACT_APP_API_URL}/tenent/settings`;
  		 await fetchWrapper.post(url,data)
  		 loadList();
  		 onClose()
  		 setLoading(false);
  		 toast(`Record has been update`,{
  		 	type:'success'
  		 })
	}

	


	return (

			<>
			{loading &&
	  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
					  thickness='4px'
					  speed='0.65s'
					  emptyColor='gray.200'
					  color='blue.500'
					  size='xl'
					/>
				</HStack>
			}
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="address" title="Billing Info"  />

			 	

		         <Button
				            colorScheme='teal'
				            border='1px'
			              borderColor='#047E60'
			              bg="#047E60"
			              px="20px"
			              py="10px"
			              color="#fff"
			              fontSize="14px"
			              fontFamily='Open Sans, sans-serif'
			              mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default BillingInfo;