import React,{useEffect,useState} from 'react';
import { BsThreeDots } from "react-icons/bs";
import { fetchWrapper } from '../../../helpers';
import Edit from './Edit';
import CustomModalLink from '../../../components/FormElement/CustomModalLink';

import {
  Box,
  HStack,
  
  Spinner
} from '@chakra-ui/react'
const List = ({user_type_id,show}) => {



	const [loading,setLoading] = useState(false);
	const [list,setList] = useState([])

	useEffect(() => {
        loadList() 
    }, [show]);

	  const loadList = async () => {
	  		// setLoading(true)
	      const url = `${process.env.REACT_APP_API_URL}/user/list?user_type_id=${user_type_id}`;
	      const data  = await fetchWrapper.get(url);
	      setList(data.data);
	  
	      // setLoading(false);
	  }


	return (
		<>
		{loading &&
  			<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.5)" position="absolute" zIndex="9999" justifyContent="center">
		    	<Spinner
				  thickness='4px'
				  speed='0.65s'
				  emptyColor='gray.200'
				  color='blue.500'
				  size='xl'
				/>
			</HStack>
		}


					<div className="plan_detail_section py-0">

							{list?.map((rsUser, indexUser) => {

								return (
										<div className="user_permission_section d_flex justify_content_between">
											<div className="user_detail_section d_flex align_center">
												<div className="user_box me-10">
													<h5>{rsUser.full_name.substr(0,1)}</h5>
												</div>

												<div className="text_section">
													<a href="#">{rsUser.full_name}</a>
													<p>Last login was Friday, March 17, 2023 05:30 pm</p>
												</div>
											</div>

											<div className="d_flex align_center">
												<a href="#" className="me-10">Limited Permissions</a>
												<div class="dropdown">
												  <a class="dropdown-toggle" data-bs-toggle="dropdown">
												    <BsThreeDots />
												  </a>
												  <ul class="dropdown-menu">
												    <li><a class="dropdown-item" href="#"><CustomModalLink
													        showModalButtonText='Edit'
													        modalHeader="Edit User"
													        modalBody=<Edit rs={rsUser} loadList={loadList} />
													      /></a></li>
												    <li><a class="dropdown-item" href="#">Delete</a></li>
												  </ul>
												</div>
											</div>
										</div>
								)
								})}
						</div>
						</>

		)

}

export default List;